import { configureStore } from "@reduxjs/toolkit";
import countersSlice from "../reducers/counters";
import textEditorSlice from "../reducers/textEditor";
import searchTopicSlice from "../reducers/searchTopic";
import userSlice from "../reducers/user";
import topicCoverageSlice from "../reducers/topicCoverage";
import titleOptimizationSlice from "../reducers/titleOptimization";
import headingsOptimizationSlice from "../reducers/headingsOptimization";
import topicDensitySlice from "../reducers/topicDensity";
import imagesSlice from "../reducers/images";
import applicationModeSlice from "../reducers/applicationMode";
import categoryTextEditorSlice from "../reducers/categoryTextEditor";
import articleSlice from "../reducers/articles";
import dashboardSlice from "../reducers/dashboard";
import performanceDashboardSlice from "../reducers/performanceDashboard";
import feedbacksSlice from "../reducers/feedbackFlow";
import apiErrors from "../reducers/apiErrors";
import featureFlagsSlice from "../reducers/featureFlags";
import aiKeywordsSlice from "../reducers/aiKeywords";
import articlesLanguage from "../reducers/articlesLanguage";
import articleDataFlagsSlice from "../reducers/articleDataFlags";
import contentAuditIssuesSlice from "../reducers/contentAuditIssues";
import keywordsCoverageSlice from "../reducers/keywordsCoverage"

export default configureStore({
  reducer: {
    counters: countersSlice,
    texteditor: textEditorSlice,
    searchtopic: searchTopicSlice,
    user: userSlice,
    topiccoverage: topicCoverageSlice,
    titleoptimization: titleOptimizationSlice,
    headingsoptimization: headingsOptimizationSlice,
    topicdensity: topicDensitySlice,
    images: imagesSlice,
    applicationmode: applicationModeSlice,
    categorytexteditor: categoryTextEditorSlice,
    articles: articleSlice,
    dashboard: dashboardSlice,
    feedbacks: feedbacksSlice,
    apiErrors,
    performanceStats: performanceDashboardSlice,
    featureFlags: featureFlagsSlice,
    aikeywords: aiKeywordsSlice,
    articlesLanguage,
    articledataflags: articleDataFlagsSlice,
    keywordscoverage: keywordsCoverageSlice,
    contentauditissues: contentAuditIssuesSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
});
