import React from "react";
import { useDispatch } from "react-redux";
import { setApiError, setApiErrorMessage } from "../reducers/apiErrors";

import { uploadCSVArticles, uploadImageToEditor } from "../services/articles";

import "./CustomCVSUploader.css";

const CustomFileUploader = ({
  onSuccess,
  title,
  onUpdatedProgress,
  onError,
  onStart,
}) => {
  const dispatch = useDispatch();

  const onSelectFile = async (e) => {
    const currentFile = e.target.files[0];

    if (currentFile.type.includes("image")) {
      // upload file is an image
      uploadImageFile(currentFile);
    } else {
      e.preventDefault()
    }
  };

  const uploadImageFile = async (currentFile) => {
    onStart();
    const articles = await uploadImageToEditor(currentFile, onUpdatedProgress);
    if (!articles.error) {
      onSuccess(articles);
    } else {
      onError("ERROR UPLOADING IMAGE!");
    }
  };

  const uploadCsvFile = async (currentFile) => {
    const articles = await uploadCSVArticles(currentFile);
    if (!articles.error) {
      onSuccess();
    } else {
      dispatch(setApiError(true));
      dispatch(setApiErrorMessage(articles.error.message));
    }
  };

  return (
      <div className="custom-file-uploader">
        <div className="custom-file-uploader__content">
          <label className="input-label" htmlFor="inputId" dangerouslySetInnerHTML={{ __html: title }}></label>
          <input
              id="inputId"
              className="hidden-input"
              type="file"
              name="file"
              onChange={onSelectFile}
              accept="image/*"
              onClick={(event) => {
                  event.target.value = null;
              }}
          />
        </div>
      </div>
  );
};

export default CustomFileUploader;
