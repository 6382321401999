import React, {useEffect, useRef, useState} from 'react';
import './DefaultPromptSidebarContent.css'
import {useDispatch, useSelector} from "react-redux";
import {getProjectDefaultPrompt, saveProjectDefaultPrompt} from "../../reducers/user";
import {getGlobalState} from "../../globals";
import DefaultPromptSidebarTextareaPlaceholder from "./DefaultPromptSidebarTextareaPlaceholder";

const DefaultPromptSidebarContent = ({}) => {
    const siteId = getGlobalState('site_id');

    const dispatch = useDispatch();

    const defaultPrompt = useSelector(getProjectDefaultPrompt)

    const [text, setText] = useState(defaultPrompt);
    const [saveTimeout, setSaveTimeout] = useState(null);
    const [showSaved, setShowSaved] = useState(!!text);

    const promptTextArea = useRef(null);

    const textareaOnChange = (e) => {
        const value = e.target.value;

        setText(value);
        setShowSaved(false);
        updateTextareaHeight(e.target);


        // Clear the previous timer
        if (saveTimeout) {
            clearTimeout(saveTimeout);
        }

        // Start a new timer
        const newTimeout = setTimeout(() => {
            dispatch(saveProjectDefaultPrompt(siteId, value))
            setShowSaved(true)
        }, 1000); // 1 second

        // Save the new timer
        setSaveTimeout(newTimeout);
    };

    const updateTextareaHeight = (element) => {
        element.style.height = '0';
        element.style.height = `${element.scrollHeight + 4}px`;
    };

    const showSavedLabel = () => showSaved ? 'Saved!' : '';


    return (
        <div className="default-prompt-sidebar-content">
            <div className="default-prompt-textarea__wrapper">
                <label htmlFor="defaultPrompt">Campaign brief <span>{showSavedLabel()}</span></label>
                <textarea
                    name="defaultPrompt"
                    id="defaultPrompt"
                    placeholder=""
                    ref={promptTextArea}
                    value={text}
                    onChange={textareaOnChange}
                    style={{ overflowY: 'hidden' }}
                    rows={1}
                />
                {!text.length && <DefaultPromptSidebarTextareaPlaceholder />}

            </div>
            <div className="default-prompt-info">This brief will be prompted in the AI content generation process for all articles generated in this campaign.</div>
        </div>
    );
};

export default DefaultPromptSidebarContent