import React from "react";
import CustomSvgIcon from "./CustomSvgIcon";

import "./MenuItemTop.css";

const MenuItemTop = ({
  icon,
  title,
  action,
  disabled,
  isActive = null,
  isComponent,
  Component,
}) => {
  if (isComponent) {
    // if we defined a custom component we just render it here
    return Component;
  } else {
    return (
        <button
            className={`menu-item${isActive && isActive() ? ' is-active' : ''} left-item`}
            onClick={action}
            title={title}
            disabled={disabled ?? false}
            dangerouslySetInnerHTML={{ __html: icon }}></button>
    );
  }
};

export default MenuItemTop;
