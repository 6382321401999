import { createSlice } from "@reduxjs/toolkit";

export const apiErrors = createSlice({
  name: "apierrors",
  initialState: {
    apiError: null,
    apiErrorInfo: null,
    showGenericErrorModal: false,
    // anti pattern but this will either be a string or an object
    genericErrorText: '',
    genericHandler: null,
    genericButtonText: 'Try again',
    secondaryGenericHandler: null,
    onCloseGenericHandler: null
  },
  reducers: {
    setApiError: (state, { payload }) => {
      return {
        ...state,
        apiError: payload,
      };
    },

    setApiErrorMessage: (state, { payload }) => {
      return {
        ...state,
        apiErrorInfo: payload,
      };
    },

    setShowGenericErrorModal: (state, { payload }) => {
      return {
        ...state,
        showGenericErrorModal: payload,
      };
    },
    setGenericErrorText: (state, { payload }) => {
      return {
        ...state,
        genericErrorText: payload,
      };
    },
    setGenericHandler: (state, { payload }) => {
      return {
        ...state,
        genericHandler: payload,
      };
    },
    setSecondaryGenericHandler: (state, { payload }) => {
      return {
        ...state,
        secondaryGenericHandler: payload
      }
    },
    setOncloseHandler: (state, {payload}) => {
      return {
        ...state,
        onCloseGenericHandler: payload
      }
    }
  },
});

// actions
export const { setApiError } = apiErrors.actions;
export const { setApiErrorMessage } = apiErrors.actions;
export const { setShowGenericErrorModal } = apiErrors.actions;
export const { setGenericErrorText } = apiErrors.actions;
export const { setGenericHandler } = apiErrors.actions;
export const { setSecondaryGenericHandler } = apiErrors.actions
export const { setOncloseHandler } = apiErrors.actions
// selectors
export const isApiError = ({ apiErrors: { apiError } }) => apiError;
export const apiErrorMessage = ({ apiErrors: { apiErrorInfo } }) => apiErrorInfo;
export const showErrorModal = ({ apiErrors: { showGenericErrorModal } }) => showGenericErrorModal;
export const getGenericErrorText = ({ apiErrors: { genericErrorText } }) => genericErrorText;
export const getGenericHandler = ({ apiErrors: { genericHandler } }) => genericHandler;
export const getSecondaryHandler = ({ apiErrors : {secondaryGenericHandler}}) => secondaryGenericHandler
export const getOncloseHandler = ({ apiErrors: { onCloseGenericHandler }}) => onCloseGenericHandler
export default apiErrors.reducer;
