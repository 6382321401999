/** @format */

import React, { useEffect, useRef } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import StarterKit from '@tiptap/starter-kit';
import CharacterCount from '@tiptap/extension-character-count';
import Placeholder from '@tiptap/extension-placeholder';
import Image from '@tiptap/extension-image';
// we use our custom extension from extensions folder
import { Link } from './extensions/link/CustomLink';
import TextAlign from '@tiptap/extension-text-align';
import { debounce } from 'lodash';
import './TipTapEditor.css';
import { getParsedData, resetEditorData, saveEditorDataToStore, setEditorInstance } from '../../reducers/textEditor';
import { setHighTopicCoverage, setLowTopicCoverage, setTopicCoverageData, updateMatchingWords } from '../../reducers/topicCoverage';
import { useDispatch, useSelector } from 'react-redux';
import { setWordCount } from '../../reducers/counters';
import { DEFAULT_ARTICLE_ID } from '../../common/consts';
import {
    getCurrentArticle,
    getCurrentArticleId,
    saveArticle,
    setArticle,
    setArticleId,
    setArticlesCollection,
    contentArticleData,
    uncompiledContentArticleData,
    outlineData,
    getArticleEditorLoadingState,
    getOutlineEditorLoadingState,
    saveOutlineToDB,
    getNewTitleForOutline,
    getOutlineData,
    getOutlineProgressStatus,
    setArticleAltered,
    checkIfArticleIsEditable,
    getArticleJustFinishedGenerating,
    getArticleCompleted,
    getArticleCompilationStatus,
    getCompletedHeadings,
    getTotalHeadings, getShowUncompiled, setShowUncompiled
} from '../../reducers/articles';
import { isDemoApplication } from '../../reducers/applicationMode';
import { checkTitleOptimization } from '../../reducers/titleOptimization';
import { checkHeadingsOptimization } from '../../reducers/headingsOptimization';
import { checkTopicDensity } from '../../reducers/topicDensity';
import { checkCurrentImages, transformContentOnPaste } from '../../reducers/images';

import useUnmount from '../../utils/useUnmount';
import { useApplicationType } from '../../utils/useApplicationType';
import EditorMenuTop from './EditorMenuTop';
import { loadingStep2Data } from '../../reducers/searchTopic';
import { useLocation } from 'react-router-dom';
import DndPlayground from '../AIOutlineDashboard/DndPlayground';
import EditorHeader from './EditorHeader';
import { getWriterMode } from '../../reducers/articles';
// import AiBanner from './AiBanner';
import AiCard from './AiCard/AiCard';
import EditorLoadingScreen from './EditorLoadingScreen';
import { Portal } from '@material-ui/core';
import { RefreshIcon } from '../../UI_utils/SVGIcons';
import Scrollbars from 'react-custom-scrollbars';
import ArticleProgressBox from './ArticleProgressBox';

const CustomDocument = Document.extend({
    content: 'heading block*',
});

const parseEditorData = debounce((dispatch, data) => {
    dispatch(saveEditorDataToStore(data));
}, 500);

const trackTopicCoverage = debounce((dispatch) => {
    dispatch(updateMatchingWords());
}, 600);

const sendDataToServer = debounce((dispatch, articleType) => {
    dispatch(saveArticle(articleType, 'article'));
}, 500);

const sendUncompiledDataToServer = debounce((dispatch, articleType) => {
    dispatch(saveArticle(articleType, 'article', true));
}, 500);

const sendOutlineDataToServer = debounce((dispatch, outline) => {
    dispatch(saveOutlineToDB(outline));
}, 500);

const trackTitle = debounce((dispatch) => {
    dispatch(checkTitleOptimization());
}, 900);

const trackHeadings = debounce((dispatch) => {
    dispatch(checkHeadingsOptimization());
}, 2800);

const trackTopicDensity = debounce((dispatch) => {
    dispatch(checkTopicDensity());
}, 2900);

const trackImages = debounce((dispatch) => {
    dispatch(checkCurrentImages());
}, 3000);

const TipTapEditor = ({ writerMode, switchToArticle, firstLoad }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const isDemoApp = useSelector(isDemoApplication);
    const currentArticleId = useSelector(getCurrentArticleId);
    const outlineProgressStatus = useSelector(getOutlineProgressStatus);
    const articleEditorIsEditable = useSelector(checkIfArticleIsEditable);
    const articleJustFinishedGenerating = useSelector(getArticleJustFinishedGenerating);

    const currentArticle = useSelector(getCurrentArticle);
    const applicationType = useApplicationType();
    const isTopicLoading = useSelector(loadingStep2Data);
    const articleLoading = useSelector(getArticleEditorLoadingState);
    const outlineLoading = useSelector(getOutlineEditorLoadingState);
    const articleData = useSelector(contentArticleData);
    const uncompiledArticleData = useSelector(uncompiledContentArticleData);
    const outline = useSelector(outlineData);
    const showUncompiledArticle = useSelector(getShowUncompiled);

    const parsedData = useSelector(getParsedData);

    const [isOutline, setIsOutline] = React.useState(false);
    const [headingIsEmpty, setHeadingIsEmpty] = React.useState(true);
    const [generating, setGenerating] = React.useState(false);
    const [aiMode, setAiMode] = React.useState({ label: 'Open AI', value: 'openai' });
    const [selectedTestPrompt, setSelectedTestPrompt] = React.useState({ label: 'Default', value: false });
    // const [showUncompiledArticle, setShowUncompiledArticle] = React.useState(false);

    const showUncompiledArticleRef = useRef(showUncompiledArticle);

    useEffect(() => {
        showUncompiledArticleRef.current = showUncompiledArticle;
    }, [showUncompiledArticle]);

    const setShowUncompiledArticle = (value) => dispatch(setShowUncompiled(value));

    const articleCompilationStatus = useSelector(getArticleCompilationStatus);
    const articleCompleted = useSelector(getArticleCompleted);
    const completedHeadings = useSelector(getCompletedHeadings);
    const totalHeadings = useSelector(getTotalHeadings);

    // Use useRef to create a mutable object that holds the latest value of writerMode
    const writerModeRef = useRef(writerMode);

    // Update writerModeRef when writerMode changes
    useEffect(() => {
        setHeadingIsEmpty(false);

        writerModeRef.current = writerMode;
    }, [writerMode]);

    const editorLoading = () => {
        return writerMode === 'article' ? articleLoading : outlineLoading;
    };

    useEffect(() => {
        checkAndSetHeadingIsEmpty();
    }, [parsedData]);

    const checkAndSetHeadingIsEmpty = () => {
        setHeadingIsEmpty(!parsedData.headings1.trim());
    };

    const addContentToEditor = (editor) => {
        //here add uncompiled article
        editor.commands.setContent(writerMode === 'article' ? showUncompiledArticle ? uncompiledArticleData : articleData : outline || '');
        trackDataDetails(editor);
    };

    const isOutlineEditorDisabled = () => {
        if (isOutline && articleCompleted === false && parseInt(completedHeadings) != totalHeadings) {
            return false;
        }
        return true;
    };

    ///

    useEffect(() => {
        const currentPath = location.pathname; // e.g. "/article/101/outline"
        const pathSegments = currentPath.split('/'); // e.g. ["", "article", "101", "outline"]
        const outline = pathSegments[pathSegments.length - 1]; // e.g. "outline"
        if (outline === 'outline') {
            setIsOutline(true);
        } else {
            setIsOutline(false);
        }
    }, [location]);

    ///

    const updateEditor = (addContent = false) => {
        if ((articleData || outline) && editor) {
            if (addContent) addContentToEditor(editor);
            trackDataDetails(editor);

            if (writerModeRef.current === 'article') {
                if (sendOutlineDataToServer) sendOutlineDataToServer.cancel();
                if (isEditorEditable()) {
                    if(showUncompiledArticle){

                        if (sendDataToServer) sendDataToServer.cancel();
                        if (!sendUncompiledDataToServer) sendUncompiledDataToServer(dispatch, applicationType);
                    } else {

                        if (sendUncompiledDataToServer) sendUncompiledDataToServer.cancel();
                        if (!sendDataToServer) sendDataToServer(dispatch, applicationType);
                    }
                }
            } else {
                if (sendDataToServer) sendDataToServer.cancel();
                if (sendUncompiledDataToServer) sendUncompiledDataToServer.cancel();

                if (!sendOutlineDataToServer) sendOutlineDataToServer(dispatch, editor.getHTML());
            }
        }
    };

    useEffect(() => {
        if (writerMode === 'article') updateEditor(!articleEditorIsEditable || articleJustFinishedGenerating);
    }, [articleData]);

    useEffect(() => {
        updateEditor(true);
    }, [writerMode, articleLoading, outlineLoading, generating, showUncompiledArticle]);

    useEffect(() => {
        if (writerMode === 'outline') updateEditor();
    }, [outline]);

    useUnmount(() => {
        dispatch(setArticle(null));
        dispatch(setArticleId(null));
        dispatch(setArticlesCollection([]));
        dispatch(setEditorInstance(null));
        dispatch(setTopicCoverageData([]));
        dispatch(setHighTopicCoverage([]));
        dispatch(setLowTopicCoverage([]));
        if (editor) {
            editor.destroy();
        }
    });

    const trackDataDetails = (editor) => {
        const data = editor.getHTML();
        // console.log({ data });
        const wordCount = editor.storage.characterCount.words();
        dispatch(setWordCount(wordCount));
        // parsed all data - debounced
        parseEditorData(dispatch, data);
        // track words from the content editor - debounced
        trackTopicCoverage(dispatch);
        // track title from content editor - debounced
        trackTitle(dispatch);
        // track headings from content editor - debounced
        trackHeadings(dispatch);
        // track topic density from content editor
        // we use raw data for this one - debounced
        trackTopicDensity(dispatch);
        // track images from content editor - debounce
        trackImages(dispatch);
    };
    const isEditorEditable = () => {
        const isOutline = writerMode === 'outline';
        const isArticle = writerMode === 'article';

        if (isOutline) {
            return isOutlineEditorDisabled() && articleEditorIsEditable;
        } else {
            return isArticle && articleEditorIsEditable;
        }
    };

    

    const editor = useEditor({
        editorProps: {
            transformPastedHTML: (html) => dispatch(transformContentOnPaste(html)),
        },
        editable: isEditorEditable(),
        extensions: [
            CustomDocument,
            StarterKit.configure({
                document: false,
            }),
            CharacterCount,
            Image,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Link.configure({
                openOnClick: true,
                linkOnPaste: true,
                HTMLAttributes: {
                    target: '_blank',
                    rel: 'canonical',
                    class: null,
                },
            }),
            Placeholder.configure({
                placeholder: ({ node }) => {
                    if (node.type.name === 'heading') {
                        return 'Insert title here...';
                    }

                    return '';
                },
            }),
        ],
        // content: currentArticle.content,
        onCreate: ({ editor }) => {
            // setting editor content with current article content
            addContentToEditor(editor);
            // set editor instance globally so it can be accessed throught the app
            dispatch(setEditorInstance(editor)); // REVIEW: is this needed?
        },
        onUpdate: ({ editor }) => {
            trackDataDetails(editor);
            // send data to API, autosave debounced
            if (!isDemoApp && currentArticleId !== DEFAULT_ARTICLE_ID) {
                if (writerModeRef.current === 'article') {
                    if(showUncompiledArticleRef.current) {
                        sendUncompiledDataToServer(dispatch, applicationType);
                    } else {
                        dispatch(setArticleAltered(true));
                        sendDataToServer(dispatch, applicationType);
                    }
                } else {
                    sendOutlineDataToServer(dispatch, editor.getHTML());
                }
            }
        },
    });

    // Update writerModeRef when writerMode changes
    useEffect(() => {
        if (editor) editor.setOptions({ editable: isEditorEditable() });
    }, [writerMode, articleJustFinishedGenerating, isOutlineEditorDisabled]);

    useEffect(() => {
        if(!editorLoading()){
            if (writerModeRef.current === 'article') {
                if(showUncompiledArticleRef.current) {
                    sendUncompiledDataToServer(dispatch, applicationType);
                } else {
                    dispatch(setArticleAltered(true));
                    sendDataToServer(dispatch, applicationType);
                }
            } else {
                sendOutlineDataToServer(dispatch, editor.getHTML());
            }
        }
    }, [editorLoading])

    if (!editor) {
        return null;
    }

    const CustomFloatingButton = ({ editor }) => {
        const handleClick = () => {
            setGenerating(true);
            // Handle button click logic

            dispatch(getNewTitleForOutline({ id: currentArticleId, testPrompt: selectedTestPrompt.value }))
                .then((response) => {
                    if (!response.error) {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(outline, 'text/html');
                        const h1Element = doc.querySelector('h1');

                        h1Element.innerHTML = response;

                        const updatedString = doc.body.innerHTML;

                        dispatch(getOutlineData(updatedString));
                    }

                    setGenerating(false);
                })
                .catch((err) => {
                    setGenerating(false);
                });
        };

        const buttonText = () => (generating ? 'Regenerating...' : 'Regenerate');


        return (
            <>
                {writerMode === 'outline' && (
                    <div onClick={isOutlineEditorDisabled() ? handleClick : null} className={isOutlineEditorDisabled() ? "refresh-title" : "refresh-title regenerate-disabled"}>
                        <RefreshIcon /> {buttonText()}
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {/* {isOutline && <DndPlayground onSaveOutline={() => setIsOutline(false)}/>} */}
            <div className={writerMode === 'outline' ? 'editor-wrapper outline-mode' : 'editor-wrapper'}>
                <EditorHeader writerMode={writerMode} />
                <EditorMenuTop writerMode={writerMode} editor={editor} />
                {/*{!isTopicLoading && <EditorContent editor={editor} />}*/}
                <Scrollbars>
                <div className="editor-content-container">
                    <AiCard
                        isOutline={writerMode === 'outline'}
                        switchToArticle={switchToArticle}
                        generating={editorLoading()}
                        firstLoad={firstLoad}
                        outlineProgressStatus={outlineProgressStatus}
                        aiMode={aiMode}
                        setAiMode={setAiMode}
                        selectedTestPrompt={selectedTestPrompt}
                        setSelectedTestPrompt={setSelectedTestPrompt}
                        toggleShowUncompiledArticle={(value) => setShowUncompiledArticle(value)}
                        showUncompiledArticle={showUncompiledArticle}
                    />
                    <ArticleProgressBox
                        isOutline={writerMode === 'outline'}
                        outlineProgressStatus={outlineProgressStatus}
                        articleProgressStatus={articleCompleted}
                        articleCompilationStatus={articleCompilationStatus}
                        totalHeadings={totalHeadings}
                        completedHeadings={completedHeadings}
                    />
                    {!editorLoading() && articleCompleted !== false &&
                     (
                        <>
                            <EditorContent editor={editor} className={`editor-content ${headingIsEmpty ? 'heading-is-empty' : ''}`}>
                                <CustomFloatingButton editor={editor} />
                            </EditorContent>
                        </>
                    )}
                </div>
                </Scrollbars>
            </div>
        </>
    );
};

export default TipTapEditor;
