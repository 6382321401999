import { createSlice } from "@reduxjs/toolkit";
import {
  getAllHealth,
  getAllImpresions,
  getAllOpportunity,
  getAllPublished,
  getAllVisits,
} from "../services/performance-dashboard";
import { setApiError } from "./apiErrors";

export const performanceDashboardSlice = createSlice({
  name: "performanceStats",
  initialState: {
    visitsStats: null,
    impressionsStats: null,
    healthStats: null,
    opportunityStats: null,
    publishedArticlesStats: null,
  },
  reducers: {
    setVisitsStats: (state, { payload }) => {
      return {
        ...state,
        visitsStats: payload,
      };
    },

    setImpressionsStats: (state, { payload }) => {
      return {
        ...state,
        impressionsStats: payload,
      };
    },

    setHealthStats: (state, { payload }) => {
      return {
        ...state,
        healthStats: payload,
      };
    },

    setOpportunityStats: (state, { payload }) => {
      return {
        ...state,
        opportunityStats: payload,
      };
    },

    setPublishedStats: (state, { payload }) => {
      return {
        ...state,
        publishedArticlesStats: payload,
      };
    },
  },
});

export const { setVisitsStats } = performanceDashboardSlice.actions;
export const { setImpressionsStats } = performanceDashboardSlice.actions;
export const { setHealthStats } = performanceDashboardSlice.actions;
export const { setOpportunityStats } = performanceDashboardSlice.actions;
export const { setPublishedStats } = performanceDashboardSlice.actions;

// get all performance stats
export const initPerformanceStats = (clientId, period) => async (dispatch) => {
  try {
    const impressions = await getAllImpresions(clientId, period);
    dispatch(setImpressionsStats(impressions));
  } catch (error) {
    dispatch(setApiError(error));
  }
  try {
    const visits = await getAllVisits(clientId, period);
    dispatch(setVisitsStats(visits));
  } catch (error) {
    dispatch(setApiError(error));
  }
  try {
    const opportunity = await getAllOpportunity(clientId, period);
    dispatch(setOpportunityStats(opportunity));
  } catch (error) {
    dispatch(setApiError(error));
  }

  try {
    const health = await getAllHealth(clientId, period);
    dispatch(setHealthStats(health));
  } catch (error) {
    dispatch(setApiError(error));
  }

  try {
    const published = await getAllPublished(clientId);
    dispatch(setPublishedStats(published));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

/* selectors */
export const getStatsOpportunity = ({
  performanceStats: { opportunityStats },
}) => opportunityStats;

export const getStatsHealth = ({ performanceStats: { healthStats } }) =>
  healthStats;
export const getStatsVisits = ({ performanceStats: { visitsStats } }) =>
  visitsStats;

export const getStatsImpressions = ({
  performanceStats: { impressionsStats },
}) => impressionsStats;

export const getStatsPublished = ({
  performanceStats: { publishedArticlesStats },
}) => publishedArticlesStats;

export default performanceDashboardSlice.reducer;
