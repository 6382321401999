import {
  getDashboardTable,
  dashboardInitData,
  updateDate,
  updateDrowpdownMenuOptions,
  deleteDasboardRow,
  createNewDashboardTopic,
  renameTopicTitle,
  sendLiveUrl,
  getProofreaders,
  getWriters,
} from "../api/dasboard";
import { getTopicData, getTopicCoverage } from "../api/topic-search";
import { getUserToken } from "../utils/userStatus";
import { ASSIGN, UNASSIGN } from "../common/consts";
import { transformAllData } from "./dashboard-helpers";
import { formatDateForServer } from "../utils/dateUtility";
import {
  getDashboardProjects,
  onAddNewProject,
  onDeleteProject,
} from "../api/projects";
import {getGlobalState} from "../globals";

export const getDashboardEndpoints = async (token) => {
  const data = await dashboardInitData(token);
  const { statuses } = data;

  // change text color to red if status is `Re-assigned to proof` or `Implemeting feedback
  const newStatuses = statuses.map((status) => ({
    ...status,
    color: status.id === 4 || status.id === 5,
  }));

  const newData = {
    ...data,
    statuses: newStatuses,
  };

  return newData;
};

export const requestWriters = async (token) => {
  const writers = await getWriters(token);
  return writers;
};

export const requestProofreaders = async (token) => {
  const proofs = await getProofreaders(token);
  return proofs;
};

// date picker updates
export const setNewDate = async (updateType, updatedRows) => {
  const token = getUserToken();
  const mappedIds = updatedRows.map((article) => article.id);
  const patchObject = {
    ids: mappedIds,
    // format date to be in MM-dd-yyyy shape, this is how server needs to receive it
    [updateType]: formatDateForServer(updatedRows[0][updateType]),
  };

  const data = await updateDate(token, updateType, patchObject);
  return data;
};

// drowpdown menu updates
// this works the same for status/project/feedback/industry
// `eventType` will be different for each one, being also the api path
export const setNewOption = async (eventType, updatedRows, updatedProperty) => {
  const token = getUserToken();
  const mappedIds = updatedRows.map((article) => article.id);

  // when changing single or bulk, we will always have the same value updated,
  // so we can use the first element in the array to read/send that updated property
  const selectedPropertyValue = updatedRows[0][updatedProperty];
  const previousPropertyValue = updatedRows[0][`prev${updatedProperty}`];

  const selectedUser = selectedPropertyValue || previousPropertyValue;
  // this payload is for writer/proofreader only
  const usersPayload = {
    ids: {
      [selectedUser]: mappedIds,
    },
  };

  // this payload is for assign categories
  const genericAssignPayload = {
    ids: {
      [mappedIds]: selectedPropertyValue,
    },
  };

  // // this payload is for unassign categories
  const genericUnassignPayload = {
    ids: mappedIds,
  };

  const genericPayload = selectedPropertyValue
    ? genericAssignPayload
    : genericUnassignPayload;

  const endpointType = selectedPropertyValue
    ? `${ASSIGN}-${eventType}`
    : `${UNASSIGN}-${eventType}`;

  // // patch object is different if we are updating the option for writers or proofreaders
  const patchObject =
    eventType === "users" || eventType === "status"
      ? usersPayload
      : genericPayload;

  const data = await updateDrowpdownMenuOptions(
    token,
    endpointType,
    patchObject
  );
  return data;
};

// create new dashboard entry
export const createNewEntry = async (
  {
    deadline,
    industry,
    project,
    topic,
    topicUrl,
    writer,
    brief,
    info,
    anchor1,
    anchor2,
    url1,
    url2,
    id,
    type,
    publisherUrl,
  },
  isEditMode
) => {
  const newEntryPayload = {
    name: topic.value,
    topicUrl: (type !== 2 && topicUrl && topicUrl.value) || null,
    writer: writer.value || null,
    proofreader: null,
    project: project.value || null,
    industry: (industry && industry.value) || null,
    status: null,
    deadline: formatDateForServer(deadline.value) || null,
    created: null,
    briefUrl: brief && type !== 2 ? brief.value : null,
    info: info.value || null,
    type,
    anchor1: type === 2 ? (anchor1.value !== "" ? anchor1.value : null) : null,
    anchor2: type === 2 ? (anchor2.value !== "" ? anchor2.value : null) : null,
    url1: type === 2 ? (url1.value !== "" ? url1.value : null) : null,
    url2: type === 2 ? (url2.value !== "" ? url2.value : null) : null,
    publisherUrl: type !== 1 ? publisherUrl.value : null,
  };

  const editPayload = {
    ...newEntryPayload,
    id,
  };

  // if we are in edit entry mode, we need to attach the id of the article in the payload
  const payload = isEditMode ? editPayload : newEntryPayload;

  const token = getUserToken();

  const newData = await createNewDashboardTopic(token, payload, isEditMode);
  return newData;
};

// remove row
export const deleteArticleRow = async (rowsId) => {
  const token = getUserToken();
  const onlyIds = rowsId.map(({ id }) => id);
  const articleIds = { ids: onlyIds };

  const confirmation = await deleteDasboardRow(token, articleIds);
  return confirmation;
};

// rename topic
export const renameTopic = async (rowId, newValue) => {
  const token = getUserToken();

  const payload = {
    id: rowId,
    name: newValue.value,
  };

  try {
    const renamedConfirm = await renameTopicTitle(token, payload);

    const dataTransform = {
      ...renamedConfirm,
      value: renamedConfirm.name,
    };

    return dataTransform;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

// pre-fetch topic data
export const prefetchData = async (topic, type) => {
  const token = getUserToken();
  try {

    const siteId = getGlobalState('site_id');

    await getTopicData({ keyword: topic, project: siteId }, token);
    await getTopicCoverage({ keyword: topic, type, project: siteId }, token);
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const getDashboard = async (
  currentPage,
  rowsPerPage,
  searchValue,
  filters,
  sortedBy
) => {
  const token = getUserToken();

  const payload = {
    page: currentPage,
    limit: rowsPerPage,
    search: searchValue || null,
    filters,
    sort: sortedBy ? { [sortedBy.name]: sortedBy.sortDirection } : null,
  };

  const data = await getDashboardTable(token, payload);

  if (data.rows) {
    const transformed = transformAllData(data);

    return transformed;
  } else {
    // we send empty flag in case of no results after filtering so we can keep the table head active, otherwise it will
    // become inactive and we cannot remove the filter
    return { rows: [{ empty: true }], total: data.total };
  }
};

export const getProjectsDashboard = async (
  currentPage,
  rowsPerPage,
  searchValue,
  filters,
  sortedBy
) => {
  const token = getUserToken();
  const payload = {
    page: currentPage,
    limit: rowsPerPage,
    search: searchValue || null,
    filters,
    sort: sortedBy ? { [sortedBy.name]: sortedBy.sortDirection } : null,
  };

  const data = await getDashboardProjects(token, payload);

  return data;
};

export const editLiveURL = async (id, urlValue, date) => {
  const payload = {
    id,
    liveUrl: urlValue,
    publishedDate: formatDateForServer(date),
  };
  const token = getUserToken();
  try {
    const data = await sendLiveUrl(token, payload);

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addNewProjectReq = async (projectName) => {
  const token = getUserToken();
  const payload = {
    name: projectName,
  };
  try {
    const result = await onAddNewProject(token, payload);
    return result;
  } catch (error) {
    console.log({ error });
  }
};

export const removeProjectReq = async (id) => {
  const token = getUserToken();
  const payload = {
    ids: [id],
  };
  try {
    const result = await onDeleteProject(token, payload);
    return result;
  } catch (error) {
    console.log({ error });
  }
};
