import { createSlice } from "@reduxjs/toolkit";
import { parseContent } from "../utils/parseEditorData";

export const textEditorSlice = createSlice({
    name: 'texteditor',
    initialState: {
        // editorData: "",
        parsedEditor: {
            headings1: '',
            headings: '',
            headingsArr: [],
            paragraphs: '',
            totalImages: 0,
        },
        resetEditor: false,
        editorInstance: null,
        activeSideBarTab: 0,
        commentsInitialised: false,
        editorHTML:''
    },
    reducers: {
        // setEditorData: (state, { payload }) => {
        //   return {
        //     ...state,
        //     editorData: payload,
        //     resetEditor: false,
        //   };
        // },

        setEditorInstance: (state, { payload }) => {
            return {
                ...state,
                editorInstance: payload,
            };
        },

        setParsedData: (state, { payload }) => {
            return {
                ...state,
                parsedEditor: payload,
                resetEditor: false,
            };
        },

        clearEditorData: (state, { payload }) => {
            return {
                ...state,
                editorData: payload,
            };
        },

        reset: (state, { payload }) => {
            return {
                ...state,
                resetEditor: payload,
            };
        },

        setActiveSidebarTab: (state, { payload }) => {
            return {
                ...state,
                activeSideBarTab: payload,
            };
        },

        setCommentsStatus: (state, { payload }) => {
            return {
                ...state,
                commentsInitialised: payload,
            };
        },
        setEditorHtml: (state, { payload }) => {
            return {
                ...state,
                editorHTML: payload,
            };
        },
    },
});

// export const { setEditorData } = textEditorSlice.actions;
export const { setEditorInstance } = textEditorSlice.actions;
export const { setParsedData } = textEditorSlice.actions;
export const { clearEditorData } = textEditorSlice.actions;
export const { reset } = textEditorSlice.actions;
export const { setDataAsText } = textEditorSlice.actions;
export const { setActiveSidebarTab } = textEditorSlice.actions;
export const { setCommentsStatus } = textEditorSlice.actions;
export const { setEditorHtml } = textEditorSlice.actions;

export const resetEditorData = (data) => (dispatch, getState) => {
  // dispatch(clearEditorData(""));
  const { editorInstance } = getState().texteditor;
  editorInstance && editorInstance.commands.setContent("<p></p>");
  dispatch(reset(data));
};

export const saveEditorDataToStore = (data) => (dispatch) => {
  const parsed = parseContent(data);
  dispatch(setEditorHtml(data));
  dispatch(setParsedData(parsed));
};

export const getParsedData = ({ texteditor: { parsedEditor } }) => parsedEditor;

export const getEditorInstance = ({ texteditor: { editorInstance } }) =>
  editorInstance;

export const isReset = ({ texteditor: { resetEditor } }) => resetEditor;
export const getActiveSidebarTab = ({ texteditor: { activeSideBarTab } }) =>
  activeSideBarTab;

export const getCommentsStatus = ({ texteditor: { commentsInitialised } }) =>
  commentsInitialised;

export const getEditorHTMLData = ({ texteditor: { editorInstance } }) =>
  editorInstance?.getHTML();

export const getEditorHTML = ({ textEditor: { editorHTML } }) => editorHTML;

export default textEditorSlice.reducer;
