import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  toggleApplicationMode,
  isDemoApplication,
  setDemoApplication,
} from "../reducers/applicationMode";
import { setArticleId } from "../reducers/articles";
import {
  DEFAULT_ARTICLE_ID,
  LOGIN_PATH,
  HOME_PATH,
  ESHOP_PATH,
  DEFAULT_ESHOP_ID,
  DASHBOARD_PATH,
  CLIENTS_DASHBOARD_PATH,
  USER_MASTER,
  USER_CLIENT,
  PROJECTS_PERFORMANCE_DASHBOARD_PATH,
} from "../common/consts";
import { resetEditorData, getEditorInstance } from "../reducers/textEditor";
import {
  setCategoryEditorData,
  setMetaDescription,
  setMetaTitle,
  setH1Tag,
} from "../reducers/categoryTextEditor";
import { setTopicCoverageData } from "../reducers/topicCoverage";
import { activeUser } from "../reducers/user";

import "./SidebarLeft.css";
import {
  AddNewIcon,
  BillingIcon,
  ContactUsIcon,
  HelpIcon,
  LogoutIcon,
  ManagementDashIcon,
  PerfIcon,
  SettingsIcon,
  ToggleSidebarIcon,
} from "../UI_utils/SVGIcons";
import { WhiteTooltip } from "./UITooltips/UITooltip";
import { setKeywordsSearch } from "../reducers/searchTopic";

const SidebarLeft = ({ onCreateNew }) => {
  const [newIcon, setNewIcon] = useState(false);
  const [dashIcon, setDashIcon] = useState(false);
  const [perfIcon, setPerfIcon] = useState(false);
  const [billingIcon, setBillingIcon] = useState(false);
  const [stgIcon, setSettingsIcon] = useState(false);
  const [contactIcon, setContactIcon] = useState(false);
  const [helpIcon, setHelpIcon] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const editorInstance = useSelector(getEditorInstance);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const currentUser = useSelector(activeUser);
  const isDemoApp = useSelector(isDemoApplication);

  const isDashboardOpen = pathname.includes(DASHBOARD_PATH);

  const newArticleContent = () => {
    enableCommonActions();
    dispatch(toggleApplicationMode("article"));
    history.push(`${HOME_PATH}/${DEFAULT_ARTICLE_ID}`);
    if (editorInstance) {
      dispatch(resetEditorData(true));
    }
  };

  const newCategoryContent = () => {
    enableCommonActions();
    history.push(`${ESHOP_PATH}/${DEFAULT_ESHOP_ID}`);
    dispatch(setCategoryEditorData(""));
    dispatch(toggleApplicationMode("category"));
    dispatch(setMetaTitle(""));
    dispatch(setH1Tag(""));
    dispatch(setMetaDescription(""));
  };

  const enableCommonActions = () => {
    if (!isDashboardOpen && onCreateNew) {
      onCreateNew();
    }
    dispatch(setArticleId(null));
    dispatch(setTopicCoverageData([]));
  };

  const openDashboard = () => {
    dispatch(setArticleId(null));
    //   // reset topic coverage data when coming from eshop
    dispatch(setTopicCoverageData([]));
    //   // reset editor data to empty
    if (editorInstance) {
      dispatch(resetEditorData(true));
    }
    dispatch(setKeywordsSearch(null));
    // navigate to dashboard page
    history.push(DASHBOARD_PATH);
  };

  const openProjectBrief = () => {
    // navigate to projects dashboard
    history.push(CLIENTS_DASHBOARD_PATH);
  };

  const openPerformanceDashboard = () => {
    // navigate to projects performance dashboard
    history.push(PROJECTS_PERFORMANCE_DASHBOARD_PATH);
  };

  const pushUserToLogin = () => {
    if (isDemoApp) {
      history.push(LOGIN_PATH);
      // reset demo application flag
      dispatch(setDemoApplication(false));
      // reset editor data to empty
      if (editorInstance) {
        dispatch(resetEditorData(true));
      }
      // reset topic coverage
      dispatch(setTopicCoverageData([]));
    }
  };

  const expandSidebar = () => {
    setSidebarExpanded((val) => !val);
  };

  const collapseSidebar = () => {
    setSidebarExpanded(false);
  };

  return <div />
  // return (
  //   <>
  //     <div
  //       className="sidebar-left"
  //       style={{
  //         width: `${sidebarExpanded ? "250px" : "85px"}`,
  //         boxShadow: `${
  //           sidebarExpanded ? "rgba(0, 0, 0, 0.1) 2.4px -2.4px 3.2px" : "none"
  //         }`,
  //         backdropFilter: "blur(6px)",
  //         backgroundColor: "rgba(255, 255, 255, 0.8)",
  //       }}
  //     >
  //       <div className="sidebar-wrapper">
  //         <div className="sidebar-top">
  //           <div className="sidebar-logo-area">
  //             <div className="sidebar-logo-wrapper" onClick={openDashboard}>
  //               {sidebarExpanded ? (
  //                 <img
  //                   src="/images/logos/logo-full.svg"
  //                   height="43px"
  //                   alt="logo"
  //                 />
  //               ) : (
  //                 <img
  //                   src="/images/logos/tiny-logo.svg"
  //                   height="43px"
  //                   alt="logo"
  //                 />
  //               )}
  //             </div>
  //           </div>
  //         </div>
  //         {/* tooltips and small buttons when collapsed */}
  //         {!sidebarExpanded ? (
  //           <div className="sidebar-centered-content">
  //             <div className="top-icons">
  //               <WhiteTooltip
  //                 arrow
  //                 placement="left"
  //                 title={"Management dashboard"}
  //               >
  //                 <div
  //                   className="dynamic-icon"
  //                   onClick={isDemoApp ? pushUserToLogin : openDashboard}
  //                   onMouseEnter={() => setDashIcon(true)}
  //                   onMouseLeave={() => setDashIcon(false)}
  //                 >
  //                   <ManagementDashIcon
  //                     color={dashIcon ? "white" : "#11142D"}
  //                     opacity={dashIcon ? "1" : "0.400000006"}
  //                   />
  //                 </div>
  //               </WhiteTooltip>
  //               {currentUser &&
  //                 (currentUser.role[0] === USER_MASTER ||
  //                   currentUser.role[0] === USER_CLIENT) && (
  //                   <WhiteTooltip
  //                     arrow
  //                     placement="left"
  //                     title={"Performance dashboard"}
  //                   >
  //                     <div
  //                       className="dynamic-icon"
  //                       onClick={
  //                         isDemoApp ? pushUserToLogin : openPerformanceDashboard
  //                       }
  //                       onMouseEnter={() => setPerfIcon(true)}
  //                       onMouseLeave={() => setPerfIcon(false)}
  //                     >
  //                       <PerfIcon
  //                         color={perfIcon ? "white" : "#11142D"}
  //                         opacity={perfIcon ? "1" : "0.400000006"}
  //                       />
  //                     </div>
  //                   </WhiteTooltip>
  //                 )}
  //
  //               <WhiteTooltip arrow placement="left" title={"New article"}>
  //                 <div
  //                   className="dynamic-icon"
  //                   onClick={isDemoApp ? pushUserToLogin : newArticleContent}
  //                   onMouseEnter={() => setNewIcon(true)}
  //                   onMouseLeave={() => setNewIcon(false)}
  //                 >
  //                   <AddNewIcon
  //                     color={newIcon ? "white" : "#11142D"}
  //                     opacity={newIcon ? "1" : "0.400000006"}
  //                   />
  //                 </div>
  //               </WhiteTooltip>
  //             </div>
  //             <div className="sidebar-control">
  //               <div
  //                 className={`${
  //                   sidebarExpanded
  //                     ? "toggle-icon-wrapper"
  //                     : "toggle-icon-wrapper-closed"
  //                 }`}
  //                 onClick={expandSidebar}
  //               >
  //                 <ToggleSidebarIcon />
  //               </div>
  //             </div>
  //             <div className="bottom-icons">
  //               <div className="bottom-separator"></div>
  //               {currentUser &&
  //                 (currentUser.role[0] === USER_MASTER ||
  //                   currentUser.role[0] === USER_CLIENT) && (
  //                   <WhiteTooltip arrow placement="left" title={"Settings"}>
  //                     <div
  //                       className="dynamic-icon-bottom"
  //                       onMouseEnter={() => setSettingsIcon(true)}
  //                       onMouseLeave={() => setSettingsIcon(false)}
  //                       onClick={isDemoApp ? pushUserToLogin : openProjectBrief}
  //                     >
  //                       <SettingsIcon
  //                         color={stgIcon ? "#6C5DD3" : "#11142D"}
  //                         opacity={stgIcon ? "1" : "0.400000006"}
  //                       />
  //                     </div>
  //                   </WhiteTooltip>
  //                 )}
  //               <WhiteTooltip arrow placement="left" title={"How to use"}>
  //                 <div
  //                   className="dynamic-icon-bottom"
  //                   onMouseEnter={() => setHelpIcon(true)}
  //                   onMouseLeave={() => setHelpIcon(false)}
  //                   onClick={() =>
  //                     window.open(
  //                       "https://www.loom.com/share/ef80359547734ec29ef04340981e68c1"
  //                     )
  //                   }
  //                 >
  //                   <HelpIcon
  //                     color={helpIcon ? "#6C5DD3" : "#11142D"}
  //                     opacity={helpIcon ? "1" : "0.400000006"}
  //                   />
  //                 </div>
  //               </WhiteTooltip>
  //               <WhiteTooltip arrow placement="left" title={"Contact us"}>
  //                 <a
  //                   href="mailto:tudor@contero.io"
  //                   className="dynamic-icon-bottom"
  //                   onMouseEnter={() => setContactIcon(true)}
  //                   onMouseLeave={() => setContactIcon(false)}
  //                 >
  //                   <ContactUsIcon
  //                     color={contactIcon ? "#6C5DD3" : "#11142D"}
  //                     opacity={contactIcon ? "1" : "0.400000006"}
  //                   />
  //                 </a>
  //               </WhiteTooltip>
  //             </div>
  //           </div>
  //         ) : (
  //           // expanded sidebar
  //           <div className="sidebar-centered-content">
  //             <div className="top-icons">
  //               <div className="dynamic-btn-wrapper">
  //                 <div
  //                   className="dynamic-icon-expanded"
  //                   onClick={isDemoApp ? pushUserToLogin : openDashboard}
  //                   onMouseEnter={() => setDashIcon(true)}
  //                   onMouseLeave={() => setDashIcon(false)}
  //                 >
  //                   <ManagementDashIcon
  //                     color={dashIcon ? "white" : "#11142D"}
  //                     opacity={dashIcon ? "1" : "0.400000006"}
  //                   />
  //                   <p
  //                     className="expanded-menu-text"
  //                     style={{
  //                       color: dashIcon ? "white" : "rgb(117, 129, 155)",
  //                     }}
  //                   >
  //                     Dashboard
  //                   </p>
  //                 </div>
  //               </div>
  //               {currentUser &&
  //                 (currentUser.role[0] === USER_MASTER ||
  //                   currentUser.role[0] === USER_CLIENT) && (
  //                   <div className="dynamic-btn-wrapper">
  //                     <div
  //                       className="dynamic-icon-expanded"
  //                       onClick={
  //                         isDemoApp ? pushUserToLogin : openPerformanceDashboard
  //                       }
  //                       onMouseEnter={() => setPerfIcon(true)}
  //                       onMouseLeave={() => setPerfIcon(false)}
  //                     >
  //                       <PerfIcon
  //                         color={perfIcon ? "white" : "#11142D"}
  //                         opacity={perfIcon ? "1" : "0.400000006"}
  //                       />
  //                       <p
  //                         className="expanded-menu-text"
  //                         style={{
  //                           color: perfIcon ? "white" : "rgb(117, 129, 155)",
  //                         }}
  //                       >
  //                         Performance
  //                       </p>
  //                     </div>
  //                   </div>
  //                 )}
  //               <div className="dynamic-btn-wrapper">
  //                 <div
  //                   className="dynamic-icon-expanded"
  //                   onClick={isDemoApp ? pushUserToLogin : newArticleContent}
  //                   onMouseEnter={() => setNewIcon(true)}
  //                   onMouseLeave={() => setNewIcon(false)}
  //                 >
  //                   <AddNewIcon
  //                     color={newIcon ? "white" : "#11142D"}
  //                     opacity={newIcon ? "1" : "0.400000006"}
  //                   />
  //                   <p
  //                     className="expanded-menu-text"
  //                     style={{
  //                       color: newIcon ? "white" : "rgb(117, 129, 155)",
  //                     }}
  //                   >
  //                     New article
  //                   </p>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="sidebar-control">
  //               <div
  //                 className={`${
  //                   sidebarExpanded
  //                     ? "toggle-icon-wrapper"
  //                     : "toggle-icon-wrapper-closed"
  //                 }`}
  //                 onClick={expandSidebar}
  //               >
  //                 <ToggleSidebarIcon />
  //               </div>
  //             </div>
  //             <div className="bottom-icons">
  //               <div className="bottom-separator-expanded"></div>
  //               {currentUser &&
  //                 (currentUser.role[0] === USER_MASTER ||
  //                   currentUser.role[0] === USER_CLIENT) && (
  //                   <div className="dynamic-btn-wrapper">
  //                     <div
  //                       className="dynamic-icon-bottom-expanded"
  //                       onClick={isDemoApp ? pushUserToLogin : openProjectBrief}
  //                       onMouseEnter={() => setSettingsIcon(true)}
  //                       onMouseLeave={() => setSettingsIcon(false)}
  //                     >
  //                       <div className="small-icon-wrapper">
  //                         <SettingsIcon
  //                           color={stgIcon ? "#6C5DD3" : "#11142D"}
  //                           opacity={stgIcon ? "1" : "0.400000006"}
  //                         />
  //                       </div>
  //                       <p
  //                         className="expanded-menu-text"
  //                         style={{
  //                           color: stgIcon ? "#6C5DD3" : "rgb(117, 129, 155)",
  //                         }}
  //                       >
  //                         Settings
  //                       </p>
  //                     </div>
  //                   </div>
  //                 )}
  //               <div className="dynamic-btn-wrapper">
  //                 <div
  //                   className="dynamic-icon-bottom-expanded"
  //                   onMouseEnter={() => setHelpIcon(true)}
  //                   onMouseLeave={() => setHelpIcon(false)}
  //                   onClick={() =>
  //                     window.open(
  //                       "https://www.loom.com/share/ef80359547734ec29ef04340981e68c1"
  //                     )
  //                   }
  //                 >
  //                   <div className="small-icon-wrapper">
  //                     <HelpIcon
  //                       color={helpIcon ? "#6C5DD3" : "#11142D"}
  //                       opacity={helpIcon ? "1" : "0.400000006"}
  //                     />
  //                   </div>
  //
  //                   <p
  //                     className="expanded-menu-text"
  //                     style={{
  //                       color: helpIcon ? "#6C5DD3" : "rgb(117, 129, 155)",
  //                     }}
  //                   >
  //                     How to use
  //                   </p>
  //                 </div>
  //               </div>
  //               <div className="dynamic-btn-wrapper">
  //                 <a
  //                   href="mailto:tudor@contero.io"
  //                   className="dynamic-icon-bottom-expanded"
  //                   onMouseEnter={() => setContactIcon(true)}
  //                   onMouseLeave={() => setContactIcon(false)}
  //                 >
  //                   <div className="small-icon-wrapper">
  //                     <ContactUsIcon
  //                       color={contactIcon ? "#6C5DD3" : "#11142D"}
  //                       opacity={contactIcon ? "1" : "0.400000006"}
  //                     />
  //                   </div>
  //
  //                   <p
  //                     className="expanded-menu-text"
  //                     style={{
  //                       color: contactIcon ? "#6C5DD3" : "rgb(117, 129, 155)",
  //                     }}
  //                   >
  //                     Contact us
  //                   </p>
  //                 </a>
  //               </div>
  //             </div>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //     {sidebarExpanded && (
  //       <div className="sidebar-backdrop" onClick={collapseSidebar}></div>
  //     )}
  //   </>
  // );
};

export default SidebarLeft;

// <div className="sidebar-left">
//   <div className="sidebar-left-content">
//     {!isDemoApp ? (
//       <>
//         <div
//           className="sidebar-icon plus-icon"
//           onClick={isDemoApp ? pushUserToLogin : newArticleContent}
//         >
//           <span>
//             <img src="/images/new-icons/new.svg" alt="add" />
//           </span>
//           <div className="sidebar-tooltip plus-icon">
//             <p>Write new article</p>
//           </div>
//         </div>
//         <div
//           className="sidebar-icon home-icon"
//           onClick={isDemoApp ? pushUserToLogin : openDashboard}
//         >
//           <img
//             src="/images/_icon-home.svg"
//             alt="home"
//             width={24}
//             height={24}
//           />
//           <div className="sidebar-tooltip home-icon">
//             <p>Management Dashboard</p>
//           </div>
//         </div>
//         <div
//           className="sidebar-icon performance-icon"
//           onClick={isDemoApp ? pushUserToLogin : openPerformanceDashboard}
//         >
//           <PerformanceIcon />
//           <AddNewIcon />
//           <BillingIcon />
//           <PerfIcon />
//           <SettingsIcon />
//           <div className="sidebar-tooltip performance-icon">
//             <p>Performance Dashboard</p>
//           </div>
//         </div>
//         {/* only users with MASTER role can see/access this button(icon) */}
//         {currentUser &&
//           (currentUser.role[0] === USER_MASTER ||
//             currentUser.role[0] === USER_CLIENT) && (
//             <div
//               className="sidebar-icon sign-out-icon"
//               onClick={isDemoApp ? pushUserToLogin : openProjectBrief}
//             >
//               <img src="/images/_icon-brief-side.svg" alt="Brief" />
//               <div className="sidebar-tooltip sign-out-icon">
//                 <p>Project Brief</p>
//               </div>
//             </div>
//           )}
//         <div
//           className="sidebar-icon eshop-icon"
//           onClick={isDemoApp ? pushUserToLogin : newCategoryContent}
//         >
//           <img src="/images/_eshop-icon.svg" alt="add" />
//           <div className="sidebar-tooltip eshop-icon">
//             <p>Write new e-shop description</p>
//           </div>
//         </div>

//         <div className="sidebar-icon sign-out-icon" onClick={logOutHandler}>
//           <img src="/images/_sign-out-icon.svg" alt="sign out" />
//           <div className="sidebar-tooltip sign-out-icon">
//             <p>Sign out</p>
//           </div>
//         </div>
//       </>
//     ) : (
//       <>
//         <div
//           className="sidebar-icon plus-icon"
//           onClick={() =>
//             window.open(
//               "https://calendly.com/contero/30-minute-talk?back=1&month=2021-04"
//             )
//           }
//         >
//           <span>
//             <img src="/images/_calendar-icon.svg" alt="add" />
//           </span>
//           <div className="sidebar-tooltip plus-icon">
//             <p>Book a quick demo</p>
//           </div>
//         </div>
//         <div
//           className="sidebar-icon plus-icon"
//           onClick={() => window.open("http://contero.io/become-a-writer/")}
//         >
//           <span>
//             <img src="/images/_become-writer.svg" alt="add" />
//           </span>
//           <div className="sidebar-tooltip plus-icon">
//             <p>Become a writer</p>
//           </div>
//         </div>
//       </>
//     )}
//   </div>
// </div>
