import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './ArticleKeywordsTableHeader.css'

const ArticleKeywordsTableHeader = ({ onFilter, loadingTopics }) => {
    const onSearch = (e) => {
        if(loadingTopics) return false;

        const value = e.target.value
        onFilter(value);
    };

    return (
        <>
            <div className={`article-keywords-table-header ${loadingTopics ? 'loading' : ''}`}>
                <div className="article-keywords-table-column column--keyword column-header">
                    <div className="input-search--keyword">
                        <div className="search-icon"><img src="/images/search.svg" alt="info"/></div>
                        <input
                            id="search"
                            onChange={onSearch}
                            type="text"
                            className="input-search"
                            placeholder="Keyword"
                            disabled={loadingTopics}
                        />
                    </div>
                </div>
                <div className="article-keywords-table-column column--volume column-header">Volume</div>
                <div className="article-keywords-table-column column--presence column-header">Presence</div>
            </div>
        </>
    );
}

export default ArticleKeywordsTableHeader;
