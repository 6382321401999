import AppModal from "../../../Shared/AppModal";
import {CircleCrossIcon} from "../../../UI_utils/SVGIcons";
import React from "react";

const AiCardModal = ({ promptType, generateArticle, cancelArticle }) => {
    const handleClose = () => cancelArticle();

    const handleConfirm = () => generateArticle();
    return (
        <AppModal
            icon={CircleCrossIcon}
            title={`Existing ${promptType} content will be replaced.`}
            text={''}
            secondaryButtonAction={handleClose}
            secondaryButtonText={'Cancel'}
            buttonText={'Continue'}
            buttonAction={handleConfirm}
        />
    );
};

export default AiCardModal;
