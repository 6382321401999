import React, { useEffect, useState } from 'react';

import DraggableList from './DraggableList';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getEditorInstance } from '../../reducers/textEditor';
import { useQuery } from "react-query";
import { Queries } from "../../common/consts";
import { requestAIOutline, requestCompetitionHeadings } from "../../services/ai-outline";
import './DndPlayground.css';
import useArticleId from '../../common/hooks/useArticleId';
import { getSelectedLanguage } from '../../reducers/articlesLanguage';
import AppLoadingScreen from "../../Shared/AppLoadingScreen";

const data = [
  { id: 1, name: 'The Shawshank Redemption', role: 'h2', content: null },
  { id: 2, name: 'The Godfather', role: 'h2', content: null },
  { id: 3, name: 'Pulp Fiction', role: 'h2', content: null },
  { id: 4, name: 'The Dark Knight', role: 'h2', content: null },
  { id: 5, name: 'Fight Club', role: 'h2', content: null },
  { id: 6, name: 'Inception', role: 'h2', content: null },
  { id: 7, name: 'Goodfellas', role: 'h2', content: null },
  { id: 8, name: 'The Matrix', role: 'h2', content: null },
  { id: 9, name: 'Interstellar', role: 'h2', content: null },
  { id: 10, name: 'The Lord of the Rings: The Fellowship of the Ring', role: 'h2', content: null },
  { id: 11, name: 'Monty Python and the Holy Grail', role: 'h2', content: null },
  { id: 12, name: 'Airplane!', role: 'h2', content: null },
  { id: 13, name: 'Anchorman: The Legend of Ron Burgundy', role: 'h2', content: null },
  { id: 14, name: 'Superbad', role: 'h2', content: null },
  { id: 15, name: 'Bridesmaids', role: 'h2', content: null },
  { id: 16, name: 'Shaun of the Dead', role: 'h2', content: null },
  { id: 17, name: 'Napoleon Dynamite', role: 'h2', content: null },
  { id: 18, name: 'Zoolander', role: 'h2', content: null },
  { id: 19, name: 'Dumb and Dumber', role: 'h2', content: null },
  { id: 20, name: 'Anchorman 2: The Legend Continues', role: 'h2', content: null }
];

// const leftList = data.slice(0, 4);
// const rightList = data.slice(4, 8);
const list3 = data.slice(8, 12);
const list4 = data.slice(12, 16);
const list5 = data.slice(16, 20);

// console.log({ leftList, rightList, list3, list4, list5  })

const DndPlayground = ({ onSaveOutline }) => {
  const { articleId } = useArticleId();
  const [fetchData, setFetchData] = useState(false);
  const selectedLanguage = useSelector(getSelectedLanguage)
  // console.log({ articleId });

  // request
  const { data: mainListData, isLoading: mainListLoading } = useQuery(
    [
      Queries.ARTICLE_OUTLINE, // unique query key
      { id: articleId, lang: selectedLanguage.value }, // query params
    ],
    requestAIOutline, // query function,
    {
      enabled: fetchData, // only run when we have article id
      staleTime: 0, // don't use stale data
      cacheTime: 0, // don't use cache data
    }
  );
  const { data: competitionData, isLoading: competitionLoading } = useQuery(
    [
      Queries.COMPETITION_HEADINGS, // unique query key
      { id: articleId, lang: selectedLanguage.value }, // query params
    ],
    requestCompetitionHeadings, // query function,
    {
      enabled: fetchData, // only run when we have article id
      staleTime: 0, // don't use stale data
      cacheTime: 0, // don't use cache data
    }
  );

  const history = useHistory();
  const editor = useSelector(getEditorInstance);
  const onExportList = (list) => {
    console.log({ list });
    const itemList = list.map(item => {
      return `<${item.role}>${item.name}</${item.role}>` + (item.content ? `<p>${item.content}</p>` : '');
    }).join('');

    editor?.commands.setContent(
      itemList
    );
    onSaveOutline();
    // remove `/outline` from the url path once the outline overlay is closed
    const currentPath = history.location.pathname;
    const newPath = currentPath.replace('/outline', '');
    history.push(newPath);
  };


  // console.log({ mainListLoading });
  // console.log({ mainListData });
  // console.log({ competitionLoading });
  // console.log({ competitionData });

  useEffect(() => {
    if(articleId) {
      setFetchData(true);
    }
  }, [articleId]);

  useEffect(() => {
    if (!mainListLoading && !competitionLoading) {
      setFetchData(false);
    }
  }, [mainListLoading, competitionLoading]);

  return (

    <div className='dnd-playground'>
      <div className='dnd-content'>
        {mainListLoading && !mainListData && <div>Loading main data...</div>}
        {competitionLoading && !competitionData && <div>Loading competition data...</div>}
        {((mainListLoading && !mainListData) || (competitionLoading && !competitionData)) &&
            <AppLoadingScreen />
        }
        {mainListData && competitionData && (
          <DraggableList leftList={mainListData} rightList={competitionData} list3={list3} list4={list4} list5={list5} onExportList={onExportList} />
        )}
      </div>
    </div>

  );
};

export default DndPlayground;
