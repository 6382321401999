import {diacriticMap} from "./specialChars";

export const matchingKeywords = (keywords, editorData) => {
  const { headings, paragraphs, headingsArr } = editorData;

  const matchingWords = keywords.map((word) => {
    const lowerKey = word.keyword.toLowerCase();

    // checking to see if variants exists, (in eshop we don't have keyword variants)
    // if not, we send the normal keyword as an array to our helper method for match checking
    const keywordsToCheck =
      !word.variations || word.variations.length === 0
        ? [lowerKey]
        : word.variations;

    // transform in a single string for optimisation when checking total count of a keyword
    const allStrings = headings.concat(" ", paragraphs);

      const usedInHeading = matchHeading(headingsArr, keywordsToCheck);
      // const usedInParagraph = isAnyWordMatching(paragraphs, keywordsToCheck);
      const usedInParagraph = isAnyWordMatching(paragraphs, keywordsToCheck);
    // if (isAnyWordMatching(allStrings, keywordsToCheck) || usedInHeading || usedInParagraph) {
    if (usedInHeading || usedInParagraph) {
      const repeatCount = word.use_in_text > 0 ? getKeywordTotalCount(keywordsToCheck, allStrings) : 0;

      // -> keyword has a match anywhere in the text
      if (usedInHeading || usedInParagraph) {
        // -> keyword has a match in headings and in paragraphs
        return {
          ...word,
          usedInParagraph,
          usedInHeading,
          // don't calculate repeat value if is not displayed
          repeat:repeatCount
        };
      }
    } else {
      // -> keyword has NO matches in article
      // reset any existing marks, in case the keyword was matching initially, but it was deleted or renamed
      return {
        ...word,
        usedInParagraph: false,
        usedInHeading: false,
        repeat: 0,
      };
    }

    return word;
  });

  return matchingWords;
};

export const topicCoverageMaxPoints = (data) => {
  const totalPoints = data && data.reduce((acc, curr) => acc + curr.points, 0);

  return totalPoints;
};

export const topicCoverageCurrentPoints = (data) => {
  if(!data || !data.length) return 0

  return data.filter(kw => kw.usedComplete).length;
};

const isAnyWordMatching = (data, variations) => {
  const matchingWord = variations.some((keyword) => {
    const lowerKey = keyword.toLowerCase();

    return replaceSpecialCharacters(data).includes(lowerKey);
  });

  // const matchingKey = variations.find((keyword) => keyword === data);
  // console.log("MATCHING KEY: ", matchingKey);

  return matchingWord;
};

const newAnyWordMatching = (data, variations) => {
  const updatedText = replaceSpecialCharacters(data);

  const matchingWord = variations.some((keyword) => {
    const splitedWords = keyword.toLowerCase().split(" ");

    return findCombinationsOfWordIndices(splitedWords, updatedText)
  });

  return matchingWord;
};

const matchHeading = (headingsArr, variations) => {
  // const updatedText = replaceSpecialCharacters(data);

  return headingsArr.some(heading => {
    return variations.some(variation => {
      return variation.split(" ").every(kw => {
        const lowerKey = kw.toLowerCase();

        return heading.includes(lowerKey);
      })
    });
  })
};

const getKeywordTotalCount = (keywords, data) => {
  return keywords.reduce((count, variant) => {
    const regex = new RegExp(`\\b${variant}\\b`, "gi");
    const matches = data.match(regex) || [];
    return count + matches.length;
  }, 0);
};

export const replaceSpecialCharacters = (text) => {
  let updatedText = '';
  for (let char of text) {
    updatedText += diacriticMap[char] || char;
  }
  return updatedText;
}

export const removeStopWords = (text, stopWords = []) => {
  // Iterate through each word in the array
  stopWords.forEach(stopWord => {
    // Create a regular expression to match the stopWord globally and case-insensitively
    const regex = new RegExp(`\\b${stopWord}\\b`, 'gi');
    // Replace the word in the text with an empty string
    text = text.replace(regex, '');
  });

  // Replace multiple spaces with a single space
  text = text.replace(/\s+/g, ' ').trim();

  // Return the modified text
  return text;
}

export const formatText = (text, stopWords = []) => {
  return removeStopWords(replaceSpecialCharacters(text), stopWords);
}

function findWordIndices(words, text) {
  return words.map(word => {
    const indices = [];

    // let startIndex = 0;
    //
    // while ((startIndex = text.indexOf(word, startIndex)) !== -1) {
    //   indices.push(startIndex);
    //   startIndex += word.length;
    // }

    const regex = new RegExp(`\\b${word}\\b`, 'g');
    let match;

    while ((match = regex.exec(text)) !== null) {
      indices.push(match.index);
    }

    return indices;
  });
}

function getAllCombinations(arrays) {
  const result = [];

  function helper(arr, i, current) {
    if (i === arrays.length) {
      result.push(current);
      return;
    }

    for (const num of arrays[i]) {
      helper(arr, i + 1, current.concat(num));
    }
  }

  helper(arrays, 0, []);
  return result;
}

function sortCombinations(combinations) {
  return combinations.map(combination => combination.sort((a, b) => a - b));
}

function isWithinDistance(combination, words, maxDistance = 160) {
  for (let i = 0; i < combination.length - 1; i++) {
    const currentIndex = combination[i];
    const nextIndex = combination[i + 1];
    const currentWordLength = words[i].length;

    if (nextIndex - (currentIndex + currentWordLength) > maxDistance) {
      return false;
    }
  }
  return true;
}

function findCombinationsOfWordIndices(words, text) {
  const indicesArray = findWordIndices(words, text);
  const combinations = getAllCombinations(indicesArray);
  const sortedCombinations = sortCombinations(combinations);

  for (const combination of sortedCombinations) {
    if (isWithinDistance(combination, words)) {
      return true; // Return true if at least one valid combination is found
    }
  }

  return false; // Return false if no valid combination is found
}