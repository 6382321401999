import React from 'react'
import './loadingBlock.css'

const LoadingBlock = ({ width, height, margin, rounded }) => {
    return (
         <div
            className={`loading-block ${rounded ? 'loading-block--rounded' : ''}`}
            style={{
                width: Number(width) ? `${width}px` : width || null,
                height: Number(height) ? `${height}px` : height || null,
                margin: margin || null,
            }}
    ></div>
    )
}

export default LoadingBlock;