import React from 'react';
import './ModalHeader.css'
import {CrossIcon} from "../../UI_utils/SVGIcons";

const ModalHeader = ({noArticles, close}) => {
    const SUBTITLE_NEW_ARTICLE = 'Select an opportunity and get assistance in writing a better ranking article.';

    return (
        <div className="modal-header">
            {noArticles && ( <div className="modal-header_welcome">Welcome to Content Writer!</div>)}
            {!noArticles && !!close && ( <div className="modal-header_close" onClick={close}><CrossIcon /></div>)}
            <div className="modal-header_title">Create a new article</div>
            <div className="modal-header_subtitle">
                { SUBTITLE_NEW_ARTICLE }
            </div>
        </div>
    );
};

export default ModalHeader