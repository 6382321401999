import axios from "axios";
import { BASE_URL } from "../common/consts";

const competition = "api/topics/competition";
const categoryCompetition = "api/topics/category/competition";

export const getCompetition = (topic, token) =>
  axios
    .post(`${BASE_URL}/${competition}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getCategoryCompetition = (topic, token) =>
  axios
    .post(`${BASE_URL}/${categoryCompetition}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
