import React from "react";
import {CircleCrossIcon} from "../UI_utils/SVGIcons";
import AppModal from "./AppModal";
import {useSelector, useDispatch} from "react-redux";
import {getGenericErrorText, getGenericHandler, getSecondaryHandler, getOncloseHandler, setOncloseHandler} from "../reducers/apiErrors";

const TryAgainModal = ({buttonAction}) => {
    const dispatch = useDispatch();
    const genericHandler = useSelector(getGenericHandler);
    const allDataFetched = useSelector(getGenericErrorText);
    const secondaryGenericHandler = useSelector(getSecondaryHandler);
    const onCloseHandler = useSelector(getOncloseHandler)
    const ERROR_TEXT_DEFAULT = 'We’re on our way to fix the issue.';

    const handler = () => {
        if(genericHandler) return genericHandler();

        return buttonAction()
    }

    const secondaryHandler = () => {
        if(secondaryGenericHandler) return secondaryGenericHandler();
    }

    const closeModal = () => {
        if(onCloseHandler){
            onCloseHandler()
            dispatch(setOncloseHandler(null))
        }
    }
    return (
        <AppModal
            icon={allDataFetched?.icon || CircleCrossIcon}
            title={allDataFetched?.title || "Oops! <br />Something went wrong"}
            text={allDataFetched?.description || ERROR_TEXT_DEFAULT}
            infoText={allDataFetched?.infoText || ""}
            buttonText={allDataFetched?.buttonText || "Try again"}
            secondaryButtonText={allDataFetched?.secondaryButtonText || ''}
            buttonAction={handler}
            secondaryButtonAction={secondaryHandler}
            onCloseAction={closeModal}

        />
    );
};

export default TryAgainModal;
