import React, {useRef , useEffect, useState} from 'react';
import './saveOptions.css';
import { useSelector,  useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { exportArticleToPdf } from '../../reducers/articles';
import { getEditorInstance } from '../../reducers/textEditor';
import Popup from 'reactjs-popup';



const SaveOptions = ({ setDownloadOptions, writerMode }) => {
    const menuRef = useRef(null);
    const dispatch = useDispatch();
    const editor = useSelector(getEditorInstance);
    const [popupOpen, setPopupOpen] = useState(false);


    const handleOutsideClick = (event) => {
        event.stopPropagation()
    if (
        menuRef.current &&
        !menuRef.current.contains(event.target)
    ) {
            setDownloadOptions(false);
        }
    };

    const displayNotification = () => {
        openPopup();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

     const exportArticle = () => {
         dispatch(exportArticleToPdf(writerMode));
     };
    
    
    const openPopup = () => setPopupOpen(true);
    const closePopup = () => setPopupOpen(false);
    return (
        <>
            <Popup open={popupOpen} onClose={closePopup} closeOnDocumentClick={true}>
                <div className="notification-modal">
                    <p className="notification-message">{'The sourcecode of the article has been copied to clipboard'}</p>
                </div>
            </Popup>
            <div className="save-options-container" ref={menuRef}>
                <div className="option-item" onClick={exportArticle}>
                    Save as PDF
                </div>
                <CopyToClipboard text={editor?.getHTML()} onCopy={displayNotification}>
                    <div className="option-item">Copy HTML</div>
                </CopyToClipboard>
            </div>
        </>
    );
};

export default SaveOptions