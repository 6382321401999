import React, {useState} from 'react';
import {WhiteTooltip} from "../UITooltips/UITooltip";
import {TrashIcon, SpinnerIcon} from "../../UI_utils/SVGIcons";
import {removeTitleFromList} from "../../reducers/titleOptimization";
import {useDispatch, useSelector} from "react-redux";
import {removeTopicFromList} from "../../reducers/topicCoverage";
import {deleteKeyword, getCurrentArticleId} from "../../reducers/articles";

const ArticleKeywordsTableRowDelete = ({ keyword, section }) => {
    const dispatch = useDispatch();

    const currentArticleId = useSelector(getCurrentArticleId);

    const [deleting, setDeleting] = useState(false);

    const deleteKw = () => {
        // if(deleting) return
        // not sure if we need to remove keyword from UI before or after BE request,
        // so adding a condition to don't trigger multiple times the delete event
        setDeleting(true);
        dispatch(deleteKeyword(currentArticleId, section, keyword))
        .then(() => {
            if(section === 'title')
                dispatch(removeTitleFromList(keyword))
            else
                dispatch(removeTopicFromList(keyword))
        }).catch((e) => {
            console.log(e)
        })
        .finally(() => setDeleting(false))
    }

    return (
        <WhiteTooltip
            placement="bottom"
            title={
                <div
                    style={{ whiteSpace: 'pre-line' }}
                    dangerouslySetInnerHTML={{
                        __html: "Remove keyword from brief",
                    }}
                />
            }
        >
            {
             deleting ? 
            <span className="loading-delete-keyword">
                <SpinnerIcon/>
            </span>
            : 
             <span className="delete-keyword" onClick={deleteKw}>
                <TrashIcon />
             </span>
            }
        </WhiteTooltip>
    );
}

export default ArticleKeywordsTableRowDelete;
