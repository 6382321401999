import React, {useEffect} from 'react';
import './trafficdropdown.css'
import {shortenNumbers} from "../../utils/helpers";

const TrafficDropdown = ({ topicInfo = {} }) => {
    // const {}
    const topicData = [
        {
            title: 'Keywords',
            value: topicInfo.keywords,
            sup: null,
            sup_percent: false,
            percent: false,
        },
        {
            title: '%Clicks',
            value: topicInfo.clicks,
            sup: null,
            sup_percent: false,
            percent: false,
        },
        {
            title: 'Volume',
            value: topicInfo.search_volume,
            sup: topicInfo.search_volume_yoy,
            sup_percent: true,
            percent: false,
        },
        // {
        //     title: 'Visibility',
        //     value: topicInfo.visibility,
        //     sup: topicInfo.visibility_yoy,
        //     percent: true,
        // },
        {
            title: 'Est. traffic',
            value: topicInfo.opportunity,
            sup: '/mo',
            sup_percent: false,
            percent: false,
        },
    ];
    return (
        <div className="traffic-dropdown-container">
            <div className="statistics-row">
                {topicData.map((info) => <div className="statistics-item" key={info.title}>
                    <p className="statistics-title">{ info.title}</p>
                    <p className="main-score">{info.percent ? parseFloat(info.value).toFixed(2) : shortenNumbers(info.value)}{info.percent && '%'}
                        {!!info.sup && <sup className={isNaN(info.sup) ? '' : info.sup > 0 ? 'positive' : info.sup < 0 ? 'negative' : ''}>{info.sup > 0 ? '+' : ''}{shortenNumbers(info.sup, 1, false) || 0}{info.sup_percent ? '%' : ''}</sup>}
                    </p>
                    </div>
                )}
                
            </div>
            <p className="statistics-description">
                {topicInfo.opportunity && shortenNumbers(topicInfo.opportunity)} monthly organic visits projected if ranking in top 3 for keywords used in the article and present in the brief.
            </p>
        </div>
    );
};

export default TrafficDropdown