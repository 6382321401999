import React, { useEffect, useState } from 'react';

import './ArticleKeywordsTableGroup.css'
import ArticleKeywordsTableGroupHeader from "./ArticleKeywordsTableGroupHeader";
import ArticleKeywordsTableRow from "./ArticleKeywordsTableRow";

const ArticleKeywordsTableGroup = ({
   keywords,
   groupName,
   filteredKeywords,
   percentage,
   secondPercentage,
   keywordsCoverage,
   secondKeywordsCoverage,
   doublePresencePercentage = false,
   isTitle=false,
}) => {
    const [expanded, setExpanded] = useState(true);
    const hasTags = keywords && keywords.length !== 0;

    const toggleList = () => {
        setExpanded(!expanded)
    }

    const collapseList = () => setExpanded(false);

    useEffect(() => {
        if(percentage === 100 && !filteredKeywords) {
            // collapseList();
        }
    }, [percentage])

    const returnTotalSearchVolume = () => {
        let searchVolumeSum = 0;

        keywords.forEach(kw => {
            if(typeof kw.search_volume == 'number')
                searchVolumeSum += kw.search_volume
        })

        return searchVolumeSum
    }

    return (
        <div className="article-keywords-table-group">
            <ArticleKeywordsTableGroupHeader
                groupName={groupName}
                groupPercentage={percentage}
                secondPercentage={secondPercentage}
                onClick={toggleList}
                searchVolume={returnTotalSearchVolume()}
                doublePresencePercentage={doublePresencePercentage}
                isTitle={isTitle}
                expanded={expanded}
            />
            {hasTags && expanded &&
                keywords.map(({keyword, search_volume, broad = false}, index) => {
                    return (
                        <ArticleKeywordsTableRow
                            key={`keyword-${index}`}
                            keyword={keyword}
                            searchVolume={search_volume}
                            used={keywordsCoverage?.[keyword]}
                            usedInH={secondKeywordsCoverage?.[keyword]}
                            broad={broad}
                            doublePresencePercentage={doublePresencePercentage}
                            isTitle={isTitle}
                        />
                    )
                })
            }
        </div>
    );
}

export default ArticleKeywordsTableGroup;
