export const matchTitle = (headings, expectedTitle, titleVariations) => {
  // we send title as an array to have the same format in the helper method
  // as we also send the titles variation as an array
  const titleUsed = evaluateWords(headings, [expectedTitle]);

  const variationsUsed = !titleUsed
    ? evaluateWords(headings, titleVariations)
    : false;

  const isTitleOptimized = titleUsed || variationsUsed;

  return isTitleOptimized;
};

// helper method to evaluate both title and variations array
const evaluateWords = (headings, titles) => {
  const evaluation = titles.map((variant) => {
    const splitTitle = variant && variant.split(" ");
    const titleUsed =
      splitTitle &&
      splitTitle.every((word) => headings && headings.includes(word.trim()));

    return titleUsed;
  });

  const titleOptimized = evaluation.some((title) => title);

  return titleOptimized;
};
