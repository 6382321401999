import { getCompetition, getCategoryCompetition } from "../api/competition";
import { getUserToken } from "../utils/userStatus";
import {getGlobalState} from "../globals";

export const requestCompetition = async (topic) => {
  const siteId = getGlobalState('site_id');
  const search = {
    keyword: topic,
    project: siteId,
  };

  const token = getUserToken();
  const competition = await getCompetition(
      search,
      token
  );
  console.log('old competition => ', competition)
  // const { allHtags, competitionList, avgWords } = await getCompetition(
  //   search,
  //   token
  // );

  const competitionData = {
    articles: competition.competitionList,
    allHtags: competition.allHtags,
    avgWords: competition.avgWords,
  };

  return competitionData;
};

// competition for category
export const requestCategoryCompetition = async (topic, language) => {
  const search = {
    keyword: topic,
    lang: language,
  };

  const token = getUserToken();

  try {
    const { allHtags, competitionList, avgWords } =
      await getCategoryCompetition(search, token);

    const categoryCompetitionData = {
      articles: competitionList,
      allHtags,
      avgWords,
    };

    return {
      categoryCompetitionData,
      error: false,
    };
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};
