import { createSlice } from "@reduxjs/toolkit";
import { defaultTopicDetails, defaultTopicInfo } from './defaultTopicResults';
import {
    requestTopicSearch,
    getTopicDetails,
    topicInfo,
    searchVolumeTopicService,
    estimatedVisitsTopicService,
    searchIntentTopicService,
    variationsService,
    radarChartService,
    topicCoverageService,
    headingCoverageService, titleCoverageService
} from "../services/topic";
import {
  requestCompetition,
  requestCategoryCompetition,
} from "../services/competition";
import {
  setTopicCoverageData,
  setHighTopicCoverage,
  setLowTopicCoverage, setTopicCoverageLoadingState,
} from "./topicCoverage";
import { setExpectedTitle, setTitleVariations, setTopicTitleCoverage } from "./titleOptimization";
import { setHeadingsForOptimization, setTopicHeadingCoverage } from "./headingsOptimization";
import { setTopicDensity, setMaxTopicDensity } from "./topicDensity";
import { setMaxImages } from "./images";
import { setMaxWordCount } from "./counters";
import { getUserToken } from "../utils/userStatus";
import {saveArticle, setArticleEditorLoading} from "../reducers/articles";
import { TYPE_ARTICLE, TYPE_DESCRIPTION, TYPE_NATIVE } from "../common/consts";
import { setDescriptionDataLoaded } from "../reducers/categoryTextEditor";
import { setApiError, setApiErrorMessage } from "./apiErrors";
import { setAIKeywords } from "./aiKeywords";
import { setActiveSidebarTab } from "./textEditor";
import {checkTopicStatus, getEstimatedVisitsData, getSearchIntentData, getSearchVolumeData} from "../api/topic-search";
import {getGlobalState} from "../globals";
import {useSelector} from "react-redux";
import {
  insertCountableKeyword,
  setDefaultTopicRules, sortTopic,
  transformData,
  transformKeywords
} from "../services/topic-helpers";
import {
    setEstimatedVisitsPromise,
    setSearchIntentPromise,
    setSearchVolumePromise,
    setTitleCoveragePromise,
    setHeadingCoveragePromise,
    setTopicCoveragePromise,
    setRadarChartPromise
} from "./articleDataFlags";


export const searchTopicSlice = createSlice({
    name: 'searchtopic',
    initialState: {
        topicData: null,
        topicDataLoading: null,
        topicStep2Loading: false,
        topicRules: defaultTopicDetails,
        searchKeywords: null,
        userProjectId: null,
        searchTopicError: null,
        competitionData: null,
        competitionDataLoading: null,
        fetchDataError: false,
        fetchCompetitionError: false,
        topicLanguage: null,
        topicInfoData: defaultTopicInfo,
    },
    reducers: {
        setTopicData: (state, { payload }) => {
            return {
                ...state,
                topicData: payload && payload.code ? null : payload,
                searchTopicError: payload && payload.code,
            };
        },

        setKeywordsSearch: (state, { payload }) => {
            return {
                ...state,
                searchKeywords: payload,
            };
        },

        setUserProjectId: (state, { payload }) => {
            return {
                ...state,
                userProjectId: payload,
            };
        },

        setTopicLanguage: (state, { payload }) => {
            return {
                ...state,
                topicLanguage: payload,
            };
        },

        setLoading: (state, { payload }) => {
            return {
                ...state,
                topicDataLoading: payload,
            };
        },

        setLoadingStep2: (state, { payload }) => {
            return {
                ...state,
                topicStep2Loading: payload,
            };
        },

        setTopicRules: (state, { payload }) => {
            return {
                ...state,
                topicRules: payload,
            };
        },

        setCompetitionData: (state, { payload }) => {
            return {
                ...state,
                competitionData: payload,
            };
        },

        setLoadingCompetition: (state, { payload }) => {
            return {
                ...state,
                competitionDataLoading: payload,
            };
        },

        setFetchError: (state, { payload }) => {
            return {
                ...state,
                fetchDataError: payload,
            };
        },

        setFetchCompetitionError: (state, { payload }) => {
            return {
                ...state,
                fetchCompetitionError: payload,
            };
        },
        setTopicInfo: (state, { payload }) => {
            return {
                ...state,
                topicInfoData: payload,
            };
        },
    },
});

export const { setLoading } = searchTopicSlice.actions;
export const { setLoadingStep2 } = searchTopicSlice.actions;
export const { setTopicData } = searchTopicSlice.actions;
export const { setTopicRules } = searchTopicSlice.actions;
export const { setKeywordsSearch } = searchTopicSlice.actions;
export const { setUserProjectId } = searchTopicSlice.actions;
export const { setTopicLanguage } = searchTopicSlice.actions;
export const { setCompetitionData } = searchTopicSlice.actions;
export const { setLoadingCompetition } = searchTopicSlice.actions;
export const { setFetchError } = searchTopicSlice.actions;
export const { setFetchCompetitionError } = searchTopicSlice.actions;
export const { setTopicInfo } = searchTopicSlice.actions;

export const setDefaultTopicData = (topic) => (dispatch) => {
  const defaultTopicData = {
    topic,
    monthlyValues: [
      {id: 1, name: 'Jan', value: '5000'},
      {id: 2, name: 'Feb', value: '5000'},
      {id: 3, name: 'Mar', value: '5000'},
      {id: 4, name: 'Apr', value: '5000'},
      {id: 5, name: 'May', value: '5000'},
      {id: 6, name: 'Jun', value: '5000'},
      {id: 7, name: 'Jul', value: '5000'},
      {id: 8, name: 'Aug', value: '5000'},
      {id: 9, name: 'Sep', value: '5000'},
      {id: 10, name: 'Oct', value: '5000'},
      {id: 11, name: 'Nov', value: '5000'},
      {id: 12, name: 'Dec', value: '5000'},
    ]
  }
  const formattedDefaultTopicData = transformData(defaultTopicData);
  dispatch(setTopicData(formattedDefaultTopicData));
}
export const searchIntentPromise = (topicObj, token) => async (dispatch, getState) => {
  try {
    const searchIntentData = await searchIntentTopicService(topicObj, token);
    const initialTopicData = getState().searchtopic.topicData;
    const topicData = { ...initialTopicData, ...searchIntentData};

    dispatch(setTopicData(topicData));

      dispatch(setSearchIntentPromise(true));

  } catch(error) {
    throw error
  }
}
export const estimatedVisitsPromise = (topicObj, token) => async (dispatch, getState) => {
  try {
    const estimatedVisitsData = await estimatedVisitsTopicService(topicObj, token);
    const initialTopicData = getState().searchtopic.topicData;
    const topicData = { ...initialTopicData, ...estimatedVisitsData};

    dispatch(setTopicData(topicData));

    dispatch(setEstimatedVisitsPromise(true));

  } catch(error) {
    throw error
  }
}
export const searchVolumePromise = (topicObj, token) => async (dispatch, getState) => {
  try {
    const searchVolumeData = await searchVolumeTopicService(topicObj, token);
    const initialTopicData = getState().searchtopic.topicData;
    const topicData = { ...initialTopicData, ...searchVolumeData};

    dispatch(setTopicData(topicData));

    dispatch(setSearchVolumePromise(true));

  } catch(error) {
    throw error
  }
}
// export const titleCoveragePromise = (topicObj, token, currentType) => async (dispatch, getState) => {
//   try {
//     const titleCoverage = await titleCoverageService(topicObj, token);
//     dispatch(setTopicTitleCoverage(titleCoverage));
//     dispatch(saveArticle(currentType));
//
//
//     dispatch(setTitleCoveragePromise(true));
//
//   } catch(error) {
//     throw error
//   }
// }
// export const headingCoveragePromise = (topicObj, token, currentType) => async (dispatch, getState) => {
//   try {
//     const headingCoverage = await headingCoverageService(topicObj, token);
//     dispatch(setTopicHeadingCoverage(headingCoverage));
//     dispatch(saveArticle(currentType));
//
//
//     dispatch(setHeadingCoveragePromise(true));
//
//   } catch(error) {
//     throw error
//   }
// }
// export const topicCoveragePromise = (topicObj, token, currentType) => async (dispatch, getState) => {
//   try {
//     const topicCoverage = await topicCoverageService(topicObj, token);
//     const sortedTopicCoverage = sortTopic(topicCoverage);
//
//     topicCoverage.forEach(item => item.variations = [item.keyword]);
//
//     const updatedTopicCoverage = transformKeywords(sortedTopicCoverage);
//
//     dispatch(setHighTopicCoverage(updatedTopicCoverage));
//     dispatch(setTopicCoverageData(updatedTopicCoverage));
//     dispatch(setHeadingsForOptimization(updatedTopicCoverage));
//
//     dispatch(saveArticle(currentType));
//
//     dispatch(variationsPromise(topicObj, token, currentType, updatedTopicCoverage))
//     dispatch(setTopicCoverageLoadingState(true))
//
//
//     dispatch(setTopicCoveragePromise(true));
//
//   } catch(error) {
//     throw error
//   }
// }

// export const radarChartPromise = (topicObj, token, currentType) => async (dispatch, getState) => {
//
//   try {
//     const radarChart = await radarChartService({...topicObj, type: 0}, token);
//     const modifiedRadarChart = {
//       ...radarChart,
//       topicDensity: radarChart.topicDensity > 1 ? radarChart.topicDensity : Math.round(radarChart.maxWords * 0.009)
//     };
//
//     const maxValues = {
//       ...modifiedRadarChart,
//     };
//     const topicRules = setDefaultTopicRules(maxValues);
//
//     // setting topic density to its own slice
//     dispatch(setMaxTopicDensity(maxValues.topicDensity));
//     // setting images to its own slice
//     dispatch(setMaxImages(maxValues.maxImages));
//     // setting words to its own slice
//     dispatch(setMaxWordCount(maxValues.maxWords));
//     dispatch(setTopicRules(topicRules));
//     dispatch(saveArticle(currentType));
//
//     dispatch(setRadarChartPromise(true));
//
//   } catch(error) {
//     throw error
//   }
// }
// export const variationsPromise = (topicObj, token, currentType, topicCoverage) => async (dispatch, getState) => {
//   try {
//     const topicCoverageCopy = JSON.parse(JSON.stringify(topicCoverage))
//
//     const variations = await variationsService(topicObj, token);
//
//     topicCoverageCopy.forEach((item, index) => {
//       item.variations = [...item.variations, ...variations[item.key]]
//     })
//
//     dispatch(setHighTopicCoverage(topicCoverageCopy));
//     dispatch(setTopicCoverageData(topicCoverageCopy));
//     dispatch(setHeadingsForOptimization(topicCoverageCopy));
//
//     dispatch(saveArticle(currentType));
//   } catch(error) {
//     throw error
//   }
// }

// export const setDefaultStatesBeforeStepOne = (topic, projectId) => (dispatch) => {
//   dispatch(setLoading(true));
//   dispatch(setLoadingCompetition(true));
//   // clear existing competition data, or it will be visible under skeleton
//   dispatch(setCompetitionData(null));
//   // we start the loading of step2 at the same time with step1
//   // but this is just to keep the skeleton displayed from start
//   // until we get actual data from step2 endpoint
//   dispatch(setLoadingStep2(true));
//   dispatch(setTopicCoverageLoadingState(false));
//   dispatch(setKeywordsSearch(topic));
//   dispatch(setUserProjectId(projectId));
//   // dispatch(setTopicLanguage(language));
// }
// export const setDefaultStatesAfterStepOne = (topic) => (dispatch) => {
//   dispatch(setLoadingStep2(true));
//   dispatch(setTopicCoverageLoadingState(false));
//   dispatch(setLoadingCompetition(true));
//   dispatch(setLoading(false));
//   dispatch(setAIKeywords(null));
//   dispatch(setDefaultTopicData(topic))
// }

//step 1 audit no outline
// export const searchTopic = (topic, projectId) => async (dispatch, getState) => {
//   const token = getUserToken();
//
//   dispatch(setDefaultStatesBeforeStepOne(topic, projectId));
//
//   try {
//     const siteId = getGlobalState('site_id');
//
//     // Trigger the other requests independently and dispatch specific actions for each one
//     const topicObj = { keyword: topic, project: siteId };
//     const checkStatusResult = await checkTopicStatus(topicObj, token);
//
//
//     if (checkStatusResult === "Success") {
//       setArticleEditorLoading(false);
//
//       dispatch(setDefaultStatesAfterStepOne(topic))
//
//       await Promise.race([
//         dispatch(searchIntentPromise(topicObj, token)),
//         dispatch(estimatedVisitsPromise(topicObj, token)),
//         dispatch(searchVolumePromise(topicObj, token)),
//       ]);
//     }
//   } catch (error) {
//     dispatch(setApiError(error));
//     dispatch(setLoading(false));
//   }
// };

// export const requestTopicDetails =
//   (topic, language) => async (dispatch, getState) => {
//     const token = getUserToken();
//     // reseting sidebar tab to 0 when searching for a new topic
//     dispatch(setActiveSidebarTab(0));
//     try {
//       const siteId = getGlobalState('site_id');
//       const topicObj = { keyword: topic, project: siteId };
//
//
//       const { applicationMode } = getState().applicationmode;
//
//       // this can be article or native
//       const currentType =
//           applicationMode === "article" ? TYPE_ARTICLE : TYPE_NATIVE;
//
//       console.log('Move type verification from topic.js line 99 to here')
//       await Promise.race([
//         dispatch(titleCoveragePromise(topicObj, token, currentType)),
//         dispatch(headingCoveragePromise(topicObj, token, currentType)),
//         dispatch(topicCoveragePromise(topicObj, token, currentType)),
//         dispatch(radarChartPromise(topicObj, token, currentType)),
//       ]);
//
//       dispatch(setLoadingStep2(false));
//
//       // get competition data
//          //scos language
//       dispatch(getCompetitionOnTopic(topic));
//
//     } catch (error) {
//       dispatch(setApiError(error));
//     }
//   };

export const getCompetitionOnTopic = (topic) => async (dispatch) => {
  try {
      //scos language
    dispatch(setLoadingCompetition(true));
    const competition = await requestCompetition(topic);
    dispatch(setCompetitionData(competition));
    dispatch(setLoadingCompetition(false));
  } catch (error) {
    dispatch(setApiError(error));
    dispatch(setApiErrorMessage("error getting competition"));
  }
};

// this method only applies when we are in application mode -> category
export const searchTopicForCategory = (value, language) => async (dispatch) => {
  const token = getUserToken();
  try {
    dispatch(setLoadingCompetition(true));
    dispatch(setLoadingStep2(true));
    dispatch(setTopicCoverageLoadingState(false));
    dispatch(setKeywordsSearch(value));

    const { topicCoverageHigh, expectedTitle } = await getTopicDetails(
      value,
      language,
      TYPE_DESCRIPTION,
      token
    );

    dispatch(setTopicCoverageData(topicCoverageHigh));
    dispatch(setExpectedTitle(expectedTitle));
    dispatch(setLoadingStep2(false));
    dispatch(setDescriptionDataLoaded(true));
console.log('nu are cum sa se faca de aici')
    dispatch(saveArticle(TYPE_DESCRIPTION));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const getCompetitionOnCategory =
  (topic, language) => async (dispatch) => {
    try {
      dispatch(setLoadingCompetition(true));
      const categoryCompetition = await requestCategoryCompetition(
        topic,
        language
      );
      const { categoryCompetitionData } = categoryCompetition;

      dispatch(setFetchCompetitionError(false));
      dispatch(setCompetitionData(categoryCompetitionData));

      dispatch(setLoadingCompetition(false));
    } catch (error) {
      dispatch(setFetchCompetitionError(true));
      dispatch(setApiError(error));
    }
  };

export const getTopicInfo = (topic) => async (dispatch) => {
  const token = getUserToken();

      try {
          // dispatch(setLoadingCompetition(true));
        const result = await topicInfo(topic, token);
        console.log('old info => ', result)
        console.log('topic info', result)
        dispatch(setTopicInfo(result));

      } catch (error) {
        dispatch(setApiError(error));

          // dispatch(setFetchCompetitionError(true));
          // dispatch(setApiError(error));
      }
  };

export const resetTopicData = () => (dispatch) => {
  dispatch(setTopicData(null));

  dispatch(setLoading(false));
};

export const resetCompetitionData = () => (dispatch) => {
  dispatch(setCompetitionData(null));
};

// selectors
export const getCompetitionData = ({ searchtopic: { competitionData } }) =>
  competitionData;

export const loadingTopicData = ({ searchtopic: { topicDataLoading } }) =>
  topicDataLoading;

export const loadingStep2Data = ({ searchtopic: { topicStep2Loading } }) =>
  topicStep2Loading;
export const getTopicResults = ({ searchtopic: { topicData } }) => topicData;
export const getTopicRules = ({ searchtopic: { topicRules } }) => topicRules;

export const getSearchKeywords = ({ searchtopic: { searchKeywords } }) =>
  searchKeywords;

export const getUserProjectId = ({ searchtopic: { userProjectId } }) =>
  userProjectId;
export const isSearchError = ({ searchtopic: { searchTopicError } }) =>
  searchTopicError;

export const loadingCompetitionData = ({
  searchtopic: { competitionDataLoading },
}) => competitionDataLoading;

export const isFetchDataError = ({ searchtopic: { fetchDataError } }) =>
  fetchDataError;

export const isFetchCompetitionError = ({
  searchtopic: { fetchCompetitionError },
}) => fetchCompetitionError;

export const getTopicInfoData = ({ searchtopic: { topicInfoData } }) => topicInfoData;
export default searchTopicSlice.reducer;
