import React from "react";

import "./AppModal.css";
import AppOverlay from "./AppOverlay";
import { CloseIcon } from '../UI_utils/SVGIcons'

const AppModal = (
    {
        icon: Icon,
        title, text,
        buttonText,
        buttonAction,
        secondaryButtonText,
        secondaryButtonAction,
        onCloseAction,
        infoText
    }) => {
  return (
      <div className="app-modal">
        <AppOverlay open={true} />
          <div className="app-modal__content">
            <div className="app-modal__header">
              <div className="app-modal-icon"><Icon /></div>
              {onCloseAction &&
                <CloseIcon onClick={onCloseAction}/>
              }
            </div>
            <div className="app-modal-title" dangerouslySetInnerHTML={{ __html: title }} />
            <div className="app-modal-text" dangerouslySetInnerHTML={{ __html: text }} />
            {buttonText && buttonAction && (
                <div className="app-modal__buttons-wrapper">
                    {secondaryButtonText && secondaryButtonAction && (
                        <div className="app-modal-button app-modal-button--secondary" onClick={secondaryButtonAction}>{secondaryButtonText}</div>
                    )}
                    <div className={`app-modal-button ${!secondaryButtonText || !secondaryButtonAction ? 'big-button' : ''}`} onClick={buttonAction}>{buttonText}</div>
                </div>
            )}
            {infoText && (
                <div className="app-modal__info-text" dangerouslySetInnerHTML={{ __html: infoText }} />
            )}
        </div>
      </div>
  );
};

export default AppModal;
