import React, {useEffect, useState} from "react";

import { useSelector } from "react-redux";
import CompetitionItem from "./CompetitionItem";
import CustomTooltip from "./CustomTooltip";
import {
  getCompetitionData,
  loadingCompetitionData,
  isFetchCompetitionError,
} from "../reducers/searchTopic";
import { getMaxImages } from "../reducers/images";
import HeadingsSummary from "./HeadingsSummary";
import { getApplicationMode } from "../reducers/applicationMode";
import CompetitionSkeletonPlaceholder from "./CompetitionSkeletonPlaceholder";
import FormErrorMessage from "./FormErrorMessage";

import "./Competition.css";
import Scrollbars from "react-custom-scrollbars";
import {CrossIcon} from "../UI_utils/SVGIcons";
import AppOverlay from "../Shared/AppOverlay";

const Competition = ({ closeCompetition }) => {
  const [open, setOpen] = useState(false);
  const [competitionData, setCompetitionData] = useState();
  const competition = useSelector(getCompetitionData);
  const maxImages = useSelector(getMaxImages);
  const appMode = useSelector(getApplicationMode);
  const isLoadingCompetition = useSelector(loadingCompetitionData);
  const competitionError = useSelector(isFetchCompetitionError);

  useEffect(() => {
    setCompetitionData(competition);
  }, [competition]);

  useEffect(() => setOpen(true), [])

  const isArticle = appMode === "article";
  const showContent = competitionData && !isLoadingCompetition;

  const transitionAndCloseSidebar = () => {
    setOpen(false)
    setTimeout(() => closeCompetition(), 500)
  }

  return (
    <>
      <AppOverlay open={open} close={transitionAndCloseSidebar}/>
      <div className={`competition ${open ? 'open' : ''}`}>
      {showContent && !competitionError && (
        <div className="competiton-head">
          <div className="competition-close-icon" onClick={transitionAndCloseSidebar}>
            <CrossIcon />
          </div>
          <h3>Competition Analysis</h3>
        </div>
      )}
      <Scrollbars>
        <div className="competition-content">
          <p className="competition-sub">
            An overview of top 10 articles ranking in Google for this topic
          </p>

          {showContent && isArticle && (
              <div className="top-numbers">
                <div className="competition-numbers info-words">
                  {(competitionData && competitionData.avgWords.toLocaleString()) ||
                      0}
                  <div className="competition-numbers-info">
                    <div className="competition-numbers-label">Average wordcount</div>
                  </div>
                </div>
                <div className="competition-numbers info-images">
                  {maxImages.toLocaleString()}
                  <div className="competition-numbers-info">
                    <div className="competition-numbers-label">Average images</div>
                  </div>
                </div>
              </div>
          )}

          {isLoadingCompetition && (
            <CompetitionSkeletonPlaceholder isArticle={isArticle} />
          )}

          {competitionError ? (
            <FormErrorMessage>
              Competition Data is not available currently
            </FormErrorMessage>
          ) : (
            <>
              {competitionData &&
                competitionData.competitionList &&
                competitionData.competitionList.map(({ id, domain, ...rest }) => (
                  <CompetitionItem key={id + domain} {...rest} domain={domain} />
                ))}

              {/*{competitionData && competitionData.allHtags && (*/}
              {/*  // <HeadingsSummary data={competitionData.allHtags} />*/}
              {/*)}*/}
            </>
          )}
        </div>
      </Scrollbars>
    </div>
    </>
  );
};

export default Competition;
