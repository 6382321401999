import React, {useEffect, useState} from 'react';
import './DefaultPromptSidebar.css'

import AppOverlay from "../../Shared/AppOverlay";
import DefaultPromptSidebarHeader from "./DefaultPromptSidebarHeader";
import {useDispatch, useSelector} from "react-redux";
import DefaultPromptSidebarContent from "./DefaultPromptSidebarContent";

const DefaultPromptSidebar = ({open, close = () => void(0)}) => {

    const dispatch = useDispatch();

    return (
        <>
            {open && (
                <div className="default-prompt-sidebar__wrapper">
                    <AppOverlay open={open} close={close} />

                    <div className="default-prompt-sidebar">
                        <DefaultPromptSidebarHeader close={close} />
                        <DefaultPromptSidebarContent />
                    </div>
                </div>
            )}
        </>
    );
};

export default DefaultPromptSidebar