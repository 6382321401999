import React from "react";
import SkeletonCardCompetition from "./SkeletonCardCompetition";
import SkeletonHeadCompetition from "./SkeletonHeadCompetition";

const CompetitionSkeletonPlaceholder = ({ isArticle }) => {
  return (
    <>
      <SkeletonHeadCompetition isArticle={isArticle} />
      <SkeletonCardCompetition />
      <SkeletonCardCompetition />
      <SkeletonCardCompetition />
      <SkeletonCardCompetition />
      <SkeletonCardCompetition />
      <SkeletonCardCompetition />
    </>
  );
};

export default CompetitionSkeletonPlaceholder;
