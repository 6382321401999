import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import "./SkeletonCardCompetition.css";

const SkeletonCardCompetition = () => {
  return (
    <SkeletonTheme color="#F0F4F8" highlightColor="#e2ecf7">
      <div className="skeleton-competition-card">
        <div className="skeleton-competition-card-info">
          <div className="skeleton-competition-card-circle">
            <Skeleton circle={true} height={48} width={48} />
          </div>
          <div className="skeleton-competition-bars">
            <div className="skeleton-competition-bars-top">
              <Skeleton width={80} height={10} />
              <Skeleton width={60} height={10} />
              <Skeleton width={60} height={10} />
              <Skeleton width={60} height={10} />
            </div>
            <div className="skeleton-competition-bars-bottom">
              <Skeleton width={`100%`} height={14} />
              <Skeleton width={`95%`} height={10} />
              <Skeleton width={`65%`} height={10} />
            </div>
          </div>
        </div>
        <div className="skeleton-competition-analyze">
          <Skeleton width={100} height={20} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonCardCompetition;
