import { createSlice } from "@reduxjs/toolkit";
import {
  matchingKeywords,
  topicCoverageMaxPoints,
  topicCoverageCurrentPoints,
} from "../utils/topicCoverage";
import {searchTopicSlice} from "./searchTopic";

/* in case no topic was searched, we set default data to whatever
we have in the local storage
this will be overriden when we search for a new topic
*/
// const topicsFromLocalStorage = JSON.parse(localStorage.getItem("topicCoverage"));

export const topicCoverageSlice = createSlice({
  name: "topiccoverage",
  initialState: {
    totalPoints: 0,
    currentPoints: 0,
    headingCurrentPoints: 0,
    activeTopicCoverage: [], // this is what we currently display
    lowTopics: [],
    highTopics: [],
    showLowTopics: false,
    loaded: false,
  },
  reducers: {
    setTopicCoverageLoadingState: (state, { payload }) => {
      return {
        ...state,
        loaded: payload,
      };
    },

    setTopicCoverage: (state, { payload }) => {
      return {
        ...state,
        activeTopicCoverage: payload,
      };
    },

    setHighTopicCoverage: (state, { payload }) => {
      return {
        ...state,
        highTopics: payload,
      };
    },

    setLowTopicCoverage: (state, { payload }) => {
      return {
        ...state,
        lowTopics: payload,
      };
    },

    setTopicCoverageMax: (state, { payload }) => {
      return {
        ...state,
        totalPoints: payload,
      };
    },

    setTopicCoverageCurrent: (state, { payload }) => {
      return {
        ...state,
        currentPoints: payload,
      };
    },
    setHeadingCurrentPoints: (state, { payload }) => {
      return {
        ...state,
        headingCurrentPoints: payload,
      };
    },

    switchTopics: (state, { payload }) => {
      return {
        ...state,
        activeTopicCoverage: payload,
      };
    },

    setTopicsFlag: (state, { payload }) => {
      return {
        ...state,
        showLowTopics: payload,
      };
    },
  },
});

// actions
export const { setTopicCoverageLoadingState } = topicCoverageSlice.actions;

export const { setTopicCoverage } = topicCoverageSlice.actions;
export const { setHighTopicCoverage } = topicCoverageSlice.actions;
export const { setLowTopicCoverage } = topicCoverageSlice.actions;

export const { setTopicCoverageMax } = topicCoverageSlice.actions;
export const { setTopicCoverageCurrent } = topicCoverageSlice.actions;
export const { setHeadingCurrentPoints } = topicCoverageSlice.actions;

export const { switchTopics } = topicCoverageSlice.actions;
export const { setTopicsFlag } = topicCoverageSlice.actions;

export const setTopicCoverageData = (data) => (dispatch) => {
  if(data) data.forEach(kw => kw.broad = false)
  dispatch(setTopicCoverage(data));
};

export const removeTopicFromList = (keyword) => (dispatch, getState) => {
  const { activeTopicCoverage } = getState().topiccoverage;
  const updateListOfKeywords = activeTopicCoverage.filter(kw => kw.keyword !== keyword);

  dispatch(setTopicCoverage(updateListOfKeywords));
};

export const updateMatchingWords = () => (dispatch, getState) => {
  const { parsedEditor } = getState().texteditor;
  const { parsedMetaData } = getState().categorytexteditor;
  const { applicationMode } = getState().applicationmode;

  const isCategory = applicationMode === "category";
  // console.log('parsedEditor => ', parsedEditor)
  // console.log('parsedMetaData.headings => ', parsedMetaData.headings)
  const concatData = {
    headings: parsedEditor.headings.concat(" ", parsedMetaData.headings),
    headingsArr: parsedEditor.headingsArr,
    headings1: parsedEditor.headings1.concat(" ", parsedMetaData.headings1),
    paragraphs: parsedEditor.paragraphs.concat(" ", parsedMetaData.paragraphs),
  };

  const parsedData = isCategory ? concatData : parsedEditor;

  const { activeTopicCoverage } = getState().topiccoverage;
  const matched = matchingKeywords(activeTopicCoverage, parsedData);

  dispatch(setTopicCoverage(matched));
};

export const updateMaxTopicCoveragePoints = (topicData) => (dispatch) => {
  // const maxPoints = topicCoverageMaxPoints(topicData);
  const filteredTopicData = topicData && topicData.length ? topicData.filter(topic => !topic.broad) : [];
  // const maxPoints = filteredTopicData.length;
  // dispatch(setTopicCoverageMax(maxPoints));

  // const headingPoints = filteredTopicData.filter(topic => topic.usedInHeading).length;
  // dispatch(setHeadingCurrentPoints(headingPoints))
  //
  // const currentPoints = filteredTopicData.filter(topic => topic.usedInParagraph).length;
  // dispatch(setTopicCoverageCurrent(currentPoints));


  const maxSV = filteredTopicData
      .map(topic => topic.search_volume)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  dispatch(setTopicCoverageMax(maxSV));

  const headingSV = filteredTopicData
      .filter(topic => topic.usedInHeading)
      .map(topic => topic.search_volume)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  dispatch(setHeadingCurrentPoints(headingSV))

  const currentSV = filteredTopicData
      .filter(topic => topic.usedInParagraph)
      .map(topic => topic.search_volume)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  dispatch(setTopicCoverageCurrent(currentSV));
};

// export const updateCurrentTopicCoveragePoints = (usedTopics) => (dispatch) => {
//   // const currentPoints = topicCoverageCurrentPoints(usedTopics);
//   const filteredUsedTopics = usedTopics.length ? usedTopics.filter((topic) => !topic.broad) : []
//   const currentPoints =  filteredUsedTopics?.length || 0;
//
//   dispatch(setTopicCoverageCurrent(currentPoints));
// };

export const toggleTopics = (displayLowTopics) => (dispatch, getState) => {
  const { lowTopics, highTopics, activeTopicCoverage } =
    getState().topiccoverage;
  if (displayLowTopics) {
    // first save everything into highTopics (user might have deleted some keywords)
    dispatch(setHighTopicCoverage(activeTopicCoverage));
    dispatch(switchTopics(lowTopics));
  } else {
    // same thing for lowTopics (user might have deleted some keywords)
    dispatch(setLowTopicCoverage(activeTopicCoverage));
    dispatch(switchTopics(highTopics));
  }
  dispatch(setTopicsFlag(displayLowTopics));
};

// selectors
export const getTopicCoverageLoaded = ({ topiccoverage: { loaded } }) => loaded;

export const getTopicCoverageData = ({
  topiccoverage: { activeTopicCoverage },
}) => activeTopicCoverage;

export const getMaxTopicCoverage = ({ topiccoverage: { totalPoints } }) => totalPoints;

export const getCurrentTopicCoverage = ({ topiccoverage: { currentPoints } }) => currentPoints;
export const getHeadingCurrentPoints = ({ topiccoverage: { headingCurrentPoints } }) => headingCurrentPoints;

export const getTopicCoveragePerc = ({
  topiccoverage: { currentPoints, totalPoints },
}) => {
  return Math.round((currentPoints * 100) / totalPoints || 0)
};
export const getHeadingCoveragePerc = ({
  topiccoverage: { headingCurrentPoints, totalPoints },
}) => {
  return Math.round((headingCurrentPoints * 100) / totalPoints || 0)
};

export const lowTopicsFlag = ({ topiccoverage: { showLowTopics } }) =>
  showLowTopics;

export const hasLowTopics = ({ topiccoverage: { lowTopics } }) =>
  lowTopics.length > 0;

export default topicCoverageSlice.reducer;
