import React, { useState } from "react";
import HeadingsInArticle from "./HeadingsInArticle";

import "./CompetitionItem.css";

const CompetitionItem = ({
  position,
  domain,
  url,
  words = 0,
  keywords = 0,
  visits = 0,
  title,
  description,
  htags,
}) => {
  const [expanded, setExpanded] = useState(false);

  const getCircleColor = (position) => {
    if (position > 8) {
      return "circle-position red";
    }

    if (position > 4) {
      return "circle-position orange";
    }

    if (position > 1) {
      return "circle-position yellow";
    }

    return "circle-position green";
  };

  const showExpandedButton = !expanded && htags !== "N/A" && htags.length > 0;

  return (
    <div className="competition-item">
      <div className="competition-body">
        <div className="item-content">
          <div className="item-top">
            <div className="item-position">
              <div className="number-position">{position}</div>
            </div>
            <a href={url} target="blank">
              <div className="item-top-stats">
                {domain}
              </div>
            </a>
            {words !== 0 && (
              <div className="item-top-stats count">
                {words.toLocaleString() || ""} words{" "}
              </div>
            )}
            {keywords !== 0 && (
              <div className="item-top-stats count">
                {keywords.toLocaleString()} keywords
              </div>
            )}
            {visits !== 0 && (
              <div className="item-top-stats count">
                {visits.toLocaleString()} visits
              </div>
            )}
          </div>
          <div className="competition-title">{title}</div>

          {description && (<div className="competition-desc">
            <span>{description}</span>
            {(showExpandedButton || expanded) && (
                <span className="expand-headings-text"
                      onClick={() => setExpanded(showExpandedButton)}>{showExpandedButton ? 'Show outline' : 'Hide outline'}</span>
            )}
          </div>)}
        </div>
      </div>
      {expanded && (
        <div className="headings-info">
            <>
              <HeadingsInArticle headings={htags} />
            </>
        </div>
      )}
    </div>
  );
};

export default CompetitionItem;
