import React, { useEffect, useState } from "react";
import { loadSpace } from '@usersnap/browser'

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import Login from "./Pages/Login";
import MessageRouteChange from "./Pages/MessageRouteChange";
// import Register from "./Pages/Register";
import Home from "./Pages/Home";
import Template from "./Shared/Template";
// import ResetPassword from "./Pages/ResetPassword";
// import Eshop from "./Pages/Eshop";
// import Native from "./Pages/Native";
import Dasboard from "./Pages/Dasboard";
// import ProjectBrief from "./Pages/ProjectBrief";
// import ProjectsDashboardPage from "./Pages/ProjectsDashboard";
import DesktopOnly from "./Components/DesktopOnly";
import { isDemoApplication } from "./reducers/applicationMode";
import {
  DEFAULT_ARTICLE_ID,
  LOGIN_PATH,
  // REGISTER_PATH,
  // RESET_PASS_PATH,
  HOME_PATH,
  DEMO_APP_PATH,
  // ESHOP_PATH,
  DASHBOARD_PATH, SPACE_API_KEY,
  // NATIVE_PATH,
  // CLIENT_BRIEF_PATH,
  // CLIENTS_DASHBOARD_PATH,
  // CHANGE_PASS_PATH,
  // PERFORMANCE_DASHBOARD_PATH,
  // PROJECTS_PERFORMANCE_DASHBOARD_PATH,
  // MULTI_STEP_REGISTER,
  // SEARCH_RESULTS_PATH,
  // AI_OUTLINE_PATH,
} from "./common/consts";
import { useScreenSize } from "./utils/useWindowSize";
import useCacheBuster from "./utils/useCacheBuster";
import {
  apiErrorMessage,
  isApiError,
  setApiError,
  setApiErrorMessage, setGenericErrorText, setShowGenericErrorModal, showErrorModal,setGenericHandler
} from "./reducers/apiErrors";
// import ChangePassowrd from "./Pages/ChangePassword";
// import PerformanceDashboardPage from "./Pages/PerformanceDashboard";
// import ProjectsPerformanceDashboardPage from "./Pages/ProjectsPerformanceDashboard";
// import MultistepRegister from "./Pages/MultistepRegister";
// import SearchResultsPage from "./Pages/SearchResults";
import { QueryClient, QueryClientProvider } from "react-query";
import {getUserToken} from "./utils/userStatus";
import {setGlobalState} from "./globals";
import TryAgainModal from "./Shared/TryAgainModal";
// import AIOutlinePage from "./Pages/AIOutline";
import {syncUser} from "./api/user";
import {
  // setIssueCounter,
  setMissingPagesCount,
  setPagesManyKeywordsCount,
  setSuboptimalContentCount,
  setWrongIntentKeywordCount,
  setLowRankPageCount,
  setFaqCount
} from "./reducers/contentAuditIssues";
import {setDraftConfigOptions, setUserData, setIsContentWriterAuthor, setUserCanEdit} from './reducers/user'
import ErrorBoundary from "./UI_utils/ErrorBoundary";

import { init as FullStoryInit, FullStory } from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import './assets/styles/_global_typography.scss'


const refreshCacheAndReload = () => {
  console.log("Clearing cache and hard reloading...");
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  }
  // delete browser cache and hard reload
  if (!window.location.hash) {
    window.location = window.location + "#loaded";
    window.location.reload(true);
  }
};

const App = () => {
  const isDemoApp = useSelector(isDemoApplication);
  const { forceRefresh } = useCacheBuster();
  const error = useSelector(isApiError);
  const dispatch = useDispatch();
  const errorMessage = useSelector(apiErrorMessage);
  const genericErrorModal = useSelector(showErrorModal);
  const [snackOpen, setSnackOpen] = useState(false);
  const token = getUserToken();

  const [userSynced, setUserSynced] = useState(false);

  if(!userSynced)
    syncUser().then(resp => resp.data ? setUserSynced(true) : false);
  
  // build a redirect url based on what domain the application is loaded on
  const redirectUrl = isDemoApp
    ? `${DEMO_APP_PATH}/${DEFAULT_ARTICLE_ID}`
    : `${token ? DASHBOARD_PATH : LOGIN_PATH}`;

  /* if application is in demo mode we need to change the url to this format: `/demo/article/1`*/
  const currentHomePath = isDemoApp
    ? `${DEMO_APP_PATH}/${DEFAULT_ARTICLE_ID}`
    : `${HOME_PATH}/:id`;

  const { screenWidth } = useScreenSize();

  useEffect(() => {
    if (forceRefresh) {
      refreshCacheAndReload();
    }
  }, [forceRefresh]);

  useEffect(() => {
    if (error) {
      setSnackOpen(true);
    }
  }, [error]);

  const onCloseSnack = () => {
    setSnackOpen(false);
    dispatch(setApiError(null));
    dispatch(setApiErrorMessage(null));
  };

  const closeGenericErrorModal = () => {
    dispatch(setShowGenericErrorModal(false))
    dispatch(setGenericErrorText(''))
  };

  const client = new QueryClient();


  // Get the current URL
  const currentUrl = window.location.search;
  // Parse the query parameters
  const urlSearchParams = new URLSearchParams(currentUrl);
  // Get the value of the 'site_id' parameter
  const siteId = urlSearchParams.get('site_id');
  const openArticlesSidebar = urlSearchParams.get('openArticlesSidebar');

  if(siteId) setGlobalState('site_id', siteId);
  if(openArticlesSidebar) setGlobalState('openArticlesSidebar', openArticlesSidebar);

  window['_fs_run_in_iframe'] = true;


  const issueTypeHandler = (data) => {
    switch (data.issueType)
    {
      case 'missingpages':
        dispatch(setMissingPagesCount(data.issueCount));
        break;
      case 'suboptimalcontent':
        dispatch(setSuboptimalContentCount(data.issueCount));
        break;
      case 'pagesmanykeywords':
        dispatch(setPagesManyKeywordsCount(data.issueCount));
        break;
      case 'wrongintentkeyword':
        dispatch(setWrongIntentKeywordCount(data.issueCount));
        break;
      case 'lowrankingpage':
        dispatch(setLowRankPageCount(data.issueCount));
        break;
    }
  }

  const userDataHandler = (data) => {
    dispatch(setUserData(data.userData));
    if(Sentry){
      Sentry.setUser({ email: data.userData.email })
    }
  }

  const draftConfigOptionsHandler = (data) => {
    dispatch(setDraftConfigOptions(data.draftConfigOptions));
  }

  const isContentWriterAuthorHandler = (data) => {
    dispatch(setIsContentWriterAuthor(data.isContentWriterAuthor));
  }

  const setUserCanEditDispatch = (canEdit) => {
    dispatch(setUserCanEdit(canEdit));
  }

  // const handleInterfaceError = (data) => {
  //   dispatch(setGenericErrorText({
  //       title:'Error',
  //       description:data.interfaceError.message,
  //       buttonText:'Close'
  //   }))
  //   const closeModalError = () => {
  //     dispatch(setShowGenericErrorModal(false))
  //   }
  //   dispatch(setGenericHandler(closeModalError));
  //   dispatch(setShowGenericErrorModal(true));
  // }

  loadSpace(SPACE_API_KEY).then((api) => {
    api.init();
    window.usersnapApi = api; // Save the API to the window object for easy access

  })

  const triggerScreenshot = () => {
    if (window.usersnapApi) {
      window.usersnapApi.logEvent('show_usersnap_button_app')
    } else {
      console.error('Usersnap API is not loaded yet.');
    }
  };

  useEffect(() => {
    // Listen for messages from the parent
    const receiveMessage = (event) => {
      // Ensure the message is from an allowed origin if needed
      // if (event.origin !== 'http://allowed-origin.com') return;
      const data = event.data;

      // Handle the received data from the parent (Vue app)
      if(data.issueType) {
        // setIssueCounter(data)
        issueTypeHandler(data)
      }

      if (data.userData) {
        userDataHandler(data)
      }

      if (data.draftConfigOptions) {
        draftConfigOptionsHandler(data);
      }

      if (data.isContentWriterAuthor) {
        isContentWriterAuthorHandler(data);
      }

      if (data.openUsersnap) {
        triggerScreenshot();
      }

      if (data.canEdit) {
        setUserCanEditDispatch(data.canEdit);
      }
      // if(data.interfaceError){
      //   handleInterfaceError(data)
      // }

      if(data.faqCount){
        // console.log(data.faqCount.count, 'count')
        dispatch(setFaqCount(data.faqCount.count))
      }

    };

    // Attach the event listener
    window.addEventListener('message', receiveMessage);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []); // Empty dependency array means this effect runs once on mount


  useEffect(() => {
    FullStoryInit({
      orgId: 'RR4G',
      cookieDomain: '.seomonitor.com',  // Ensure the cookie is shared across subdomains
    });

  }, []);

  return (
    <>
      {screenWidth > 768 ? (
        <>
          {genericErrorModal && (
              <TryAgainModal buttonAction={closeGenericErrorModal}/>
          )}
          {errorMessage && ( // Only render Snackbar if errorMessage exists
              <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={snackOpen}
                  onClose={onCloseSnack}
              >
                <Alert
                    onClose={onCloseSnack}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                  {errorMessage}
                </Alert>
              </Snackbar>
          )}

          <ErrorBoundary>
            <QueryClientProvider client={client}>
              <Router>
                  <MessageRouteChange />
                <Switch>
                  <Route path={LOGIN_PATH} exact>
                    {token ? (
                        <Redirect to={DASHBOARD_PATH} />
                    ) : (
                        <Template>
                          <Login />
                        </Template>
                    )}
                  </Route>
                  {/*<Route path={REGISTER_PATH} exact>*/}
                  {/*  <Template>*/}
                  {/*    <Register />*/}
                  {/*  </Template>*/}
                  {/*</Route>*/}
                  {/*<Route path={MULTI_STEP_REGISTER} exact>*/}
                  {/*  <Template>*/}
                  {/*    <MultistepRegister />*/}
                  {/*  </Template>*/}
                  {/*</Route>*/}
                  {/*<Route path={RESET_PASS_PATH} exact>*/}
                  {/*  <Template>*/}
                  {/*    <ResetPassword />*/}
                  {/*  </Template>*/}
                  {/*</Route>*/}
                  {/*<Route path={CHANGE_PASS_PATH} exact>*/}
                  {/*  <Template>*/}
                  {/*    <ChangePassowrd />*/}
                  {/*  </Template>*/}
                  {/*</Route>*/}
                  <Route path={currentHomePath}>
                    <Template>
                      <Home />
                    </Template>
                  </Route>
                  <Route path={DASHBOARD_PATH}>
                    <Template>
                      <Dasboard />
                    </Template>
                  </Route>

                  {/*<Route path={`${CLIENT_BRIEF_PATH}/:id`}>*/}
                  {/*  <Template>*/}
                  {/*    <ProjectBrief />*/}
                  {/*  </Template>*/}
                  {/*</Route>*/}
                  {/*<Route path={SEARCH_RESULTS_PATH}>*/}
                  {/*  <Template>*/}
                  {/*    <SearchResultsPage />*/}
                  {/*  </Template>*/}
                  {/*</Route>*/}
                  {/*<Route path={CLIENTS_DASHBOARD_PATH}>*/}
                  {/*  <Template>*/}
                  {/*    <ProjectsDashboardPage />*/}
                  {/*  </Template>*/}
                  {/*</Route>*/}
                  {/*<Route path={`${PERFORMANCE_DASHBOARD_PATH}/:clientId`}>*/}
                  {/*  <Template>*/}
                  {/*    <PerformanceDashboardPage />*/}
                  {/*  </Template>*/}
                  {/*</Route>*/}
                  {/*<Route path={PROJECTS_PERFORMANCE_DASHBOARD_PATH}>*/}
                  {/*  <Template>*/}
                  {/*    <ProjectsPerformanceDashboardPage />*/}
                  {/*  </Template>*/}
                  {/*</Route>*/}
                  {/*<Route path={`${ESHOP_PATH}/:id`}>*/}
                  {/*  <Template>*/}
                  {/*    <Eshop />*/}
                  {/*  </Template>*/}
                  {/*</Route>*/}
                  {/*<Route path={`${NATIVE_PATH}/:id`}>*/}
                  {/*  <Template>*/}
                  {/*    <Native />*/}
                  {/*  </Template>*/}
                  {/*</Route>*/}
                  <Redirect to={redirectUrl} />
                </Switch>
              </Router>
            </QueryClientProvider>
          </ErrorBoundary>
        </>
      ) : (
        <DesktopOnly />
      )}
    </>
  );
};

export default App;
