import React from "react";

import "./Template.css";

const Template = ({ children }) => {
  return (
    <div className="template">
      <main className="template-body">{children}</main>
    </div>
  );
};

export default Template;
