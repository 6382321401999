import {
  changePassRequest, getProjectDefaultPrompt,
  getUserProfile,
  loginRequest,
  multistepRegisterRequest,
  registerRequest,
  resetPassRequest, saveProjectDefaultPrompt,
} from "../api/user";

import { getMasters } from "../api/dasboard";
import {getUserToken} from "../utils/userStatus";

// user profile
export const requestUserProfile = async (token) => {
  const activeUser = await getUserProfile(token);

  return activeUser;
};

// get all users with role MASTER - needed for comments plugin
export const requestAllMasters = async (token) => {
  const activeUser = await getMasters(token);

  return activeUser;
};

// login
export const userLogin = async (values) => {
  const {
    email: { value: email },
    password: { value: password },
  } = values;

  const user = {
    username: email,
    password,
  };

  const userToken = await loginRequest(user);

  return userToken;
};

// forgot pass
export const resetPass = async (values) => {
  const {
    email: { value: email },
  } = values;

  const reset = await resetPassRequest({ email });

  return reset;
};

// change pass (step2)
export const changePass = async (values, token) => {
  const {
    password: { value: password },
  } = values;

  const reset = await changePassRequest({ password, token });

  return reset;
};

// register
export const registerUser = async (values) => {
  const user = transformForPost(values);
  try {
    const userStatus = await registerRequest(user);
    return userStatus;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

// multistept register
export const multistepRegisterReq = async (values) => {
  try {
    const userStatus = await multistepRegisterRequest(values);
    return userStatus;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

const transformForPost = (data) => {
  const {
    firstname: { value: firstname },
    lastname: { value: lastname },
    email: { value: email },
    password: { value: password },
    registrationCode: { value: registrationCode },
  } = data;

  const user = {
    firstname,
    lastname,
    email,
    password,
    registrationCode,
  };

  return user;
};


export const getProjectDefaultPromptReq = async (siteId) => {
    const token = getUserToken();

    const defaultPrompt = await getProjectDefaultPrompt(siteId, token);

    return defaultPrompt.error ? '' : defaultPrompt;
};

export const saveProjectDefaultPromptReq = async (siteId, prompt) => {
  const token = getUserToken();

  try {
    const savedPrompt = await saveProjectDefaultPrompt(siteId, prompt, token);
    return savedPrompt;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};
