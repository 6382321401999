import React from "react";

import "./DesktopOnly.css";

const DesktopOnly = () => {
  return (
    <div className="desktop-wrapper">
      <p>The editor demo is available only for desktop devices.</p>
    </div>
  );
};

export default DesktopOnly;
