import { createSlice } from "@reduxjs/toolkit";

export const featureFlagsSlice = createSlice({
  name: "featureflags",
  initialState: {
    showTopicLanguages: null,
    newTopicSearch: null,
  },
  reducers: {
    setFeatureFlags: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

// actions
export const { setFeatureFlags } = featureFlagsSlice.actions;

// selectors
export const getFeatureFlags = ({ featureFlags }) => featureFlags;

export default featureFlagsSlice.reducer;
