import React from 'react';
import './NewArticleModal.css'
import AppOverlay from "../../Shared/AppOverlay";
import NewArticleBox from "./NewArticleBox";

const NewArticleModal = ({noArticles, close = () => void(0)}) => {
    return (
        <div className="new-article-modal__wrapper">
            <AppOverlay open={true} close={close} />
            <NewArticleBox noArticles={noArticles} close={close}/>
        </div>
    );
};

export default NewArticleModal