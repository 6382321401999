import {
  CREATED,
  DEADLINE,
  WRITER,
  PROOFREADER,
  USER_WRITER,
  USER_PROOFREADER,
  STATUS,
  TYPE_NATIVE,
  TYPE_DESCRIPTION,
  NATIVE_PATH,
  ESHOP_PATH,
  HOME_PATH,
  DEADLINE_CLOSE,
  DEADLINE_PASSED,
} from "../common/consts";

import { formatDateForServer, compareDates } from "../utils/dateUtility";

export const displayEntryType = (type) => {
  switch (type) {
    case TYPE_NATIVE:
      return "Native";
    case TYPE_DESCRIPTION:
      return "Description";
    default:
      return "Article";
  }
};

export const getNextUrl = (type) => {
  switch (type) {
    case TYPE_NATIVE:
      return NATIVE_PATH;
    case TYPE_DESCRIPTION:
      return ESHOP_PATH;
    default:
      return HOME_PATH;
  }
};

const assignWriter = { value: 1, label: "Assign writer" };
const unassignWriter = { value: 2, label: "Unassign writer" };
const assignAnotherWriter = { value: 3, label: "Assign another writer" };

const assignProofreader = { value: 4, label: "Assign proofreader" };
const unassignProofreader = { value: 5, label: "Unassign proofreader" };
const assignAnotherProofreader = {
  value: 6,
  label: "Assign another proofreader",
};

const fullRightsBulkOptions = [
  { value: 1, label: "Assign writer", updateProperty: WRITER },
  { value: 2, label: "Unassign writer", updateProperty: WRITER },
  { value: 3, label: "Assign proofreader", updateProperty: PROOFREADER },
  { value: 4, label: "Unassign proofreader", updateProperty: PROOFREADER },
  { value: 5, label: "Change deadline", updateProperty: DEADLINE },
  { value: 6, label: "Change creation date", updateProperty: CREATED },
  { value: 7, label: "Change status", updateProperty: STATUS },
  { value: 8, label: "Delete article", updateProperty: "delete" },
];

const restrictedBulkOptions = [
  { value: 8, label: "Delete article", updateProperty: "delete" },
];

export const customMenuOptions = (currentUserType) => {
  if (
    userHasPermission(currentUserType, USER_PROOFREADER) ||
    userHasPermission(currentUserType, USER_WRITER)
  ) {
    return restrictedBulkOptions;
  } else {
    return fullRightsBulkOptions;
  }
};

export const dymanicMenuOptions = (row, currentUserType) => {
  let genericActions = [
    { value: 12, label: "Edit live URL" },
    // { value: 7, label: "Change deadline" },
    { value: 8, label: "Change creation date" },
    { value: 11, label: "Edit article" },
    { value: 10, label: "Delete article" },
  ];

  // if (row.proofreader) {
  //   genericActions = buildMenu(genericActions, [
  //     unassignProofreader,
  //     assignAnotherProofreader,
  //   ]);
  // } else {
  //   genericActions = buildMenu(genericActions, [assignProofreader]);
  // }
  //
  // if (row.writer) {
  //   genericActions = buildMenu(genericActions, [
  //     unassignWriter,
  //     assignAnotherWriter,
  //   ]);
  // } else {
  //   genericActions = buildMenu(genericActions, [assignWriter]);
  // }
  //
  // if (
  //   userHasPermission(currentUserType, USER_PROOFREADER) ||
  //   userHasPermission(currentUserType, USER_WRITER)
  // ) {
  //   genericActions = [
  //     { value: 9, label: "Rename topic" },
  //     { value: 10, label: "Delete article" },
  //   ];
  // }
  return genericActions;
};

const buildMenu = (all, newValue) => {
  const newArray = [...newValue, ...all];

  return newArray;
};

export const getDefaultValue = (val, options) => {
  const selected = options && options.find(({ id }) => id === val);
  if (selected) {
    return {
      label: selected.name,
      value: selected.id,
      color: selected.color,
    };
  }

  return null;
};

export const getDefaultValuePill = (val, options) => {
  const selected = options && options.find((option) => option.id === val);
  if (selected) {
    return {
      ...selected,
      label: selected.name,
      value: selected.id,
      color: selected.color,
    };
  }

  return null;
};

export const userHasPermission = (currentUser, requiredPermission) =>
  currentUser === requiredPermission;

export const updateLocalStateWithOusideValues = (
  tableData,
  articleIds,
  updateProperty,
  selectedOption
) => {
  const newDataFeed = tableData.map((row) => {
    articleIds.forEach((articleId) => {
      if (row.id === articleId.id) {
        row = {
          ...row,
          [updateProperty]: selectedOption
            ? selectedOption.value
            : selectedOption,
        };
      }
      return;
    });
    return row;
  });

  return newDataFeed;
};

export const markSelectedRowsInData = (tableData, selectedRows) => {
  const markedAsSelected = tableData.map((row) => {
    selectedRows.forEach((selectedRow) => {
      if (row.id === selectedRow.id) {
        row = {
          ...row,
          selected: true,
        };
      }
      return;
    });
    return row;
  });
  return markedAsSelected;
};

export const updateTableInLocalState = (initialTable, updatedRows) => {
  const updatedTable = initialTable.map(
    (initialRow) =>
      updatedRows.find((updatedRow) => updatedRow.id === initialRow.id) ||
      initialRow
  );

  return updatedTable;
};

const cleanText = (text) => {
  return text.replace(/\n/g, " ");
};

const cleanContent = (content) => {
  return content.replace(
    /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,
    " "
  );
};

export const getTableForExport = (
  tableData,
  projects,
  feedbacks,
  statuses,
  industries,
  writers,
  proofreaders
) => {
  const exportedTable =
    tableData &&
    tableData.map((tableRow) => {
      // replacing new lines from description
      // const description = tableRow.info && tableRow.info;
      // const cleanInfo = description && description.replace(/\n/g, " ");
      const newRow = {
        briefUrl: tableRow.briefUrl && tableRow.briefUrl,
        publisherUrl: tableRow.publisherUrl && tableRow.publisherUrl,
        topicUrl: tableRow.topicUrl && tableRow.topicUrl,
        id: tableRow.id && tableRow.id,
        // info: cleanInfo,
        info: tableRow.info && cleanText(tableRow.info),
        created: tableRow.created && formatDateForServer(tableRow.created),
        deadline: tableRow.deadline && formatDateForServer(tableRow.deadline),
        feedback:
          tableRow.feedback && returnValueById(tableRow.feedback, feedbacks),
        writer: tableRow.writer && returnValueById(tableRow.writer, writers),
        industry:
          tableRow.industry && returnValueById(tableRow.industry, industries),
        status: tableRow.status && returnValueById(tableRow.status, statuses),
        project:
          tableRow.project && returnValueById(tableRow.project, projects),
        proofreader:
          tableRow.proofreader &&
          returnValueById(tableRow.proofreader, proofreaders),
        type:
          tableRow.type === 0
            ? "Article"
            : tableRow.type === 1
            ? "Description"
            : "Native",
        topic: tableRow.topic && tableRow.topic,
        metaDescription:
          tableRow.metaDescription && cleanText(tableRow.metaDescription),
        metaTitle: tableRow.metaTitle && tableRow.metaTitle,
        h1Tag: tableRow.h1Tag && tableRow.h1Tag,
        anchor1: tableRow.anchor1 && tableRow.anchor1,
        anchor2: tableRow.anchor2 && tableRow.anchor2,
        url1: tableRow.url1 && tableRow.url1,
        url2: tableRow.url2 && tableRow.url2,
        technicalCheck: tableRow.technicalCheck && tableRow.technicalCheck,
        content: tableRow.content && cleanContent(tableRow.content),
      };

      return newRow;
    });

  return convertArrayOfObjectsToCSV(exportedTable);
};

const returnValueById = (id, currentArray) => {
  const obj = currentArray.find((item) => item.id === id);
  // return the value of the identified object
  return obj.name;
};

const convertArrayOfObjectsToCSV = (tableData) => {
  const csvString = [
    [
      "Id",
      "Type",
      "Topic",
      "Topic Url",
      "Writer",
      "Proofreader",
      "Project", // this is project
      "Industry",
      "Status",
      "Article Brief",
      "Meta Title",
      "Meta Description",
      "H1",
      "Technical Check",
      "anchor 1",
      "url1",
      "anchor 2",
      "url2",
      "Publisher Url",
      "Deadline",
      "content",
    ],
    ...tableData.map((item) => [
      item.id,
      item.type,
      item.topic,
      item.topicUrl,
      item.writer,
      item.proofreader,
      item.project,
      item.industry,
      item.status,
      item.info,
      item.metaTitle,
      item.metaDescription,
      item.h1Tag,
      item.technicalCheck,
      item.anchor1,
      item.url1,
      item.anchor2,
      item.url2,
      item.publisherUrl,
      item.deadline,
      item.content,
    ]),
  ]
    .map((e) => e.join(","))
    .join("\n");

  return csvString;
};

export const customSortedTable = (tableData) => {
  // status id 4 -> implementing feedback
  // status id 5 -> Re-assigned to proof

  const byStatus =
    tableData &&
    tableData.filter((row) => row.status === 4 || row.status === 5);

  const restByStatus =
    tableData &&
    tableData.filter((row) => row.status !== 4 && row.status !== 5);

  const withDeadlineStatus =
    restByStatus &&
    restByStatus.map((row) => {
      const deadlineStatus = compareDates(row.deadline);
      return {
        ...row,
        deadlineStatus,
      };
    });

  const withDeadlinePassed =
    withDeadlineStatus &&
    withDeadlineStatus.filter((row) => row.deadlineStatus === DEADLINE_PASSED);

  const withDeadlineClose =
    withDeadlineStatus &&
    withDeadlineStatus.filter((row) => row.deadlineStatus === DEADLINE_CLOSE);

  const restWithDeadline =
    withDeadlineStatus &&
    withDeadlineStatus.filter(
      (row) =>
        row.deadlineStatus !== DEADLINE_CLOSE &&
        row.deadlineStatus !== DEADLINE_PASSED
    );

  const unassignedStatus =
    restWithDeadline && restWithDeadline.filter((row) => !row.status);
  const assignedStatus =
    restWithDeadline && restWithDeadline.filter((row) => row.status);

  const dataByDeadline =
    withDeadlinePassed &&
    withDeadlineClose &&
    assignedStatus &&
    unassignedStatus
      ? [
          ...byStatus,
          ...withDeadlinePassed,
          ...withDeadlineClose,
          ...assignedStatus,
          ...unassignedStatus,
        ]
      : [];

  return dataByDeadline;
};

export const extractHeadingContent = (htmlString, headingType = 'h1') => {
  // Create a regular expression to match the content inside the specified heading type
  const regex = new RegExp(`<${headingType}[^>]*>([^<]+)<\/${headingType}>`, 'i');

  // Use the exec method to search for the pattern in the HTML string
  const match = regex.exec(htmlString);

  // Check if there is a match and if the content inside the specified heading is not empty
  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
}