import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";
import { setArticleId } from "../../reducers/articles";

import "./ManagementDashboard.css";

import { getDashboardData, dashboardData, dashboardNoDataResults } from "../../reducers/dashboard";
import { setTopicCoverageData } from "../../reducers/topicCoverage";

import { customSortedTable } from "../dashboardComponentHelpers";

import "./DashboardComponent.css";
import { setKeywordsSearch, setTopicData } from "../../reducers/searchTopic";
import { getGlobalState } from "../../globals";
import NewArticleModal from "../NewArticleModal/NewArticleModal";
import AppLoadingScreen from "../../Shared/AppLoadingScreen";
import ArticlesSidebar from "../ArticlesSidebar/ArticlesSidebar";
import NewArticleBox from "../NewArticleModal/NewArticleBox";

const DasboardComponent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const urlSearch = queryParams.get("search");

  const dispatch = useDispatch();
  const history = useHistory();
  const dashboard = useSelector(dashboardData);

  const noResults = useSelector(dashboardNoDataResults);


  const siteId = getGlobalState('site_id');
  const defaultFilters = siteId ? { project: [siteId] } : null;

  const [tableData, setTableData] = useState([]);
  const [tableIsUpdated, setTableIsUpdated] = useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentFilters, setCurrentFilters] = useState(defaultFilters);
  const [searchString, setSearchString] = useState(urlSearch);
  const [sortedBy, setSortedBy] = useState(null);


  // clean up all article related data when loading this page
  useEffect(() => {
    dispatch(setArticleId(null));
    dispatch(setTopicData(null));
    //   // reset topic coverage data when coming from eshop
    dispatch(setTopicCoverageData([]));
    //   // reset editor data to empty
    // dispatch(resetEditorData(true));
    dispatch(setKeywordsSearch(null));
  }, []);

  useEffect(() => {
    dispatch(
        getDashboardData(
            currentPage,
            rowsPerPage,
            searchString,
            currentFilters,
            sortedBy
        )
    );
  }, [
    currentPage,
    rowsPerPage,
    tableIsUpdated,
    searchString,
    currentFilters,
    sortedBy,
  ]);

  useEffect(() => {
    // sort data here
    const sorted = customSortedTable(dashboard);
    setTableData(sorted);
    setTableIsUpdated(false);
  }, [dashboard]);


  // useEffect(() => {
  //   // Redirect the user after the component mounts
  //   if(!noResults && tableData.length) {
  //     const id = tableData[0].id;
  //     const path = `/article/${id}?site_id=${siteId}`;
  //     history.push(path);

  //   }
  // }, [noResults, tableData]);

  // if(noResults) return <NewArticleModal noArticles={true} />
  return (
      <div className={`dashboard-page ${!noResults ? 'dashboard-page--sidebar' : ''}`}>
        {!noResults && (
            <div className="dashboard-page__sidebar">
              <ArticlesSidebar open={!noResults} close={false} absoluteSidebar={false} />
            </div>
        )}
        <div className="dashboard-page__content">
          <NewArticleBox noArticles={noResults} close={false} />
        </div>
      </div>
  )

  return <AppLoadingScreen />


};

export default DasboardComponent;
