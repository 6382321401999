export const isEshopEditor = () => {
  const isEshop = getCurrentLocation("eshop");

  return isEshop;
};

export const isNativeEditor = () => {
  const isNative = getCurrentLocation("native");

  return isNative;
};

export const isDemoApp = () => getCurrentLocation("demo");

const getCurrentLocation = (location) => {
  const currentUrl = window.location.pathname;
  const isLocation = currentUrl.includes(location);

  return isLocation;
};
