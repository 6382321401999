import { createSlice } from "@reduxjs/toolkit";

export const aiKeywordsSlice = createSlice({
  name: "aikeywords",
  initialState: {
    generatedKeywords: null,
  },
  reducers: {
    setAIKeywords: (state, { payload }) => {
      return {
        ...state,
        generatedKeywords: payload,
      };
    },
  },
});

// actions
export const { setAIKeywords } = aiKeywordsSlice.actions;

// selectors

export const getAIKeywords = ({ aikeywords: { generatedKeywords } }) => generatedKeywords;

export default aiKeywordsSlice.reducer;
