import React, { useEffect, useState } from 'react';
import LoadingBlock  from "../LoadingBlock/LoadingBlock";

import './ArticleKeywordsTableGroupLoading.css'

const ArticleKeywordsTableGroupLoading = () => {

    const numberOfItems = [1,2,3]

    return (
        <div className="article-keywords-table-group-loading">
            <LoadingBlock height="32" width="100%" />
            {numberOfItems.map((item) => (
                <div className="article-keywords-table-group-loading--row" key={item}>
                    <div className="article-keywords-table-column column--keyword flex-grow">
                        <LoadingBlock height="24" width="100px" />
                    </div>
                    <div className="article-keywords-table-column column--volume">
                        <LoadingBlock height="24" width="40px" />
                    </div>
                    <div className="article-keywords-table-column column--presence">
                        <LoadingBlock height="24" width="100%" />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ArticleKeywordsTableGroupLoading;
