import { createSelector, createSlice } from "@reduxjs/toolkit";

export const contentAuditIssuesSlice = createSlice({
    name: "contentauditissues",
    initialState: {
        missingPagesCount: null,
        wrongIntentKeywordCount: null,
        suboptimalContentCount: null,
        pagesManyKeywordsCount: null,
        lowRankPageCount: null,
        faqCount:null,
        importFaqData: null
        // issuesCounters: {
        //     missingpages: null,
        //     wrongintentkeyword: null,
        //     suboptimalcontent: null,
        //     pagesmanykeywords: null,
        // }
    },
    reducers: {
        // setIssuesCounters: (state, 3{ payload }) => {
        //     return {
        //         ...state,
        //         issuesCounters: payload,
        //     };
        // },
        setMissingPagesCount: (state, { payload }) => {
            return {
                ...state,
                missingPagesCount: payload,
            };
        },
        setWrongIntentKeywordCount: (state, { payload }) => {
            return {
                ...state,
                wrongIntentKeywordCount: payload,
            };
        },
        setSuboptimalContentCount: (state, { payload }) => {
            return {
                ...state,
                suboptimalContentCount: payload,
            };
        },
        setPagesManyKeywordsCount: (state, { payload }) => {
            return {
                ...state,
                pagesManyKeywordsCount: payload,
            };
        },
        setLowRankPageCount : (state, {payload}) => {
            return {
                ...state,
                lowRankPageCount: payload
            }
        },
        setFaqCount:(state, {payload}) => {
            return {
                ...state,
                faqCount: payload
            }
        },
        setImportFaqData:(state, {payload}) => {
            return {
                ...state,
                importFaqData: payload
            }
        }

    },
});

// actions
// export const { setIssuesCounters } = contentAuditIssuesSlice.actions;
export const { setMissingPagesCount } = contentAuditIssuesSlice.actions;
export const { setWrongIntentKeywordCount } = contentAuditIssuesSlice.actions;
export const { setSuboptimalContentCount } = contentAuditIssuesSlice.actions;
export const { setPagesManyKeywordsCount } = contentAuditIssuesSlice.actions;
export const { setLowRankPageCount } = contentAuditIssuesSlice.actions;
export const { setFaqCount } = contentAuditIssuesSlice.actions;
export const { setImportFaqData } = contentAuditIssuesSlice.actions;
export const setIssueCounter = (data) => (dispatch) => {
    console.log('get here data.issueType => ', data.issueType)
    switch (data.issueType)
    {
        case 'missingpages':
            dispatch(setMissingPagesCount(data.issueCount));
            break;
        case 'suboptimalcontent':
            dispatch(setSuboptimalContentCount(data.issueCount));
            break;
        case 'pagesmanykeywords':
            dispatch(setPagesManyKeywordsCount(data.issueCount));
            break;
        case 'wrongintentkeyword':
            dispatch(setWrongIntentKeywordCount(data.issueCount));
            break;
        case 'lowrankingpage':
            dispatch(setLowRankPageCount(data.issueCount));
            break;
    }
}

// selectors

// export const getIssuesCounters = ({ contentauditissues: { issuesCounters } }) => issuesCounters;
export const getMissingPagesCount = ({ contentauditissues: { missingPagesCount } }) => missingPagesCount;
export const getWrongIntentKeywordCount = ({ contentauditissues: { wrongIntentKeywordCount } }) => wrongIntentKeywordCount;
export const getSuboptimalContentCount = ({ contentauditissues: { suboptimalContentCount } }) => suboptimalContentCount;
export const getPagesManyKeywordsCount = ({ contentauditissues: { pagesManyKeywordsCount } }) => pagesManyKeywordsCount;
export const getLowRankPageCount = ({contentauditissues: { lowRankPageCount}}) => lowRankPageCount;
export const getFaqCount = ({contentauditissues: { faqCount}}) => faqCount;
export const getImportFaqData = ({ contentauditissues: { importFaqData } }) => importFaqData 
export default contentAuditIssuesSlice.reducer;
