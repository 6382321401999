import { createSlice } from "@reduxjs/toolkit";

export const articleDataFlagsSlice = createSlice({
  name: "articledataflags",
  initialState: {
    searchIntentPromise: false,
    estimatedVisitsPromise: false,
    searchVolumePromise: false,
    titleCoveragePromise: false,
    headingCoveragePromise: false,
    topicCoveragePromise: false,
    radarChartPromise: false,
  },
  reducers: {
    setSearchIntentPromise: (state, { payload }) => {
      return {
        ...state,
        searchIntentPromise: payload,
      };
    },
    setEstimatedVisitsPromise: (state, { payload }) => {
      return {
        ...state,
        estimatedVisitsPromise: payload,
      };
    },
    setSearchVolumePromise: (state, { payload }) => {
      return {
        ...state,
        searchVolumePromise: payload,
      };
    },
    setTitleCoveragePromise: (state, { payload }) => {
      return {
        ...state,
        titleCoveragePromise: payload,
      };
    },
    setHeadingCoveragePromise: (state, { payload }) => {
      return {
        ...state,
        headingCoveragePromise: payload,
      };
    },
    setTopicCoveragePromise: (state, { payload }) => {
      return {
        ...state,
        topicCoveragePromise: payload,
      };
    },
    setRadarChartPromise: (state, { payload }) => {
      return {
        ...state,
        radarChartPromise: payload,
      };
    },
  },
});

// actions
export const { setSearchIntentPromise } = articleDataFlagsSlice.actions;
export const { setEstimatedVisitsPromise } = articleDataFlagsSlice.actions;
export const { setSearchVolumePromise } = articleDataFlagsSlice.actions;
export const { setTitleCoveragePromise } = articleDataFlagsSlice.actions;
export const { setHeadingCoveragePromise } = articleDataFlagsSlice.actions;
export const { setTopicCoveragePromise } = articleDataFlagsSlice.actions;
export const { setRadarChartPromise } = articleDataFlagsSlice.actions;


// selectors
export const getSearchIntentPromise = ({ articledataflags: { searchIntentPromise } }) => searchIntentPromise;
export const getEstimatedVisitsPromise = ({ articledataflags: { estimatedVisitsPromise } }) => estimatedVisitsPromise;
export const getSearchVolumePromise = ({ articledataflags: { searchVolumePromise } }) => searchVolumePromise;
export const getTitleCoveragePromise = ({ articledataflags: { titleCoveragePromise } }) => titleCoveragePromise;
export const getHeadingCoveragePromise = ({ articledataflags: { headingCoveragePromise } }) => headingCoveragePromise;
export const getTopicCoveragePromise = ({ articledataflags: { topicCoveragePromise } }) => topicCoveragePromise;
export const getRadarChartPromise = ({ articledataflags: { radarChartPromise } }) => radarChartPromise;

export const getAllDataFetched = (state) => {
  const {
    searchIntentPromise,
    estimatedVisitsPromise,
    searchVolumePromise,
    titleCoveragePromise,
    headingCoveragePromise,
    topicCoveragePromise,
    radarChartPromise,
  } = state.articledataflags;

  return (
      searchIntentPromise &&
      estimatedVisitsPromise &&
      searchVolumePromise &&
      titleCoveragePromise &&
      headingCoveragePromise &&
      topicCoveragePromise &&
      radarChartPromise
  );
};

export default articleDataFlagsSlice.reducer;
