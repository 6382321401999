import React from 'react';
import './ArticlesSidebarItem.css'
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import LoadingBlock from "../LoadingBlock/LoadingBlock";

const ArticlesSidebarItemPlaceholder = () => {

    return (
        <div className="articles-sidebar-item__wrapper">
            <div className="articles-sidebar-item">
                <div className="article-sidebar-item-info">
                    <div className="article-item-topic_wrapper">
                        <div className="article-item-topic"><LoadingBlock /></div>
                        <div className="article-item-add-traffic"><LoadingBlock /></div>
                    </div>
                    <div className={`article-item-title`}><LoadingBlock /></div>
                    <div className="article-item-details">
                        <div className="article-item-date"><LoadingBlock /></div>
                        <div className="article-item-author"><LoadingBlock /></div>
                    </div>
                </div>
                <div className="article-sidebar-item-score">
                    <CircularProgressbarWithChildren
                        value={0}
                        strokeWidth={9}
                        styles={buildStyles({
                            pathTransitionDuration: 0.5,
                            pathColor: '#40E246',
                            strokeLinecap: 'butt',
                            backgroundColor: 'transparent',
                        })}>
                    </CircularProgressbarWithChildren>
                </div>
            </div>
        </div>
    );
};

export default ArticlesSidebarItemPlaceholder