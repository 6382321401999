import React from 'react';
import './ModalHowItWorks.css'
import HowItWorksBox from "./HowItWorksBox";

const ModalHowItWorks = () => {

    const dataArray = [
        {
            title: 'Keyword Research',
            text: 'Input your target keyword and get a list of related keywords within the topic',
            clickableSpan: false,
            section: '',
        },
        {
            title: 'SEO Brief Generation',
            text: 'AI analyzes top-ranking pages to create a detailed SEO brief',
            clickableSpan: false,
            section: '',
        },
        {
            title: 'Outline Optimization',
            text: 'Customize the AI-generated outline and add your content guidelines',
            clickableSpan: false,
            section: '',
        },
        {
            title: 'Article Generation',
            text: 'Review and refine the AI-written, SEO-optimized article with automatic link and image suggestions',
            clickableSpan: false,
            section: '',
        },
        {
            title: 'Rank & Excel',
            text: 'Publish your optimized content and monitor performance with integrated analytics',
            clickableSpan: false,
            section: '',
        },
    ];

    const handleClick = (event, clickable, section) => {
        if(event.target.tagName.toLowerCase() === 'span' && clickable) {
            console.log('section => ', section)
            window.parent.postMessage({ section }, '*');
        }
    }

    return (
        <>
            <div className="modal-how-it-works">
                <div className="modal-how-it-works_title">How<br />it works</div>
                <div className="modal-how-it-works_boxes">
                    {dataArray.map((item, index) => (
                        <HowItWorksBox
                            key={index}
                            step={index + 1}
                            title={item.title}
                            text={item.text}
                            section={item.section}
                            clickable={item.clickableSpan}
                            clickHandler={handleClick}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default ModalHowItWorks