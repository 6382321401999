import React, { useState, useCallback } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVert from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  menu: {
    "&:last-child": {
      color: "#ed1927",
    },
    "&:hover": {
      fontWeight: "bold",
    },
    fontSize: ".8rem",
  },
}));

const CustomMenu2 = ({ row, menuOptions, handleRowAction }) => {
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [, setSelectedOption] = useState();

  const handleOnchange = useCallback(
    (option) => () => {
      setSelectedOption(option);
      handleRowAction(row, option);
      setOpen(false);
    },
    [row]
  );

  const handleClick = (e, option) => {
    setSelectedOption(option);
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <>
      <Button onClick={(option) => handleClick(option)}>
        <MoreVert onClick={(option) => handleClick(option)} />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuOptions.map((option) => (
          <MenuItem
            key={option.label}
            onClick={handleOnchange(option)}
            className={classes.menu}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CustomMenu2;
