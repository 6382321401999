// default for topic 'obiective turistice Londra'
export const defaultTopicResults = {
  estimatedVisits: null,
  monthSearches: 1270,
  searchVolume: 1270,
  titleCoverage: [],
  headingCoverage: [],
  monthlyValues: [
    { id: 0, name: "Jan", value: 2210, fill: "#F2F4F9", currentMonth: false },
    { id: 1, name: "Feb", value: 1780, fill: "#F2F4F9", currentMonth: false },
    { id: 2, name: "Mar", value: 1030, fill: "#F2F4F9", currentMonth: false },
    { id: 3, name: "Apr", value: 710, fill: "#F2F4F9", currentMonth: false },
    { id: 4, name: "May", value: 1320, fill: "#F2F4F9", currentMonth: false },
    { id: 5, name: "Jun", value: 360, fill: "#F2F4F9", currentMonth: false },
    { id: 6, name: "Jul", value: 220, fill: "#F2F4F9", currentMonth: false },
    { id: 7, name: "Aug", value: 270, fill: "#F2F4F9", currentMonth: false },
    { id: 8, name: "Sep", value: 1640, fill: "#F2F4F9", currentMonth: false },
    { id: 9, name: "Oct", value: 1870, fill: "#A9ACD4", currentMonth: true },
    { id: 10, name: "Nov", value: 1850, fill: "#F2F4F9", currentMonth: false },
    { id: 11, name: "Dec", value: 1450, fill: "#F2F4F9", currentMonth: false },
  ],
  topic: "obiective turistice Londra",
  isDummy: true,
};

// default topic details have big values now for testing purpose
// should be changed with the real ones for default
export const defaultTopicDetails = {
  maxValues: {
    maxWords: 5,
    maxImages: 3,
    topicDensity: 40,
    titleOptimization: true,
    topicCoverage: 60,
    headingsOptimization: 6,
  },
  rules: {
    type: "normal",
    topicDensity: 10,
    images: 49,
    wordcount: 41,
    headingsOptimization: 25,
    topicCoverage: 25,
    titleOptimization: 15,
  },
  topicCoverage: [
    {
      key: 0,
      keyword: "londra obiective turistice",
      search_volume: 390,
      estimated_visits: 46.14,
      usedH1: false,
      usedH2: false,
      usedH3: false,
      heading: false,
    },
    {
      key: 11,
      keyword: "care este capitala angliei",
      search_volume: 1000,
      estimated_visits: 27.3,
      usedH1: false,
      usedH2: true,
      usedH3: true,
      heading: true,
    },
    {
      key: 1,
      keyword: "de vizitat in londra",
      search_volume: 170,
      estimated_visits: 20.11,
      usedH1: false,
      usedH2: false,
      usedH3: false,
      heading: false,
    },
    {
      key: 13,
      keyword: "anglia capitala",
      search_volume: 210,
      estimated_visits: 3.76,
      usedH1: false,
      usedH2: true,
      usedH3: true,
      heading: true,
    },
    {
      key: 8,
      keyword: "capitala londrei",
      search_volume: 170,
      estimated_visits: 2.47,
      usedH1: false,
      usedH2: false,
      usedH3: false,
      heading: false,
    },
    {
      key: 0,
      keyword: "de vizitat in londra forum",
      search_volume: 20,
      estimated_visits: 2.37,
      usedH1: false,
      usedH2: false,
      usedH3: false,
      heading: false,
    },
    {
      key: 1,
      keyword: "ghid turistic londra",
      search_volume: 20,
      estimated_visits: 2.37,
      usedH1: false,
      usedH2: true,
      usedH3: true,
      heading: true,
    },
    {
      key: 2,
      keyword: "ce sa faci in londra",
      search_volume: 30,
      estimated_visits: 2.09,
      usedH1: false,
      usedH2: false,
      usedH3: false,
      heading: false,
    },
    {
      key: 0,
      keyword: "referat despre obiectivele turistice din londra in engleza",
      search_volume: 30,
      estimated_visits: 2.09,
      usedH1: false,
      usedH2: false,
      usedH3: false,
      heading: false,
    },
    {
      key: 0,
      keyword: "westminster abbey pret intrare",
      search_volume: 10,
      estimated_visits: 1.18,
      usedH1: false,
      usedH2: false,
      usedH3: false,
      heading: false,
    },
    {
      key: 6,
      keyword: "am fost acolo londra",
      search_volume: 50,
      estimated_visits: 1.16,
      usedH1: false,
      usedH2: false,
      usedH3: false,
      heading: false,
    },
    {
      key: 10,
      keyword: "harta turistica londra",
      search_volume: 40,
      estimated_visits: 1.09,
      usedH1: false,
      usedH2: false,
      usedH3: false,
      heading: false,
    },
    {
      key: 14,
      keyword: "informatii despre londra",
      search_volume: 90,
      estimated_visits: 0.95,
      usedH1: false,
      usedH2: false,
      usedH3: false,
      heading: false,
    },
    {
      key: 3,
      keyword: "monumente din londra",
      search_volume: 30,
      estimated_visits: 0.86,
      usedH1: false,
      usedH2: true,
      usedH3: true,
      heading: true,
    },
    {
      key: 8,
      keyword: "obiective turistice londra preturi 2019",
      search_volume: 30,
      estimated_visits: 0.82,
      usedH1: false,
      usedH2: false,
      usedH3: false,
      heading: false,
    },
    {
      key: 12,
      keyword: "zone londra",
      search_volume: 40,
      estimated_visits: 0.72,
      usedH1: false,
      usedH2: true,
      usedH3: true,
      heading: true,
    },
  ],
};

export const defaultTopicInfo = {
    keywords: 0,
    clicks: 0,
    search_volume: 0,
    search_volume_yoy: 0,
    visibility: 0,
    visibility_yoy: 0,
    opportunity: 0,
    loaded: false
};