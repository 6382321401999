import React from 'react';
import AppSwitcher from "../../../Shared/AppSwitcher";
import {WhiteTooltip} from "../../UITooltips/UITooltip";

const AiCardOptions = ({
    isOutline,
    outlineProgressStatus,
    showPrompt,
    hidePromptInput,
    generateArticle,
    promptType,
    articleCompleted,
    completedHeadings,
    totalHeadings,
    disableStopGenerating,
    stopGenerating,
    setShowUncompiledArticle,
    showUncompiledArticle,
    showUncompiledArticleTooltipText,
    showPromptInput,
    disableGeneratingArticle
}) => {
    const generateButtonTooltip = () => {
        return disableGeneratingArticle ?
            "To generate an article, please enter a title, add headings, and include relevant keywords." :
            ""
    }

    const generateHandler = () => disableGeneratingArticle ? false : generateArticle();

    if(articleCompleted === false) {
        return (
            <div className="ai-card-buttons">
                <div
                    className={`ai-card-button ai-card-button--stop ${disableStopGenerating ? 'disabled' : ''}`}
                    onClick={stopGenerating}>
                    Stop generating
                </div>
            </div>
        );
    } else if (isOutline) {
        if (outlineProgressStatus !== null) {
            return (
                <div className="ai-card-options">
                    <div className="ai-card-buttons">
                        {showPrompt ? (
                            <>
                                <div className="ai-card-button ai-card-button--reset" onClick={hidePromptInput}>
                                    Go back
                                </div>
                                <WhiteTooltip
                                    placement="bottom"
                                    title={generateButtonTooltip()}
                                >
                                    <div className={`ai-card-button ai-card-button--show-prompt ${disableGeneratingArticle ? 'disabled' : ''}`}
                                         onClick={generateHandler}
                                    >
                                        {promptType === 'article' ? 'Generate' : 'Regenerate'}
                                    </div>
                                </WhiteTooltip>
                            </>
                        ) : (
                            <>
                                <div className="ai-card-button ai-card-button--reset" onClick={() => showPromptInput('outline')}>
                                    Regenerate Outline
                                </div>
                                <div className="ai-card-button ai-card-button--show-prompt" onClick={() => showPromptInput('article')}>
                                    Generate Article
                                </div>
                            </>
                        )}
                    </div>
                </div>
            );
        }
    } 
    // else if (articleCompleted === true) {
    //     // if (articleCompleted === false && completedHeadings != totalHeadings) {
    //     //     return (
    //     //         <div className="ai-card-buttons">
    //     //             <div
    //     //                 className={`ai-card-button ai-card-button--stop ${disableStopGenerating ? 'disabled' : ''}`}
    //     //                 onClick={stopGenerating}>
    //     //                 Stop generating
    //     //             </div>
    //     //         </div>
    //     //     );
    //     // } else if (articleCompleted === true) {
    //         return (
    //             <div className="switcher-wrapper">
    //                 <AppSwitcher
    //                     disabled={false}
    //                     canEdit={true}
    //                     readOnlyTooltip={'Show or hide article'}
    //                     updateState={setShowUncompiledArticle}
    //                     isChecked={showUncompiledArticle}
    //                     label={'Show uncompiled article'}
    //                     tooltip={showUncompiledArticleTooltipText}
    //                 />
    //             </div>
    //         );
    //     // }
    // }
};

export default AiCardOptions;