/**
 * Created this file as a quick fix capturing the route change.
 * Didn't want to invest more time, right now, into moving this in App.js or even index.js
 */

import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
const MessageRouteChange = () => {

const location = useLocation();

useEffect(() => {
    // execute on location change
    const newURL = window.location.href;
    // Send the new URL to the parent frame
    window.parent.postMessage({ url: newURL }, '*');
}, [location]);
    return (
        <div className="test">
        </div>
    );
};

export default MessageRouteChange;
