import React, { useEffect, useState } from 'react';

import './ArticleKeywordsTable.css'
import ArticleKeywordsTableHeader from "./ArticleKeywordsTableHeader";
import ArticleKeywordsTableGroup from "./ArticleKeywordsTableGroup";
import {useDispatch, useSelector} from "react-redux";
import {
    getTopicCoveragePerc,
    getTopicCoverageData,
    lowTopicsFlag, setHighTopicCoverage, setLowTopicCoverage,
    setTopicCoverageData, updateCurrentTopicCoveragePoints, updateMaxTopicCoveragePoints,
    getHeadingCoveragePerc
} from "../../reducers/topicCoverage";
import ArticleKeywordsTableGroupLoading from "./ArticleKeywordsTableGroupLoading";
import {
    getH1Optimization,
    getTitleCoverage,
    getTitleOptimization,
    getTitleCoveragePerc,
} from "../../reducers/titleOptimization";
import {
    checkHeadingsOptimization,
    getHeadingCoverage,
    setHeadingCoveragePerc,
    // getHeadingCoveragePerc,
    setHeadingsForOptimization
} from "../../reducers/headingsOptimization";
import { getEditorInstance, getParsedData } from "../../reducers/textEditor";
import {getApplicationMode} from "../../reducers/applicationMode";
import {useParams} from "react-router-dom";
import {filterUsedKeywords, markUsedKeywords} from "../tagsHelpers";
import {debounce} from "lodash";
import {TYPE_ARTICLE, TYPE_DESCRIPTION, TYPE_NATIVE} from "../../common/consts";
import {
    getArticleEditorLoadingState,
    getOutlineEditorLoadingState, getShowUncompiled,
    getStopWords,
    saveArticle
} from "../../reducers/articles";
import {keywordRemovedByUser, keywordSetAsCompleted} from "../../services/topic";
import {formatText} from "../../utils/topicCoverage";
import { calculateAndDispatchTitleCoverage,
    // calculateAndDispatchHeadingCoverage,
    calculateHeadingAndTitleCoverage } from './utils';
import {
    getActiveCoverageDataHeading,
    getActiveCoverageDataTitle, getActiveCoverageDataTopic,
    getCoverageDataHeading,
    getCoverageDataTitle,
    getCoverageDataTopic
} from "../../reducers/keywordsCoverage";
import {WhiteTooltip} from "../UITooltips/UITooltip";

const ArticleKeywordsTable = ({ mode, writerMode }) => {
    const dispatch = useDispatch();

    const showUncompiledArticle = useSelector(getShowUncompiled);
    const processedKeywords = useSelector(getTopicCoverageData);

    // const coverageDataTitle = useSelector(getCoverageDataTitle);
    // const coverageDataTopic = useSelector(getCoverageDataTopic);
    // const coverageDataHeading = useSelector(getCoverageDataHeading);

    const coverageDataTitle = useSelector(getActiveCoverageDataTitle);
    const coverageDataTopic = useSelector(getActiveCoverageDataTopic);
    const coverageDataHeading = useSelector(getActiveCoverageDataHeading);

    // const returnTitleCoverage = () => writerMode === 'outline' ? coverageDataTitle.outline : showUncompiledArticle ? coverageDataTitle.uncompiled : coverageDataTitle.article;
    // const returnTopicCoverage = () => writerMode === 'outline' ? coverageDataTopic.outline : showUncompiledArticle ? coverageDataTopic.uncompiled : coverageDataTopic.article;
    // const returnHeadingCoverage = () => writerMode === 'outline' ? coverageDataHeading.outline : showUncompiledArticle ? coverageDataHeading.uncompiled : coverageDataHeading.article;

    const titleCoverage = useSelector(getTitleCoverage);
    const headingCoverage = useSelector(getHeadingCoverage);
    const parsedData = useSelector(getParsedData);
    const topicCoveragePercentage = useSelector(getTopicCoveragePerc);
    const titleCoveragePercentage = useSelector(getTitleCoveragePerc);
    const headingCoveragePercentage = useSelector(getHeadingCoveragePerc);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [allKeywords, setAllKeywords] = useState(processedKeywords);

    const isTitleOptimized = useSelector(getTitleOptimization);
    const isH1TagOptimized = useSelector(getH1Optimization);
    const showLowTopics = useSelector(lowTopicsFlag);
    const appMode = useSelector(getApplicationMode);
    const articleLoading = useSelector(getArticleEditorLoadingState);
    const outlineLoading = useSelector(getOutlineEditorLoadingState);
    const stopWords = useSelector(getStopWords);

    const loadingContent = () => writerMode === 'outline' ? outlineLoading : articleLoading;

    const [activeTags, setActiveTags] = useState(allKeywords);
    const [titleOptimization, setTitleOptimization] = useState(false);
    // `h1TagOptimization` is used only in category mode
    const [h1TagOptimization, setH1TagOptimization] = useState(false);
    const { id: articleId } = useParams();

    const filterCoverage = (coverageArray, parsedData, field, useIn) => {
        if (coverageArray && coverageArray !== 'undefined') {
            const filteredCoverage = coverageArray
                .filter(item => item?.name ? item.name.includes(searchKeyword) : item.length && item.includes(searchKeyword))
                .map(item => {

                    return {
                        keyword: item?.name || item,
                        search_volume: item?.search_volume,
                        broad: false,
                    }
                })
                .sort((a, b) => b.search_volume - a.search_volume)

            return filteredCoverage;
        } else {
            return [];
        }
    }

    const filteredTitles = () => filterCoverage(titleCoverage, parsedData, 'headings1', 'use_in_h1');
    // const filteredHeadings = () => filterCoverage(headingCoverage, parsedData, 'headings', 'use_in_title');

    const filterKeywords = (filter) => {
        setSearchKeyword(filter);
    };

    useEffect(() => {
        const filteredKeywords = processedKeywords.filter(({ keyword }) =>
            keyword?.includes(searchKeyword)
        );
        setAllKeywords(filteredKeywords);

        // calculateAndDispatchHeadingCoverage(filteredHeadings(), parsedData, dispatch, stopWords);
        // calculateAndDispatchTitleCoverage(filteredTitles(), parsedData, dispatch, stopWords);

    }, [processedKeywords, searchKeyword]);



    useEffect(() => {
        // const usedKeywords = markUsedKeywords(
        //     allKeywords,
        //     titleOptimization,
        //     h1TagOptimization
        // );
        //
        // // filter used keywords  and move them at the bottom of the list
        // const nonBroadKeywords = allKeywords.filter((keyword) => !keyword.broad)
        // const filtered = filterUsedKeywords(usedKeywords, loadingContent());
        const filtered = filterUsedKeywords(allKeywords, loadingContent());

        // if(loadingContent()) {
        //     filtered.forEach(kw => {
        //         kw.usedComplete = false;
        //     })
        // }

        setActiveTags(filtered);

        // const filteredUsed = filtered.filter(kw => kw.usedComplete);
        // we only calculate topic coverage points for the high keywords
        // calculateCurrentTopicCoverage(filteredUsed);
        // calculateMaxTopicCoveragePoints(nonBroadKeywords);

    }, [allKeywords, titleOptimization, articleLoading, outlineLoading]);


    // useEffect(() => setTitleOptimization(isTitleOptimized), [isTitleOptimized]);
    // useEffect(() => setH1TagOptimization(isH1TagOptimized), [isH1TagOptimized]);


    // const calculateCurrentTopicCoverage = debounce((totalTags) => {
    //     dispatch(updateCurrentTopicCoveragePoints(totalTags));
    // }, 500);
    //
    const calculateMaxTopicCoveragePoints = debounce((currentTags) => {
        dispatch(updateMaxTopicCoveragePoints(currentTags));
    }, 500);

    /*end new*/

    useEffect(() => {
        // calculateHeadingAndTitleCoverage(parsedData, titleCoverage, headingCoverage, dispatch, stopWords);
    }, [parsedData]);

    return (
        <div className="article-keywords-table">
            <div className="article-keywords-table__title">
                <WhiteTooltip placement="bottom-start" title={"Keyword recommendations to maximize ranking potential for top-searched keywords within the topic. The SEO score primarily reflects the inclusion of these keywords in your outline and article."}>
                    <span>SEO brief</span>
                </WhiteTooltip>
            </div>
            <ArticleKeywordsTableHeader
                onFilter={filterKeywords}
                loadingTopics={(!activeTags.length && !searchKeyword) || loadingContent()}
            />
            {(((!activeTags.length && searchKeyword == '' ) || loadingContent()) && (
                <ArticleKeywordsTableGroupLoading />

            )) || (
                <>
                    <ArticleKeywordsTableGroup
                        keywords={filteredTitles()}
                        keywordsCoverage={coverageDataTitle.keywords}
                        percentage={loadingContent() ? 0 : coverageDataTitle?.percent}
                        groupName={'Title Optimization'}
                        skipComplexLogic={true}
                        filteredKeywords={!!searchKeyword}
                        isTitle={true}
                    />
                    <ArticleKeywordsTableGroup
                        keywords={activeTags}
                        keywordsCoverage={coverageDataTopic?.keywords}
                        secondKeywordsCoverage={coverageDataHeading?.keywords}
                        percentage={loadingContent() ? 0 : coverageDataTopic?.percent}
                        secondPercentage={loadingContent() ? 0 : coverageDataHeading?.percent}
                        groupName={'Headings & Coverage'}
                        filteredKeywords={!!searchKeyword}
                        doublePresencePercentage={true}
                    />
                </>
            )}
        </div>
    );
}

export default ArticleKeywordsTable;
