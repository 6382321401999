import React, {useEffect, useRef, useState} from 'react';
import './DefaultPromptSidebarHeader.css'
import {CrossIcon} from "../../UI_utils/SVGIcons";

const DefaultPromptSidebarHeader = ({ close = () => void(0)}) => {

    return (
        <div className="default-prompt-sidebar-header">
            <div className="default-prompt-sidebar-title__wrapper">
                <div className="default-prompt-sidebar-title">Writer Settings</div>
                <div className="default-prompt-sidebar-close" onClick={close}><CrossIcon /></div>
            </div>
        </div>
    );
};

export default DefaultPromptSidebarHeader