import React from 'react';
import './NewArticleBox.css'
import {useDispatch, useSelector} from "react-redux";
import {  useLocation } from "react-router-dom";
import ModalHeader from "./ModalHeader";
import ModalContentAudit from "./ModalContentAudit";
import ModalHowItWorks from "./ModalHowItWorks";
import SerpQuestions from './SerpQuestions';
import {
    getMissingPagesCount, getPagesManyKeywordsCount,
    getSuboptimalContentCount,
    getWrongIntentKeywordCount,
    getLowRankPageCount
} from "../../reducers/contentAuditIssues";
import {useState} from 'react'
import PreviousArticle from './PreviousArticle';
import { dashboardData } from '../../reducers/dashboard'
import {getIsContentWriterAuthor, getUserCanEdit} from "../../reducers/user";
import ModalStartArticle from "./ModalStartArticle";

const NewArticleBox = ({noArticles, close}) => {
    const [showImportMessage, setImportMessageDisplay] = useState(false)
    const missingPagesCount = useSelector(getMissingPagesCount);
    const wrongIntentKeywordCount = useSelector(getWrongIntentKeywordCount)
    // const suboptimalContentCount = useSelector(getSuboptimalContentCount)
    const pagesManyKeywordsCount = useSelector(getPagesManyKeywordsCount);
    const lowRankPageCount = useSelector(getLowRankPageCount)
    const dashboard = useSelector(dashboardData)
    const isContentWriterAuthor = useSelector(getIsContentWriterAuthor);
    const userCanEdit = useSelector(getUserCanEdit);
    let location = useLocation();
    const showSerpQuestionsSection = () => {
        return (missingPagesCount + wrongIntentKeywordCount + pagesManyKeywordsCount + lowRankPageCount) < 50
    }
    const allContentIssuesLoaded = (missingPagesCount !== null) && (wrongIntentKeywordCount !== null) && (pagesManyKeywordsCount !== null) && (lowRankPageCount !== null)
    return (
        <div className="new-article-modal">
            <ModalHeader noArticles={noArticles} close={close} />
            <ModalContentAudit showImportMessage={showImportMessage} setImportMessageDisplay={setImportMessageDisplay}  />
            <ModalStartArticle userCanEdit={userCanEdit} />
            {showSerpQuestionsSection() && (
                <SerpQuestions
                    allContentIssuesLoaded={allContentIssuesLoaded}
                    showImportMessage={showImportMessage}
                    setImportMessageDisplay={setImportMessageDisplay}
                />
            )}
            {/*{((dashboard && dashboard.length  && !dashboard[0].empty)  && location.pathname === '/dashboard') &&*/}
            {/*    <PreviousArticle dashboard={dashboard}/>*/}
            {/*}*/}
            {!isContentWriterAuthor && noArticles && (
                <ModalHowItWorks />
            )}
        </div>
    );
};

export default NewArticleBox