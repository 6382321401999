import { createSlice } from "@reduxjs/toolkit";

export const categoryTextEditorSlice = createSlice({
  name: "categorytexteditor",
  initialState: {
    categoryEditorData: "",
    metaTitle: "",
    metaDescription: "",
    h1Tag: "",
    parsedMetaData: {
      headings1: "",
      paragraphs: "",
      headings: "",
    },
    isDataLoaded: false,
  },

  reducers: {
    setCategoryEditorData: (state, { payload }) => {
      return {
        ...state,
        categoryEditorData: payload,
      };
    },
    setMetaTitle: (state, { payload }) => {
      return {
        ...state,
        metaTitle: payload,
      };
    },

    setMetaDescription: (state, { payload }) => {
      return {
        ...state,
        metaDescription: payload,
      };
    },

    setH1Tag: (state, { payload }) => {
      return {
        ...state,
        h1Tag: payload,
      };
    },

    setDescriptionDataLoaded: (state, { payload }) => {
      return {
        ...state,
        isDataLoaded: payload,
      };
    },

    setParsedMetaData: (state, { payload }) => {
      return {
        ...state,
        parsedMetaData: payload,
      };
    },
  },
});

// actions
export const { setMetaTitle } = categoryTextEditorSlice.actions;
export const { setDescriptionDataLoaded } = categoryTextEditorSlice.actions;
export const { setMetaDescription } = categoryTextEditorSlice.actions;
export const { setH1Tag } = categoryTextEditorSlice.actions;
export const { setParsedMetaData } = categoryTextEditorSlice.actions;
export const { setCategoryEditorData } = categoryTextEditorSlice.actions;

export const updateMetaTitle = (value) => (dispatch) => {
  dispatch(setMetaTitle(value));
  dispatch(covertMetaData());
};

export const updateMetaDescription = (value) => (dispatch) => {
  dispatch(setMetaDescription(value));
  dispatch(covertMetaData());
};

export const updateH1Tag = (value) => (dispatch) => {
  dispatch(setH1Tag(value));
  dispatch(covertMetaData());
};

const covertMetaData = () => (dispatch, getState) => {
  const { metaTitle, metaDescription, h1Tag } = getState().categorytexteditor;
  const metaData = {
    headings1: h1Tag,
    paragraphs: metaDescription,
    headings: metaTitle,
  };

  dispatch(setParsedMetaData(metaData));
};
// selectors
export const getMetaTitle = ({ categorytexteditor: { metaTitle } }) =>
  metaTitle;

export const getMetaDesc = ({ categorytexteditor: { metaDescription } }) =>
  metaDescription;

export const getH1Tag = ({ categorytexteditor: { h1Tag } }) => h1Tag;
export const getIsDataLoaded = ({ categorytexteditor: { isDataLoaded } }) =>
  isDataLoaded;

export const getCategoryEditorData = ({
  categorytexteditor: { categoryEditorData },
}) => categoryEditorData;

export default categoryTextEditorSlice.reducer;
