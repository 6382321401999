import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import "./CustomSelect.css";

const CustomSelect = ({ options, handleChange, label, isMulti, defaultValue, inputId, isSearchable }) => {
  const animatedComponents = makeAnimated();

  return (
    <div className="custom-select">
      <label className="label" htmlFor={inputId}>{label}</label>
      <Select
        id={inputId}
        name={inputId}
        className="react-select-container"
        classNamePrefix="react-select"
        closeMenuOnSelect={true}
        components={animatedComponents}
        isMulti={isMulti}
        options={options}
        onChange={handleChange}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        // placeholder='Select the industries you have niche expertise for'
      />
    </div>
  );
};

export default CustomSelect;
