import React, { useEffect, useState } from "react";
import "./AppLoadingScreen.css"; // Import your CSS file

const AppLoadingScreen = ({ loadingText = 'Loading...', transitionText = '', transitionDelay = 3 }) => {
    const logoSvg = '<svg width="76" height="44" viewBox="0 0 76 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.125 44.5L28.625 23.6641L43.625 37.5316L75.5 4.38829L70.2125 -0.5L43.625 27.131L28.625 13.2635L0.5 39.2997L6.125 44.5Z" fill="#171717"/></svg>';

    const [switchText, setSwitchText] = useState(false);

    useEffect(() => {
        if (transitionText) {
            const timeout = setTimeout(() => {
                setSwitchText(true);
            }, transitionDelay * 1000);
            return () => clearTimeout(timeout);
        }
    }, [transitionText, transitionDelay]);

    return (
        <div className="loading-screen position-fixed d-flex align-items-center justify-content-center">
            <div className="logo-container-wrapper d-flex flex-column align-items-center">
                <div className="logo-container d-flex align-items-center justify-content-center">
                    <div className="logo-wrapper">
                        <div className="logo-cover"></div>
                        <div className="logo" dangerouslySetInnerHTML={{ __html: logoSvg }}></div>
                    </div>
                </div>
                <div className={`text-wrapper ${switchText ? 'show-transition-text' : ''}`}>
                    <div className="loading-text">{loadingText}</div>
                    {transitionText && (
                        <div className="transition-text">{transitionText}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AppLoadingScreen;
