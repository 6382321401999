import {useSelector} from "react-redux";
import {getDraftConfigOptions, getUserData, showDraftConfigOptions} from "../../../reducers/user";
import CustomSelect from "../../../Shared/CustomSelect";
import CustomCheckbox from "../../../Shared/CustomCheckbox";

const AiCardPrompt = ({
    promptType,
    text,
    handleTextareaChange,
    textareaRef,
    aiMode,
    setAiMode,
    selectedTestPrompt,
    setSelectedTestPrompt,
    selectedMarketSource,
    setSelectedMarketSource,
    // contentWriterSourceUserAccess,
    isOutline
}) => {
    const userData = useSelector(getUserData);
    const hasDraftConfigOptions = useSelector(showDraftConfigOptions);
    const draftConfigOptions = useSelector(getDraftConfigOptions);

    const contentWriterSourceUserAccess = () => {
        const allowedEmails = [
            'tiberiu@seomonitor.com',
            'busayo@seomonitor.com',
            'claudiu@seomonitor.com',
            'mihai@seomonitor.com',
            'dragos.stanciu@seomonitor.ro',
            'laurentiu@seomonitor.com',
        ];
        return userData ? allowedEmails.includes(userData.email) : false;
    };

    const aiSource = [
        { label: 'OpenAI GPT-4', value: 'openai' },
        { label: 'OpenAI GPT-4o (faster)', value: 'openai4o' },
        { label: 'Claude', value: 'claude' },
        { label: 'Gemini', value: 'gemini' },
    ];
    const marketSource = [{ label: 'True', value: true }, { label: 'False', value: false }]
    const testPrompt = [
        { label: 'Default', vlaue: false },
        { label: 'Alternative', value: true },
    ];

    return (
        <div className="ai-card-prompt__wrapper">
            <div className="ai-card-prompt">
                <label htmlFor="prompt">Prompt</label>
                <textarea
                    name="prompt"
                    id="prompt"
                    placeholder={
                        promptType === 'article' ? `E.g. Mention [Brand] or include link to [URL] when appropriate.` : `E.g. 'Include a FAQ section' or 'Focus on benefits'`
                    }
                    ref={textareaRef}
                    value={text}
                    onChange={handleTextareaChange}
                    style={{ overflowY: 'hidden' }}
                />
            </div>

            {(hasDraftConfigOptions && isOutline) && (
                <div className="writer-source">
                    {draftConfigOptions?.aiModeSelector && (
                        <CustomSelect
                            inputId={'aiModeSelector'}
                            defaultValue={aiMode}
                            options={aiSource}
                            handleChange={setAiMode}
                            label="Writer model"
                        />
                    )}
                    {draftConfigOptions?.testPromptSelector && (
                        <CustomSelect
                            inputId={'testPromptSelector'}
                            defaultValue={selectedTestPrompt}
                            options={testPrompt}
                            handleChange={setSelectedTestPrompt}
                            label="Test prompt"
                        />
                    )}
                    {draftConfigOptions?.ukCheckbox && (
                        <CustomCheckbox
                            inputId={'ukCheckbox'}
                            label="Get data from UK"
                            defaultValue={selectedMarketSource}
                            handleChange={(value) => setSelectedMarketSource(value)}
                            tooltipText={'<span>Gathers data for the topic in UK<br>instead of campaign’s target<br>location. Useful for products<br>and services that are new or<br>lack articles about them in<br>target language.</span>'}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default AiCardPrompt;
