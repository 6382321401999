import React from 'react';
import './SerpQuestions.css'
import {ChevronDown, ChevronUp, SerpBackgroundIcon, SpinnerIcon} from '../../UI_utils/SVGIcons'
import LoadingBlock from "../LoadingBlock/LoadingBlock";
import {getFaqCount} from "../../reducers/contentAuditIssues";
import {useState} from 'react'
import {interfaceApiRequest} from '../../utils/dataToInterface.js';
import classNames from 'classnames'
import {setShowGenericErrorModal, setGenericErrorText,setGenericHandler} from '../../reducers/apiErrors.js'
import { useDispatch , useSelector} from "react-redux";
import {getImportFaqData , setImportFaqData } from '../../reducers//contentAuditIssues.js'
import {shortenNumbers} from "../../utils/helpers";
const SerpQuestions = ({showImportMessage,setImportMessageDisplay, allContentIssuesLoaded}) => {
    const [importLoading, setImportLoading] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const dispatch = useDispatch();
    const importFaqData = useSelector(getImportFaqData)

    const setImportRelatedFaq = () => {
        setImportLoading(true)
        interfaceApiRequest('importFaqRelated')
        .then((response) => {
            setImportLoading(false);
            setImportMessageDisplay(true)
            dispatch(setImportFaqData(response));;
            }).catch((error) => {
                if(error.message){
                    dispatch(setGenericErrorText({
                        title:'Error',
                        description:error.message,
                        buttonText:'Close'
                    }))
                    const closeModalError = () => {
                        dispatch(setShowGenericErrorModal(false))
                    };
                    dispatch(setGenericHandler(closeModalError));
                    dispatch(setShowGenericErrorModal(true));
                }
                setImportLoading(true)
            })
    }
    const faqCount = useSelector(getFaqCount)

    const toggleAccordion = () => setExpanded(!expanded);

    const generalLoading = () => {
        return (faqCount == null || faqCount == 'undefined') || !allContentIssuesLoaded
    }
    return (
        <>
            {!generalLoading() && !showImportMessage && !importFaqData && (
                <hr />
            )}
            {!generalLoading() && (
                <div className="serp-container">
                    {showImportMessage || importFaqData ? (
                        <p className="serp-heading">
                            {shortenNumbers(faqCount)} SERP questions are processed into ‘Low ranking pages <br /> on informational keywords’ category. <br />
                            Start writing articles from there.
                        </p>
                    ) : (
                        <>
                            <div className="serp-heading__wrapper" onClick={toggleAccordion}>
                                <p className="serp-heading">
                                    Found {shortenNumbers(faqCount)} additional topic opportunities from the SERP questions of your tracked keywords
                                </p>
                                <span className="accordion-icon">
                                {expanded ? <ChevronUp /> : <ChevronDown />}
                            </span>
                            </div>
                            {expanded && (
                                <div className="serp-body">
                                    <p className="serp-heading-description">
                                        These questions, extracted from search results, are ideal for SEO-optimized articles<br/>using Content Writer. Add them to your keyword strategy to assess opportunities and<br/>select top article topics.
                                    </p>
                                    <button
                                        disabled={importLoading}
                                        className={classNames({ "serp-trigger-button": true, "check-progress": importLoading })}
                                        onClick={setImportRelatedFaq}
                                    >
                                        Add {shortenNumbers(faqCount)} SERP questions as keywords {importLoading && <SpinnerIcon />}
                                    </button>
                                    <p className="serp-footer-text">
                                        Keywords to be added for daily tracking.<br />
                                        After analysis, archive low-priority ones to optimize resources.
                                    </p>
                                    <svg className="background-icon" xmlns="http://www.w3.org/2000/svg" width="97" height="120" viewBox="0 0 97 120" fill="none">
                                        <path
                                            d="M60 0C26.8548 0 0 27.0968 0 60C0 93.1452 26.8548 120 60 120C92.9032 120 120 93.1452 120 60C120 27.0968 92.9032 0 60 0ZM60 108.387C33.1452 108.387 11.6129 86.8548 11.6129 60C11.6129 33.3871 33.1452 11.6129 60 11.6129C86.6129 11.6129 108.387 33.3871 108.387 60C108.387 86.8548 86.6129 108.387 60 108.387ZM85.8871 46.6935C85.8871 34.5968 73.0645 25.1613 61.2097 25.1613C49.8387 25.1613 42.5806 30 37.0161 38.4677C36.0484 39.6774 36.2903 41.371 37.5 42.3387L44.2742 47.4194C45.4839 48.3871 47.4194 48.1452 48.3871 46.9355C52.0161 42.3387 54.6774 39.6774 60.2419 39.6774C64.5968 39.6774 69.9193 42.3387 69.9193 46.6935C69.9193 49.8387 67.2581 51.2903 62.9032 53.7097C58.0645 56.6129 51.5323 60 51.5323 68.7097V70.6452C51.5323 72.3387 52.7419 73.5484 54.4355 73.5484H65.3226C67.0161 73.5484 68.2258 72.3387 68.2258 70.6452V69.1936C68.2258 63.1452 85.8871 62.9032 85.8871 46.6935ZM70.1613 87.0968C70.1613 81.5323 65.5645 76.9355 60 76.9355C54.1935 76.9355 49.8387 81.5323 49.8387 87.0968C49.8387 92.9032 54.1935 97.2581 60 97.2581C65.5645 97.2581 70.1613 92.9032 70.1613 87.0968Z"
                                            fill="#171717"
                                        />
                                    </svg>
                                </div>

                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default SerpQuestions 