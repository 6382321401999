import { createSlice } from "@reduxjs/toolkit";
import { countries} from "../common/consts"

export const articlesLanguageSlice = createSlice({
  name: "articlesLanguage",
  initialState: {
   selectedLanguage: countries[0],
  },
  reducers: {
    setSelectedLanguage: (state, { payload }) => {
      return {
        ...state,
        selectedLanguage: payload,
      };
    },
  },
});

// actions
export const { setSelectedLanguage } = articlesLanguageSlice.actions;

// selectors
export const getSelectedLanguage = ({ articlesLanguage }) => articlesLanguage.selectedLanguage;

export default articlesLanguageSlice.reducer;
