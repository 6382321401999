import { formatDateForStore } from "../utils/dateUtility";

export const transformAllData = (data) => {
  const transformedData = data.rows.map((row) => {
    const obj = {
      ...row,
      deadline: row.deadline ? formatDateForStore(row.deadline) : null,
      created: row.created ? formatDateForStore(row.created) : null,
      selected: false,
    };

    return obj;
  });

  return {
    rows: transformedData,
    total: data.total,
  };
};
