import { useLocation } from "react-router-dom";
import {
  HOME_PATH,
  ESHOP_PATH,
  NATIVE_PATH,
  TYPE_ARTICLE,
  TYPE_NATIVE,
  TYPE_DESCRIPTION,
} from "../common/consts";

export const useApplicationType = () => {
  const location = useLocation();

  if (location.pathname.includes(HOME_PATH)) {
    return TYPE_ARTICLE;
  } else if (location.pathname.includes(NATIVE_PATH)) {
    return TYPE_NATIVE;
  } else if (location.pathname.includes(ESHOP_PATH)) {
    return TYPE_DESCRIPTION;
  } else {
    return -1;
  }
};
