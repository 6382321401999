import { createSlice } from "@reduxjs/toolkit";
import { currentTopicDensity } from "../utils/topicDensity";

export const topicDensitySlice = createSlice({
  name: "topicdensity",
  initialState: {
    topics: null,
    maxTopicDensity: 0,
    topicDensity: 0,
  },
  reducers: {
    setTopicDensity: (state, { payload }) => {
      return {
        ...state,
        topics: payload,
      };
    },

    setMaxTopicDensity: (state, { payload }) => {
      return {
        ...state,
        maxTopicDensity: payload,
      };
    },

    setCurrentDensity: (state, { payload }) => {
      return {
        ...state,
        topicDensity: payload,
      };
    },
  },
});

// actions
export const { setTopicDensity } = topicDensitySlice.actions;
export const { setMaxTopicDensity } = topicDensitySlice.actions;
export const { setCurrentDensity } = topicDensitySlice.actions;

// this action is dispatch in TextEditor component, onChange
export const checkTopicDensity = () => (dispatch, getState) => {
  const { topics } = getState().topicdensity;
  const { activeTopicCoverage } = getState().topiccoverage;
  const density = currentTopicDensity(topics, activeTopicCoverage);
  dispatch(setCurrentDensity(density || 0));
};

// selectors

export const getTopicDensity = ({
  titleoptimization: { optimizedTitle },
  topicdensity: { topicDensity },
  // if title is optimised, increase topic density with one point
}) => (optimizedTitle ? topicDensity + 1 : topicDensity);
// export const getTopicDensity = ({ topicdensity: { topicDensity } }) =>
//   topicDensity;

export const getMaxTopicDensity = ({ topicdensity: { maxTopicDensity } }) =>
  maxTopicDensity;

export const getTopicDensityPerc = ({
  topicdensity: { maxTopicDensity, topicDensity },
}) => (topicDensity * 100) / maxTopicDensity || 0;

export default topicDensitySlice.reducer;
