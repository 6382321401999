import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import "./SkeletonHeadCompetition.css";

const SkeletonHeadCompetition = ({ isArticle }) => {
  return (
    <SkeletonTheme color="#f4f4f3" highlightColor="#f4f4f5">
      <div className="skeleton-head-competition">
        <div className="skeleton-head-top">
          <Skeleton width={200} height={18} />
          <Skeleton width={250} height={16} />
          {isArticle && (
            <>
              <div className="skeletion-head-results">
                <Skeleton width={50} height={16} />
                <Skeleton width={50} height={16} />
              </div>
              <div className="skeletion-head-details">
                <Skeleton width={70} height={10} />
                <Skeleton width={70} height={10} />
              </div>
            </>
          )}
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonHeadCompetition;
