import { createSlice } from "@reduxjs/toolkit";
import { matchTitle } from "../utils/titleOptimization";
import {countersSlice} from "./counters";

export const titleOptimizationSlice = createSlice({
  name: "titleoptimization",
  initialState: {
    expectedTitle: null,
    titleVariations: [],
    optimizedTitle: false,
    // category only
    optimizedH1Tag: false,
    titleCoverage: [],
    titleCoveragePerc: 0,
  },
  reducers: {
    setExpectedTitle: (state, { payload }) => {
      return {
        ...state,
        expectedTitle: payload,
      };
    },

    setTitleVariations: (state, { payload }) => {
      return {
        ...state,
        titleVariations: payload,
      };
    },

    setTitleOptimization: (state, { payload }) => {
      return {
        ...state,
        optimizedTitle: payload,
      };
    },

    setH1Optimization: (state, { payload }) => {
      return {
        ...state,
        optimizedH1Tag: payload,
      };
    },
    setTopicTitleCoverage: (state, { payload }) => {
      return {
        ...state,
        titleCoverage: payload,
      };
    },
    setTitleCoveragePerc: (state, { payload }) => {
      return {
        ...state,
        titleCoveragePerc: payload,
      };
    },
  },
});

// actions
export const { setExpectedTitle } = titleOptimizationSlice.actions;
export const { setTitleVariations } = titleOptimizationSlice.actions;
export const { setTitleOptimization } = titleOptimizationSlice.actions;
export const { setH1Optimization } = titleOptimizationSlice.actions;
export const { setTopicTitleCoverage } = titleOptimizationSlice.actions;
export const { setTitleCoveragePerc } = titleOptimizationSlice.actions;

// this action is dispatch in TextEditor component, onChange
export const checkTitleOptimization = () => (dispatch, getState) => {
  const { expectedTitle, titleVariations } = getState().titleoptimization;
  const {
    parsedEditor: { headings1 },
  } = getState().texteditor;

  const isTitleOptimized = matchTitle(
    headings1,
    expectedTitle,
    titleVariations
  );
  dispatch(setTitleOptimization(isTitleOptimized));
};
// this action is only for category mode, dispatched in CategoryInputs component
export const checkCategoryTitleOptimization = () => (dispatch, getState) => {
  const { expectedTitle } = getState().titleoptimization;
  const { metaTitle } = getState().categorytexteditor;
  // we don't need title variations here, we will send an empty array
  const isTitleOptimized = matchTitle(metaTitle, expectedTitle, []);
  dispatch(setTitleOptimization(isTitleOptimized));
};

export const checkCategoryH1Optimization = () => (dispatch, getState) => {
  const { expectedTitle } = getState().titleoptimization;
  const { h1Tag } = getState().categorytexteditor;
  // we don't need title variations here, we will send an empty array
  const isH1Optimized = matchTitle(h1Tag, expectedTitle, []);
  dispatch(setH1Optimization(isH1Optimized));
};

export const removeTitleFromList = (title) => (dispatch, getState) => {
  const { titleCoverage } = getState().titleoptimization;
  const updateListOfTitles = titleCoverage.filter(kw => kw.keyword !== title);

  dispatch(setTopicTitleCoverage(updateListOfTitles));
};

// selectors

export const getTitleOptimization = ({ titleoptimization: { optimizedTitle } }) => optimizedTitle;

export const getH1Optimization = ({ titleoptimization: { optimizedH1Tag } }) => optimizedH1Tag;

export const getTitleCoverage = ({ titleoptimization: { titleCoverage } }) => titleCoverage;

export const getTitleCoveragePerc = ({ titleoptimization: { titleCoveragePerc } }) => titleCoveragePerc;


export default titleOptimizationSlice.reducer;
