import {getGlobalState} from "../globals";

export const customHistoryPush = (history, path) => {
    // Get the site_id parameter if it exists
    const siteIdParam = getGlobalState('site_id');

    // Construct the new URL with the site_id parameter if it exists
    const newUrl = siteIdParam ? `${path}?site_id=${siteIdParam}` : path;

    history.push(newUrl);
};

export const setCookie = (cname, cvalue, exdays, extraAttr = '') => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    // let expires = "expires="+d.toUTCString();
    let expires = `expires=${d.toUTCString()}`;
    // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    document.cookie = `${cname}=${cvalue};${expires};path=/;${extraAttr}`
}

export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const deleteCookie = (cname, extraAttr) => {
    let expires = `expires=Thu, 18 Dec 2013 12:00:00 UTC`;
    document.cookie = `${cname}=;${expires};path=/;${extraAttr}`
}


export const shortenNumbers = (num, decimalsNumber = 1, return_zero = true, hideDecimalForUnderThousand = false, showSign= false, decimalUnderTen = false) => {
    let decimalPlaces = decimalsNumber;
    if (isNaN(parseFloat(num))) {

        if (return_zero) return 0;
        if (num === 'N/A' || typeof num === 'number') return '-';
        return num;
    }
    num = parseFloat(num);
    let str,
        suffix = '';
    if (num === 0) {
        if (!return_zero) return '-';
        decimalPlaces = 0;
    }

    num = +num;

    let factor = Math.pow(10, decimalPlaces);

    if (num < 1000 && num > -1000) {
        if (num % 1 == 0 || hideDecimalForUnderThousand) {
            str = num.toFixed(0);
        } else {
            str = num.toFixed(decimalPlaces);
        }
    } else if (num < 1000000 && num > -1000000) {
        // str = Math.floor(num / (1000 / factor)) / factor;
        str = ((num / (1000 / factor)) / factor);
        suffix = 'K';
    } else if (num < 1000000000 && num > -1000000000) {
        // str = Math.floor(num / (1000000 / factor)) / factor;
        str = ((num / (1000000 / factor)) / factor);
        suffix = 'M';
    } else if (num < 1000000000000 && num > -1000000000000) {
        // str = Math.floor(num / (1000000000 / factor)) / factor;
        str = ((num / (1000000000 / factor)) / factor);
        suffix = 'B';
    } else if (num < 1000000000000000 && num > -1000000000000000) {
        // str = Math.floor(num / (1000000000000 / factor)) / factor;
        str = ((num / (1000000000000 / factor)) / factor);
        suffix = 'T';
    }

    if(decimalUnderTen && str < 10 && num > 0){
        decimalPlaces = decimalsNumber
    }else if(decimalUnderTen){
        decimalPlaces = 0;
    }

    str = parseFloat(str).toFixed(decimalPlaces)
    if (parseFloat(str) % 1 == 0) {
        str = parseFloat(str).toFixed(0);
    }
    if (((str > 100 && str < 1000) || (str < -100 && str > -1000)) && num > 999) {
        str = parseFloat(str).toFixed(0);
    }

    if (showSign && num > 0) {
        str = '+' + str;
    }

    return str + suffix;
};
