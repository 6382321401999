export const interfaceApiRequest = (interfaceFuncName, payload = null) => {
    return new Promise((resolve, reject) => {
            window.parent.postMessage({interfaceApi: [interfaceFuncName], isApi: true, payload}, '*');
            const handleApiResponse = (response) => {
                   // response format has to come with a success and error key to differectiate
                if(response.data[interfaceFuncName] && response.data[interfaceFuncName].success){
                    resolve(response.data[interfaceFuncName])
                    window.removeEventListener('message', handleApiResponse);

                }
                else if(response.data[interfaceFuncName] && response.data[interfaceFuncName].error){
                    reject(response.data[interfaceFuncName]);
                    window.removeEventListener('message', handleApiResponse);
                }
            }
            window.addEventListener('message', handleApiResponse);
      
    })
}

export const interfaceRouteNavigation = () => {

} 