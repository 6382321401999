import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { USER_WRITER } from "../common/consts";
import { getCurrentArticle } from "../reducers/articles";
import { activeUser } from "../reducers/user";

export const useEditorModeCheck = () => {
  const user = useSelector(activeUser);
  const currentArticle = useSelector(getCurrentArticle);
  const [userType, setCurrentUserType] = useState(null);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    if (user) {
      setCurrentUserType(user.role[0]);
    }
  }, [user]);

  useEffect(() => {
    if (currentArticle) {
      setArticle(currentArticle);
    }
  }, [currentArticle]);

  const canEdit =
    (article && article.status === "Assigned to writer") ||
    (article && article.status === "Implementing Feedback");

  const isWriter = userType === USER_WRITER;

  const editorReadOnly = isWriter === true && canEdit === false;

  return editorReadOnly;
};
