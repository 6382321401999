import React from "react";

import "./AppOverlay.css";

const AppOverlay = ({open, close = () => void(0)}) => {
    return (
        <div className={`app-overlay ${open ? 'open' : ''}`} onClick={close}></div>
    );
};

export default AppOverlay;
