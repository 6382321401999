import {deleteCookie, getCookie, setCookie} from "./helpers";
const USER_TOKEN = 'writerUserToken'
const COOKIE_DOMAIN_PROD = 'domain=.seomonitor.com'
const COOKIE_DOMAIN_DEV = 'domain=.seomonitor.ro'

export const isUserActive = () => {
  const activeToken = getUserToken();
  const isActive = activeToken ? true : false;
  return isActive;
}

export const getUserToken = () => getCookie(USER_TOKEN);

export const deleteUserToken = () => {
  deleteCookie(USER_TOKEN, COOKIE_DOMAIN_PROD)
  deleteCookie(USER_TOKEN, COOKIE_DOMAIN_DEV)
}

export const setUserToken = (token) =>  {
  setCookie(USER_TOKEN, token, 7, COOKIE_DOMAIN_PROD)
  setCookie(USER_TOKEN, token, 7, COOKIE_DOMAIN_DEV)
}
