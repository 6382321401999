import {
  IMG_NOT_ALLOWED,
  IMG_PARTIALLY_ALLOWED,
  MAX_IMAGES_ALLOWED,
} from "../common/consts";

export const processPastedImages = (html, existingImages) => {
  const elementHtml = document.createElement("div");
  elementHtml.innerHTML = html;

  const images = elementHtml.querySelectorAll("img");
  if (!images.length) {
    return {
      html: elementHtml.innerHTML,
      error: null,
    };
  }
  // if number of existing images is greater than max allowed, remove all images from pasted content
  if (existingImages >= MAX_IMAGES_ALLOWED) {
    for (let i = 0; i < images.length; i++) {
      images[i].remove();
    }
    return {
      html: elementHtml.innerHTML,
      error: IMG_NOT_ALLOWED,
    };
  }

  const diffAllowedImages = MAX_IMAGES_ALLOWED - existingImages;
  const maxAllowedAdded = diffAllowedImages - images.length;
  console.log({ maxAllowedAdded });
  // add only the number of images respecting the limit
  if (maxAllowedAdded < 0) {
    console.log(`only ${diffAllowedImages} images allowed`);
    for (let i = images.length - 1; i >= diffAllowedImages; i--) {
      images[i].remove();
    }
    return {
      html: elementHtml.innerHTML,
      error: IMG_PARTIALLY_ALLOWED,
    };
  }
  return {
    html: elementHtml.innerHTML,
    error: null,
  };
};
