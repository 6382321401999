import React from "react";

import "./FormErrorMessage.css";

const FormErrorMessage = ({ children, success }) => (
  <p className={!success ? "form-error-message" : "form-success-message"}>
    {children}
  </p>
);

export default FormErrorMessage;
