import React, { Component } from 'react';
import TryAgainModal from "../Shared/TryAgainModal";
import AppModal from "../Shared/AppModal";
import {CircleCrossIcon} from "./SVGIcons";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <AppModal
                icon={CircleCrossIcon}
                title={"Oops! <br />Something went wrong"}
                text={'Please try again later or contact support'}
                buttonText={"Try again"}
                buttonAction={() => window.location.reload()}
            />
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
