import React, {useState} from 'react';
import './ModalStartArticle.css'
import {CrossIcon, SpinnerIcon, PlusIcon} from "../../UI_utils/SVGIcons";
import {useDispatch} from "react-redux";
import {BASE_URL} from "../../common/consts";
import {getGlobalState} from "../../globals";
import {interfaceApiRequest} from "../../utils/dataToInterface";
import {WhiteTooltip} from "../UITooltips/UITooltip";

const ModalStartArticle = ({userCanEdit}) => {
    const dispatch = useDispatch();

    const DEFAULT_ERROR = 'Keywords is not searched with informational intent. Try different keyword<br />or use other options.';

    const [newArticleTopic, setNewArticleTopic] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [customError, setCustomError] = useState('');

    const returnError = () => DEFAULT_ERROR //customError || DEFAULT_ERROR

    const handleInputChange = (event) => {
        setError(false);
        setNewArticleTopic(event.target.value);
    }

    const componentStateClassName = () => {
        let dynamicClass = '';

        if(loading) dynamicClass += 'modal-start-article--loading '
        if(!!newArticleTopic) dynamicClass += 'modal-start-article--active '
        if(error) dynamicClass += 'modal-start-article--error '
        if(!userCanEdit) dynamicClass += 'modal-start-article--disabled '

        return dynamicClass
    }

    const checkTopicAndStartArticle = () => {
        if(!loading && !error && userCanEdit) {
            setLoading(true)
            // dispatch(getTopicValidation(newArticleTopic))
            interfaceApiRequest('checkTopicAndStartArticleMessage', {newTopicFromWriter: newArticleTopic})
                .then(resp => {
                    if(resp.message) {
                        setError(true);
                        setLoading(false);
                    // } else {
                    //     goToQuickWriting(newArticleTopic)
                    }
                })
                .catch(err => {
                    setLoading(false);
                    setError(true);
                    setCustomError(err.message)
                })
        }
    }

    const goToQuickWriting = (topic) => {
        const siteId = getGlobalState('site_id');
        if(!siteId || !topic) return ;

        const url = `${BASE_URL}/api/quick-write?topic=${topic}&site_id=${siteId}&local=true`
        window.location.href = url;
    }

    const enterHandler = (event) => {
        if (event.key === 'Enter') checkTopicAndStartArticle()
    }


    return (
        <WhiteTooltip
            placement="bottom-start"
            title={userCanEdit ? '' : 'You don’t have access to this.'}
        >
            <div className={`modal-start-article ${componentStateClassName()}`}>
                <div className="start-article__label">Or <strong>start with your topic</strong></div>

                <div className="start-article__input-wrapper">
                    <div className="start-article__input-label">Your informational topic</div>
                    <input
                        type="text"
                        value={newArticleTopic}
                        onChange={handleInputChange}
                        onKeyDown={enterHandler}
                        className={`start-article__input`}
                        placeholder="E.g., artificial intelligence"
                        disabled={!userCanEdit}
                    />
                    <div className="start-article__input-action" onClick={checkTopicAndStartArticle}>
                        {!!newArticleTopic && !loading && !error && (
                            <PlusIcon />
                        )}
                        {loading && (
                            <SpinnerIcon />
                        )}
                    </div>
                </div>
                {error && !loading && (
                    <div className="start-article__error"
                         dangerouslySetInnerHTML={{
                             __html: returnError(),
                         }}
                    />
                )}
                {loading && !error && (
                    <div className="start-article__loading-info">Processing keyword. Usually takes up to 1 minute.</div>
                )}
            </div>
        </WhiteTooltip>
    );
};

export default ModalStartArticle