import React from 'react';
import {WhiteTooltip} from "../UITooltips/UITooltip";
import {CoverageIcon, TitleIcon, HeadingIcon} from "../../UI_utils/SVGIcons";

const ArticleKeywordsTableRowIcon = ({ type, used }) => {
    const TOOLTIPS = {
        title: {
            used: '✓ Used in title',
            default: 'Add to title'
        },
        heading: {
            used: '✓ Used in headings',
            default: 'Add to headings'
        },
        topic: {
            used: '✓ Used in content',
            default: 'Add to content'
        }
    }

    const returnTooltip = () => {
        const state = used ? 'used' : 'default';

        return TOOLTIPS?.[type]?.[state] || 'TBD'
    }


    return (
        <WhiteTooltip
            placement="bottom"
            title={returnTooltip()}
        >
            <div className={!used ? 'progress-icon' : "progress-icon--active"}>{
                type === 'title' ? <TitleIcon /> : type === 'heading' ? <HeadingIcon /> : <CoverageIcon />
            }</div>
        </WhiteTooltip>
    );
}

export default ArticleKeywordsTableRowIcon;
