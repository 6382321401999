import React from 'react';
import './articleSwitch.css'

const ArticleSwitch = ({onSwitch , options, selectedOption}) => {
    return (
        <div className="article-switch-container">
            {options.map((item) => (
                <div className={selectedOption === item.key ? 'active switch-item' : 'switch-item'} key={item.label} onClick={() => onSwitch(item.key)}>
                    {item.label}
                </div>
            ))}
        </div>
    );
}
export default ArticleSwitch