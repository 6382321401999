import React, {useEffect, useRef, useState} from 'react';
import './ArticlesSidebarHeader.css'
import {CrossIcon, SearchIcon} from "../../UI_utils/SVGIcons";

const ArticlesSidebarHeader = ({searchHandler, searchedTerm, close = () => void(0)}) => {

    const [searchTerm, setSearchTerm] = useState(searchedTerm);
    const searchTimeoutRef = useRef(null);

    useEffect(() => {
        // Clear the previous timeout when the component unmounts or when a new key is pressed
        return () => {
            clearTimeout(searchTimeoutRef.current);
        };
    }, []);

    const searchArticles = (term) => {

        console.log('search term 333 => ', term)
        searchHandler(term);

    }

    const handleInputChange = (event) => {
        setSearchTerm((prevSearchTerm) => {
            // Clear the previous timeout if any
            clearTimeout(searchTimeoutRef.current);

            // Set a new timeout for 1000ms (1 second)
            searchTimeoutRef.current = setTimeout(() => {
                // Trigger the search event (call your search function here)
                searchArticles(event.target.value);
            }, 1000);

            // Return the updated search term
            return event.target.value;
        });
    };

    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            // Clear the previous timeout if any
            clearTimeout(searchTimeoutRef.current);

            // Trigger the search event (console.log for now)
            searchArticles(searchTerm);
        }
    };

    return (
        <div className="articles-sidebar-header">
            <div className="articles-sidebar-title__wrapper">
                <div className="articles-sidebar-title">Recent Articles</div>
                {!!close && <div className="articles-sidebar-close" onClick={close}><CrossIcon/></div>}
            </div>
            <div className="articles-sidebar-subheader">
                <div className="articles-sidebar-input">
                    <div className="articles-sidebar-input-icon">
                        <SearchIcon />

                    </div>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onKeyDown={handleEnterPress}
                    />
                </div>
            </div>
        </div>
    );
};

export default ArticlesSidebarHeader