import { createSlice } from "@reduxjs/toolkit";
import { isEshopEditor, isDemoApp, isNativeEditor } from "../utils/location";

export const applicationModeSlice = createSlice({
  name: "applicationmode",
  initialState: {
    applicationMode: isEshopEditor()
      ? "category"
      : isNativeEditor()
      ? "native"
      : "article",
    demoApplication: isDemoApp(),
    showArticleSidebar: false
  },
  reducers: {
    setApplicationMode: (state, { payload }) => {
      return {
        ...state,
        applicationMode: payload,
      };
    },

    setDemoApplication: (state, { payload }) => {
      return {
        ...state,
        demoApplication: payload,
      };
    },
    setShowArticleSidebar:(state, {payload}) => {
      return {
        ...state,
        showArticleSidebar: payload
      }
    }
  },
});

// actions
export const { setApplicationMode } = applicationModeSlice.actions;
export const { setDemoApplication } = applicationModeSlice.actions;
export const { setShowArticleSidebar } = applicationModeSlice.actions;

export const toggleApplicationMode = (mode) => (dispatch) => {
  dispatch(setApplicationMode(mode));
};

// selectors
export const getApplicationMode = ({ applicationmode: { applicationMode } }) =>
  applicationMode;

export const isDemoApplication = ({ applicationmode: { demoApplication } }) =>
  demoApplication;
export const getShowArticleSidebar = ({ applicationmode: { showArticleSidebar }}) => showArticleSidebar

export default applicationModeSlice.reducer;
