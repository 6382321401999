import { createSlice } from "@reduxjs/toolkit";
import { sendFeedbackToServer } from "../services/feedbacks";
import {
  getCommentsFromServer,
  saveComments,
  getUsersForArticle,
} from "../services/comments";
import {
  WRITER_SUBMIT,
  PROOF_FEEDBACK,
  PROOF_NO_FEEDBACK,
  WRITER_FEEDBACK,
  USER_WRITER,
  USER_PROOFREADER,
  USER_MASTER,
  ROUND2_PROOF_FEEDBACK,
  ROUND2_NO_FEEDBACK,
  USER_CLIENT,
} from "../common/consts";
import { setApiError } from "./apiErrors";

export const feedbacksSlice = createSlice({
  name: "feedbacks",
  initialState: {
    buttonStatus: "Submit",
    userAction: "asign-to-proof",
    allComments: null,
    currentEditorComments: null,
    allUsers: null,
    commentAdded: false,
    newComment: false,
  },
  reducers: {
    setButtonStatus: (state, { payload }) => {
      return {
        ...state,
        buttonStatus: payload,
      };
    },

    setUserAction: (state, { payload }) => {
      return {
        ...state,
        userAction: payload,
      };
    },
    setComments: (state, { payload }) => {
      return {
        ...state,
        allComments: payload,
      };
    },

    setCurrentEditorComments: (state, { payload }) => {
      return {
        ...state,
        allComments: payload,
      };
    },

    setAllUsers: (state, { payload }) => {
      return {
        ...state,
        allUsers: payload,
      };
    },
    setCommentAdded: (state, { payload }) => {
      return {
        ...state,
        commentAdded: payload,
      };
    },
    // this will change only once to true, use to compare existing with new comments
    setNewComment: (state, { payload }) => {
      return {
        ...state,
        newComment: payload,
      };
    },
  },
});

// actions
export const { setButtonStatus } = feedbacksSlice.actions;
export const { setUserAction } = feedbacksSlice.actions;
export const { setComments } = feedbacksSlice.actions;
export const { setCurrentEditorComments } = feedbacksSlice.actions;
export const { setAllUsers } = feedbacksSlice.actions;
export const { setCommentAdded } = feedbacksSlice.actions;
export const { setNewComment } = feedbacksSlice.actions;

// registers a click event from comments sidebar, based on which we save comments into plugin
export const registerCommentFromSidebar = (value) => (dispatch) => {
  dispatch(setCommentAdded(value));
};

// this requests all comments from server
export const getExistingComments = (articleId) => async (dispatch) => {
  try {
    const response = await getCommentsFromServer(articleId);
    dispatch(setComments(response.comments));
  } catch (error) {
    if (error.status === 404) {
      dispatch(setComments([]));
    } else {
      dispatch(setApiError(error));
    }
  }
};

// this gets the list of users assigned to an article
export const getExistingUsers = (articleId) => async (dispatch) => {
  try {
    const response = await getUsersForArticle(articleId);
    const allUsers = response.error ? [] : response;

    dispatch(setAllUsers(allUsers));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

// this saves existing comments to server
export const saveCommentsToServer =
  (articleId) => async (dispatch, getState) => {
    const { allComments } = getState().feedbacks;
    try {
      await saveComments(articleId, allComments);
    } catch (error) {
      dispatch(setApiError(error));
    }
  };

export const sendCurrentFeedback =
  (articleId, userAction) => async (dispatch) => {
    try {
      const result = await sendFeedbackToServer(articleId, userAction);
      return result;
    } catch (error) {
      dispatch(setApiError(error));
    }
  };

// selectors
export const getAllComments = ({ feedbacks: { allComments } }) => allComments;
export const getUsersOnArticle = ({ feedbacks: { allUsers } }) => allUsers;
export const isRegisterComment = ({ feedbacks: { commentAdded } }) =>
  commentAdded;

export const getButtonStatus = ({
  // texteditor: { editorData },
  user: { activeUser },
  feedbacks: { newComment },
  articles: { currentArticle },
}) => {
  const userRole = activeUser && activeUser.role[0];
  // set this as empty string for now so we don't get a crash, until we implement the new feedback feature
  const editorData = "";

  if (userRole === USER_WRITER) {
    if (editorData.includes("<comment-start")) {
      return WRITER_FEEDBACK;
    } else {
      return WRITER_SUBMIT;
    }
  }

  if (
    userRole === USER_PROOFREADER ||
    userRole === USER_MASTER ||
    userRole === USER_CLIENT
  ) {
    if (
      (currentArticle && currentArticle.hasRating) ||
      (currentArticle && currentArticle.status === "Re-assigned to proof")
    ) {
      if (editorData.includes("<comment-start") && newComment) {
        return ROUND2_PROOF_FEEDBACK;
      } else {
        return ROUND2_NO_FEEDBACK;
      }
    } else if (currentArticle && !currentArticle.hasRating) {
      if (editorData.includes("<comment-start")) {
        return PROOF_FEEDBACK;
      } else {
        return PROOF_NO_FEEDBACK;
      }
    }
  }
};

export default feedbacksSlice.reducer;
