import React from 'react';
import './ContentAuditBox.css'
import LoadingBlock from "../LoadingBlock/LoadingBlock";
import classNames from 'classnames'
import {shortenNumbers} from '../../utils/helpers'

const ContentAuditBox = ({title, issuesCount, dispatchEvent, section, subsection, showImportMessage, Icon}) => {

    const checkAndDispatch = () => {
        if(issuesCount) dispatchEvent(section, subsection)
    }

    return (
        <div className={classNames({'content-audit-box': true, disabled: !issuesCount,'questions-active': subsection == 'lowrankingpages' && showImportMessage })}
             onClick={checkAndDispatch}
        >
            <div className="content-audit-box-count">
                {(issuesCount != null && (
                    <div className="content-audit-box_issues">
                        {`${shortenNumbers(issuesCount)}`}
                    </div>

                )) || (
                    <LoadingBlock width={120} height={30} />
                )}
            </div>
            <div className="content-audit-box_title">{title}</div>
        </div>
    );
};

export default ContentAuditBox