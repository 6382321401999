import React from 'react';
import {shortenNumbers} from "../../utils/helpers";
import {WhiteTooltip} from "../UITooltips/UITooltip";
import './ArticleKeywordsTableGroupHeader.css'
import {ChevronDown, PlusIcon} from "../../UI_utils/SVGIcons";

const ArticleKeywordsTableGroupHeader = ({
    groupName,
    groupPercentage,
    onClick,
    searchVolume,
    doublePresencePercentage,
    secondPercentage,
    expanded,
    isTitle = false
}) => {

    return (
        <>
            <div className="article-keywords-table-group-header" onClick={onClick}>
                <div className="group-header-title column--keyword">
                    { groupName }
                    {!expanded && <ChevronDown />}
                </div>

                {typeof searchVolume != 'undefined'  && (
                    <div className="column--volume column-row">
                        {typeof searchVolume === 'string' ? searchVolume : shortenNumbers(searchVolume)}
                    </div>
                )}
                <div className="group-header-progress column--presence">
                    {doublePresencePercentage && (

                        <WhiteTooltip
                            placement="bottom"
                            title={`${secondPercentage}% of the top keyword opportunities by search volume are currently included in the headings`}
                        >
                            <div className="progress-percentage progress-percentage--secondary">{ secondPercentage }%</div>
                        </WhiteTooltip>
                    )}

                    <WhiteTooltip
                        placement="bottom"
                        title={`${groupPercentage}% of the top keyword opportunities by search volume are currently included in the ${isTitle ? 'title' : 'coverage'}`}
                    >
                        <div className="progress-percentage">{ groupPercentage }%</div>
                    </WhiteTooltip>
                </div>
            </div>
        </>
    );
}

export default ArticleKeywordsTableGroupHeader;
