import { Tooltip, withStyles } from "@material-ui/core";

export const BlackTooltip = withStyles({
  tooltip: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "black",
  },
  arrow: {
    "&:before": {
      color: "black",
    },
  },
})(Tooltip);

export const WhiteTooltip = withStyles({
  tooltip: {
    color: "#384359",
    backgroundColor: "white",
    boxShadow: "0px 20px 60px 0px rgba(0, 0, 0, 0.1)",
    transition: "none",
    transform: "none",
    borderRadius: "12px",
    fontFamily: "Inter",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    padding: "8px 12px",
    maxWidth: "220px",
    marginTop: "4px"

},
  arrow: {
    "&:before": {
      color: "white",
      boxShadow: "1.3px 0.3px 15px rgba(0, 0, 0, 0.2)",
    },
  },
})(Tooltip);
