import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import HomeContent from "../Components/HomeContent";
import { isDemoApplication } from "../reducers/applicationMode";
import { useSelector } from "react-redux";
import {getUserToken} from "../utils/userStatus";
import {getGlobalState} from "../globals";

const Home = () => {
  const isDemoApp = useSelector(isDemoApplication);
  const [isUserAuth] = useState(!!getUserToken());

  const siteId = getGlobalState('site_id');
  const getParam = siteId ? `?site_id=${siteId}` : '';
  const loginPath = `/login${getParam}`;

  return isUserAuth || isDemoApp ? <HomeContent /> : <Redirect to={loginPath} />;
};

export default Home;
