import axios from "axios";
import { BASE_URL } from "../common/consts";

const baseApi = "api/articles";
const projectsApi = "api/projects";
const userProjects = "user-projects";
const industries = "list-industries";
const projects = "list-projects";
const statuses = "list-statuses";
const feedbacks = "list-feedbacks";
const writersAndProofreaders = "list-users";
const dashboardList = "list";
const deleteRow = "delete";
const createNew = "new-full";
const editEntry = "edit-full";
const rename = "rename";
const liveUrl = "live-url";

// industries
export const getIndustries = (token) =>
  axios
    .get(`${BASE_URL}/${baseApi}/${industries}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// projects
export const getProjects = (token) =>
  axios
    .get(`${BASE_URL}/${baseApi}/${projects}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

//user projects (only projects on which a user is working on)
export const getUserProjects = (token) =>
  axios
    .get(`${BASE_URL}/${projectsApi}/${userProjects}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// statuses
export const getStatuses = (token) =>
  axios
    .get(`${BASE_URL}/${baseApi}/${statuses}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// feedbacks
export const getFeedbacks = (token) =>
  axios
    .get(`${BASE_URL}/${baseApi}/${feedbacks}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// axios get all the above
export const dashboardInitData = (token) =>
  axios
    .all([
      getIndustries(token),
      getProjects(token),
      getStatuses(token),
      getFeedbacks(token),
      getUserProjects(token),
    ])
    .then(
      axios.spread(
        (industries, projects, statuses, feedbacks, userProjects) => ({
          industries,
          projects,
          statuses,
          feedbacks,
          userProjects,
        })
      )
    );

// get writers
export const getWriters = (token) =>
  axios
    .post(
      `${BASE_URL}/${baseApi}/${writersAndProofreaders}`,
      {
        role: "writer",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get masters
export const getMasters = (token) =>
  axios
    .post(
      `${BASE_URL}/${baseApi}/${writersAndProofreaders}`,
      {
        role: "master",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get proofreaders
export const getProofreaders = (token) =>
  axios
    .post(
      `${BASE_URL}/${baseApi}/${writersAndProofreaders}`,
      {
        role: "proofreader",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// update date (created or deadline)
export const updateDate = (token, updateType, patchObject) =>
  // 'updateType' cand be 'created' or 'deadline' so path will be dynamically build
  axios
    .post(`${BASE_URL}/${baseApi}/${updateType}`, patchObject, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// update dropdown menu options
// this works the same for status/project/feedback/industry
// `eventType` will be different for each one, being also the api path
export const updateDrowpdownMenuOptions = (token, eventType, patchObject) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${eventType}`, patchObject, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// create new entry
export const createNewDashboardTopic = (token, payloadObject, isEditMode) =>
  axios
    .post(
      `${BASE_URL}/${baseApi}/${isEditMode ? editEntry : createNew}`,
      payloadObject,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// delete dashboard row
export const deleteDasboardRow = (token, id) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${deleteRow}`, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// rename topic
export const renameTopicTitle = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${rename}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get dashboard list
// api/articles/list
export const getDashboardTable = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${dashboardList}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// edit live url
export const sendLiveUrl = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${liveUrl}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
