import React from "react";

import "./HeadingsInArticle.css";

const HeadingsInArticle = ({ headings }) => {
  return (
    <div>
      {headings &&
        headings.map((heading, index) => {
          const key = Object.keys(heading).join();
          const value = Object.values(heading).join();

          return (
            <div
              key={index}
              className={`${key}-article-heading article-heading`}
            >
                <span className="heading-level">{key}.</span>
                <span className="heading-info">{value}</span>
            </div>
          );
        })}
    </div>
  );
};

export default HeadingsInArticle;
