export const markUsedKeywords = (
  keywords,
  titleOptimization,
  h1TagOptimization
) => {
  const usedKeywords = keywords.map((word) => {
    const h1Tag = word.use_in_h1;
    const isUsedInH1Tag = h1Tag && h1TagOptimization;
    const isUsedInTitle = word.use_in_title && titleOptimization;
    const isUsedInHeading = word.usedInHeading;
    let usedComplete = false;
    // special case for description matching
    // in description, we set the keyword as completed if it's used in paragraph, no need to check for headings
    // if (isDescription) {
    //   if (word.use_in_title) {
    //     usedComplete =
    //       (isUsedInTitle && word.usedInParagraph) || word.markedByUser;
    //     return {
    //       ...word,
    //       usedComplete,
    //     };
    //   }
    //   if (word.isMulti) {
    //     usedComplete = word.usedInParagraph || word.markedByUser;
    //     return {
    //       ...word,
    //       usedComplete,
    //     };
    //   }
    // }
    // end special case
    if (word.isMulti && word.titleAndHeading) {
      usedComplete =
        (isUsedInTitle && isUsedInHeading) ||
        word.markedByUser ||
        word.ignoredByUser;
      return {
        ...word,
        usedComplete,
      };
    }

    if (word.isMulti && word.titleAndH1) {
      usedComplete =
        (isUsedInTitle && isUsedInH1Tag) ||
        word.markedByUser ||
        word.ignoredByUser;
      return {
        ...word,
        usedComplete,
      };
    }

    if (word.isMulti && word.titleAndArticle) {
      usedComplete =
        (isUsedInTitle && word.usedInParagraph) ||
        word.markedByUser ||
        word.ignoredByUser;
      return {
        ...word,
        usedComplete,
      };
    }

    if (word.isMulti && word.headingAndArticle) {
      usedComplete =
        (isUsedInHeading && word.usedInParagraph) ||
        word.markedByUser ||
        word.ignoredByUser;
      return {
        ...word,
        usedComplete,
      };
    }

    if (!word.isMulti) {
      usedComplete =
        word.usedInParagraph || word.markedByUser || word.ignoredByUser;
      return {
        ...word,
        usedComplete,
      };
    }

    return {
      ...word,
      usedComplete: false,
    };
  });

  return usedKeywords;
};

export const filterUsedKeywords = (keywords, loading) => {
  return keywords.sort((a, b) => {
    if(loading) return 0

    // if (a.broad !== b.broad) return a.broad - b.broad;
    //
    // if (a.use_in_title && !b.use_in_title) return -1;
    //
    // if (!a.use_in_title && b.use_in_title) return 1;
    //
    // if (!a.usedComplete && b.usedComplete) return -1;
    //
    // if (a.usedComplete && !b.usedComplete) return 1;

    if (a.search_volume !== b.search_volume) {
      return b.search_volume - a.search_volume;
    }

    return 0;
  });
};
