const globalState = {};

export const setGlobalState = (key, value) => {
    globalState[key] = value;
};

export const getGlobalState = (key) => {
    return globalState[key];
};

export const deleteGlobalState = (key) => {
    delete globalState[key];
};

export const removeQueryParam = (key) => {
    // Get the current URL's search parameters
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);

    // Remove the specific query parameter
    searchParams.delete(key);
    deleteGlobalState(key)

    // Update the URL without reloading the page
    const newUrl = `${url.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
}