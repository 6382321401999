import { createSlice } from "@reduxjs/toolkit";
import {
  requestUserProfile,
  requestAllMasters,
  getProjectDefaultPromptReq,
  saveProjectDefaultPromptReq
} from "../services/user";
import { setApiError } from "./apiErrors";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    activeUser: null,
    masterUsers: null,
    userData: null,
    projectDefaultPrompt: '',
    isContentWriterAuthor: false,
    userCanEdit: true,
    draftConfigOptions: {
      aiModeSelector: false,
      testPromptSelector: false,
      ukCheckbox: false
    }
  },
  reducers: {
    setActiveUser: (state, { payload }) => {
      return {
        ...state,
        activeUser: payload,
      };
    },
    setDraftConfigOptions: (state, { payload }) => {
      return {
        ...state,
        draftConfigOptions: payload,
      };
    },
    setIsContentWriterAuthor: (state, { payload }) => {
      return {
        ...state,
        isContentWriterAuthor: payload,
      };
    },
    setUserCanEdit: (state, { payload }) => {
      return {
        ...state,
        userCanEdit: payload,
      };
    },
    setProjectDefaultPrompt: (state, { payload }) => {
      return {
        ...state,
        projectDefaultPrompt: payload,
      };
    },
    setMasterUsers: (state, { payload }) => {
      return {
        ...state,
        masterUsers: payload,
      };
    },
    setUserData: (state, { payload }) => {
      return {
        ...state,
        userData: payload
      }
    }
  },
});

export const { setActiveUser } = userSlice.actions;
export const { setDraftConfigOptions } = userSlice.actions;
export const { setIsContentWriterAuthor } = userSlice.actions;
export const { setUserCanEdit } = userSlice.actions;
export const { setProjectDefaultPrompt } = userSlice.actions;
export const { setMasterUsers } = userSlice.actions;
export const { setUserData } = userSlice.actions

export const getUserProfile = (token) => async (dispatch) => {
  try {
    const user = await requestUserProfile(token);
    dispatch(setActiveUser(user));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const getMasterUsers = (token) => async (dispatch) => {
  try {
    const masters = await requestAllMasters(token);

    dispatch(setMasterUsers(masters));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const getDefaultPrompt = (siteId) => async (dispatch) => {
    const response = await getProjectDefaultPromptReq(siteId);

    dispatch(setProjectDefaultPrompt(response?.prompt || ''));

    return prompt
};

export const saveProjectDefaultPrompt = (siteId, prompt) => async (dispatch) => {
    dispatch(setProjectDefaultPrompt(prompt));

    const savedPrompt = await saveProjectDefaultPromptReq(siteId, prompt);

    return savedPrompt
};

export const activeUser = ({ user: { activeUser } }) => activeUser;
export const getDraftConfigOptions = ({ user: { draftConfigOptions } }) => draftConfigOptions;
export const getIsContentWriterAuthor = ({ user: { isContentWriterAuthor } }) => isContentWriterAuthor;
export const getUserCanEdit = ({ user: { userCanEdit } }) => userCanEdit;
export const showDraftConfigOptions = ({ user: { draftConfigOptions } }) => Object.values(draftConfigOptions).includes(true);
export const allMasterUsers = ({ user: { masterUsers } }) => masterUsers;
export const getUserData = ({ user: { userData} }) => userData
export const getProjectDefaultPrompt = ({ user: { projectDefaultPrompt} }) => projectDefaultPrompt

export default userSlice.reducer;
