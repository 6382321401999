import { useState, useEffect } from "react";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    // remove 85 pixels which is the left side bar
    width: window.innerWidth - 85,
    height: window.innerHeight,
  });

  const changeWindowSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return windowSize;
};

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    screenWidth: window.screen.availWidth,
    screenHeight: window.screen.availHeight,
  });

  const changeWindowSize = () => {
    setScreenSize({
      screenWidth: window.screen.availWidth,
      screenHeight: window.screen.availHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return screenSize;
};
