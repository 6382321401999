import React from 'react';
import './progressbar.css'
import LoadingBlock from '../LoadingBlock/LoadingBlock'
import CustomTooltip from "../CustomTooltip";
import {WhiteTooltip} from "../UITooltips/UITooltip";

const ProgressBar = ({
    disabled,
    label,
    value = 0,
    unit,
    valueIsPercent = true,
    currentValue,
    totalValue,
    isPlagiarism,
    plagLoading,
    refreshPlagiarism,
    loading ,
    tooltipText,
    invalidValue
}) => {

    return (
        <>
            <div className="progress-bar__wrapper">
                <WhiteTooltip
                    placement="bottom"
                    title={loading || !tooltipText  ? '' : <div style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: tooltipText }} />}
                >
                {loading ? (
                    <div style={{'marginTop': '-8px'}}>
                        <div className="progress-heading">
                            <LoadingBlock width={24} height={8} />
                            <LoadingBlock width={24} height={8} />
                        </div>
                        <LoadingBlock height={2} width={'100%'} margin={'-12px 0 0 0'} />
                    </div>
                ) : (
                    <div className={`progress-metric ${invalidValue ? 'disabled' : ''}`} onClick={() => (isPlagiarism && !disabled && !invalidValue ) && refreshPlagiarism()}>
                        <div className="progress-heading">
                            <p className={disabled || invalidValue ? 'disabled' : ''}>{label || 'Coverage'}</p>
                                <div className="has-tooltip">
                                    {!disabled && (valueIsPercent ? (
                                            <div className="plagiarism-row">
                                                {isPlagiarism && (
                                                    <svg
                                                        className={plagLoading ? 'rotating' : ''}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 14 14"
                                                        fill="none">
                                                        <g clipPath="url(#clip0_277_30784)">
                                                            <mask
                                                                id="mask0_277_30784"
                                                                style={{ maskType: 'alpha' }}
                                                                maskUnits="userSpaceOnUse"
                                                                x="0"
                                                                y="0"
                                                                width="14"
                                                                height="14">
                                                                <path
                                                                    d="M12.0039 1.97656C12.127 1.97656 12.25 2.09961 12.25 2.22266V6.16016C12.25 6.30371 12.127 6.40625 12.0039 6.40625H8.06641C7.92285 6.40625 7.82031 6.30371 7.82031 6.16016V5.60645C7.82031 5.46289 7.92285 5.36035 8.06641 5.36035L10.6504 5.42188C10.0146 4.02734 8.62012 3.04297 7 3.04297C5.05176 3.04297 3.45215 4.41699 3.0625 6.22168C3.04199 6.34473 2.93945 6.40625 2.81641 6.40625H2.24219C2.07812 6.40625 1.95508 6.2832 1.99609 6.11914C2.42676 3.76074 4.49805 1.97656 7 1.97656C8.76367 1.97656 10.3223 2.89941 11.2246 4.25293L11.1836 2.24316C11.1836 2.09961 11.2861 1.97656 11.4297 1.97656H12.0039ZM11.7373 7.71875C11.9014 7.71875 12.0244 7.8623 11.9834 8.02637C11.5527 10.3848 9.48145 12.1484 7 12.1484C5.21582 12.1484 3.65723 11.2461 2.75488 9.89258L2.7959 11.9023C2.7959 12.0459 2.69336 12.1484 2.5498 12.1484H1.99609C1.85254 12.1484 1.75 12.0459 1.75 11.9023V7.96484C1.75 7.8418 1.85254 7.71875 1.99609 7.71875H5.93359C6.05664 7.71875 6.17969 7.8418 6.17969 7.96484V8.53906C6.17969 8.68262 6.05664 8.78516 5.91309 8.78516L3.3291 8.72363C3.96484 10.1182 5.35938 11.082 7 11.082C8.92773 11.082 10.5273 9.72852 10.917 7.92383C10.9375 7.80078 11.04 7.71875 11.1631 7.71875H11.7373Z"
                                                                    fill="#171717"
                                                                />
                                                            </mask>
                                                            <g mask="url(#mask0_277_30784)">
                                                                <rect width="14" height="14" fill="#171717" />
                                                            </g>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_277_30784">
                                                                <rect width="14" height="14" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                )}
                                                <p>{value}%</p>
                                            </div>
                                        ) : (
                                            <p>
                                                {currentValue} / {totalValue}
                                            </p>
                                        ))}
                                </div>
                        </div>
                        <div className="progress-container">
                            <div className="progress-value" style={{ width: `${disabled ? 0 : value < 100 ? value : 100}%` }}></div>
                        </div>
                    </div>
                )}
                </WhiteTooltip>
            </div>
        </>
    );
};

export default ProgressBar