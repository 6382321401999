import {useEffect, useState} from "react";

import './ModalContentAudit.css'
import ContentAuditBox from "./ContentAuditBox";
import {useDispatch, useSelector} from "react-redux";
import {
    getMissingPagesCount, 
    getPagesManyKeywordsCount,
    getSuboptimalContentCount,
    getWrongIntentKeywordCount,
    getLowRankPageCount
} from "../../reducers/contentAuditIssues";
// import {setIssuesCounters} from "../../reducers/contentAuditIssues";
import {MissingPageCountIcon , WrongIntentCountIcon, InformationalKeywordsCountIcon, PagesServingTooManyKeywordsCountIcon } from '../../UI_utils/SVGIcons'

const ModalContentAudit = ({showImportMessage}) => {
    const missingPagesCount = useSelector(getMissingPagesCount);
    const wrongIntentKeywordCount = useSelector(getWrongIntentKeywordCount)
    // const suboptimalContentCount = useSelector(getSuboptimalContentCount)
    const pagesManyKeywordsCount = useSelector(getPagesManyKeywordsCount)
    const lowRankPageCount = useSelector(getLowRankPageCount)

    const returnIssueCount = (key) => {
        if(key === 'missingpages')
            return missingPagesCount
        if(key === 'wrongintentkeyword')
            return wrongIntentKeywordCount
        // if(key === 'suboptimalcontent')
        //     return suboptimalContentCount
        if(key === 'lowrankingpages')
            return lowRankPageCount
        return pagesManyKeywordsCount
    }

    const boxes = () => [
        {
            title: 'Informational keywords with missing pages',
            section: 'content-audit',
            subsection: 'missingpages',
            icon: MissingPageCountIcon
        },
        {
            title: 'Keywords with pages serving the wrong intent',
            section: 'content-audit',
            subsection: 'wrongintentkeyword',
            icon: WrongIntentCountIcon
        },
        // {
        //     title: 'Suboptimal informational pages',
        //     section: 'content-audit',
        //     subsection: 'suboptimalinformation',
        // },
        {
            title: 'Content improvement opportunities on informational topics',
            section: 'content-audit',
            subsection: 'lowrankingpages',
            icon: InformationalKeywordsCountIcon
        },
        {
            title: 'Pages serving too many keywords',
            section: 'content-audit',
            subsection: 'pagesmanykeywords',
            icon: PagesServingTooManyKeywordsCountIcon
        },
       
    ]

    const dispatchEvent = (section, subsection) => {
        let urlQuery = {}
        if(subsection != 'lowrankingpages') urlQuery.informational = true
        window.parent.postMessage({ section, subsection , urlQuery }, '*');
    }

    return (
        <div className="modal-content-audit">
            {boxes().map(
                (contentAuditItem, index) =>
                    <ContentAuditBox
                        key={index}
                        title={contentAuditItem.title}
                        issuesCount={returnIssueCount(contentAuditItem.subsection) || 0}
                        subsection={contentAuditItem.subsection}
                        section={contentAuditItem.section}
                        dispatchEvent={dispatchEvent}
                        showImportMessage={showImportMessage}
                        Icon={contentAuditItem.icon}
                    />
            )}
        </div>
    );
};

export default ModalContentAudit